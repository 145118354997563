import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { TextField, Button, MenuItem, Typography, Container, Box, Alert, Breadcrumbs } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const EditProduct = () => {
    const { id } = useParams();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [stock, setStock] = useState('');
    const [unitId, setUnitId] = useState('');
    const [units, setUnits] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [categories, setCategories] = useState([]);
    const [productType, setProductType] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [reorderLevel, setReorderLevel] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState('');
    // const navigate = useNavigate();

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await axiosInstance.get(`/products/${id}`);
                const product = response.data;
                setName(product.name);
                setDescription(product.description);
                setPrice(product.price?.toString() || '');
                setMinPrice(product.minPrice?.toString() || '');
                setStock(product.stock?.toString() || '');
                setUnitId(product.unit._id);
                setCategoryId(product.category._id);
                setReorderLevel(product.reorderLevel.toString());
                setProductType(product.productType || '');
                setExpirationDate(product.expirationDate?.split('T')[0] || ''); // Adjust to 'YYYY-MM-DD'
                setImagePreview(product.image);
            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.get('/categories');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        const fetchUnits = async () => {
            try {
                const response = await axiosInstance.get('/units');
                setUnits(response.data);
            } catch (error) {
                console.error('Error fetching units:', error);
            }
        };

        fetchProductDetails();
        fetchUnits();
        fetchCategories();
    }, [id]);

    const getConditionalBorderColor = () => {
        if (parseFloat(stock) > 0 && parseFloat(minPrice) <= 0 || parseFloat(minPrice) < parseFloat(price)) {
            return 'red';
        }
        return 'grey';
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess('');
    
        // Check if the expiration date is required based on product type and stock
        if (
            ['Liquid', 'Gel', 'Powder'].includes(productType) &&
            parseFloat(stock) > 0 &&
            !expirationDate
        ) {
            setError('Expiration date is required for liquid, gel, and powder products with stock greater than 0.');
            return;
        }
    
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('price', parseFloat(price.replace(/,/g, '')) || 0);
        formData.append('minPrice', parseFloat(minPrice.replace(/,/g, '')) || 0);
        formData.append('unit', unitId);
        formData.append('category', categoryId);
        formData.append('productType', productType);
        formData.append('reorderLevel', parseInt(reorderLevel.replace(/,/g, '')) || 0);
    
        // Include expiration date if applicable
        if (
            ['Liquid', 'Gel', 'Powder'].includes(productType) &&
            parseFloat(stock) > 0
        ) {
            formData.append('expirationDate', expirationDate);
        }
    
        if (image) {
            formData.append('image', image);
        }
    
        try {
            await axiosInstance.put(`/products/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setSuccess('Product updated successfully');
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message;
                const existingProduct = error.response.data.existingProduct;
    
                if (existingProduct) {
                    setError(`${errorMessage}. The conflicting product is "${existingProduct.name}" with description: "${existingProduct.description}".`);
                } else {
                    setError(errorMessage || 'There was an error updating the product.');
                }
            } else {
                setError('There was an error updating the product. Please try again later.');
            }
            console.error('Error updating product:', error);
        }
    };
    

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h5" component="h4" color="primary" gutterBottom>
                        Edit Product
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 'auto' }}>
                        <RouterLink to="/admin/products" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Product List
                        </RouterLink>
                        <Typography color="textPrimary">Edit Product</Typography>
                    </Breadcrumbs>
                </Box>
                {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    {imagePreview && (
                        <Box sx={{ mb: 2 }}>
                            <img
                                src={imagePreview}
                                alt="Product Preview"
                                style={{
                                    maxWidth: '200px',
                                    maxHeight: '200px',
                                    width: '100%',
                                    height: 'auto',
                                    objectFit: 'contain',
                                    borderRadius: 8
                                }}
                            />
                        </Box>
                    )}
                    <TextField
                        fullWidth
                        label="Product Name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Description"
                        variant="outlined"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Price"
                        variant="outlined"
                        value={formatNumberWithCommas(price)}
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Minimum Selling Price"
                        variant="outlined"
                        type="text"
                        value={formatNumberWithCommas(minPrice)}
                        onChange={(e) => setMinPrice(e.target.value.replace(/,/g, ''))}
                        sx={{
                            mb: 2,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: getConditionalBorderColor(),
                                },
                                '&:hover fieldset': {
                                    borderColor: getConditionalBorderColor(),
                                },
                            },
                        }}
                        InputProps={{ 
                            inputProps: { step: '0.01', min: 0 }, 
                            readOnly: parseFloat(price) <= 0,
                        }}
                    />
                    <TextField
                        fullWidth
                        label="Stock"
                        variant="outlined"
                        value={formatNumberWithCommas(stock)}
                        InputProps={{
                            inputProps: { min: 0 },
                            readOnly: true,
                        }}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        select
                        fullWidth
                        label="Product Type"
                        variant="outlined"
                        value={productType}
                        onChange={(e) => setProductType(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    >
                        <MenuItem value="" disabled>Select product type</MenuItem>
                        <MenuItem value="Solid">Solid</MenuItem>
                        <MenuItem value="Liquid">Liquid</MenuItem>
                        <MenuItem value="Gas">Gas</MenuItem>
                        <MenuItem value="Gel">Gel</MenuItem>
                        <MenuItem value="Powder">Powder</MenuItem>
                    </TextField>

                    {['Liquid', 'Gel', 'Powder'].includes(productType) && parseFloat(stock) > 0 && (
                        <TextField
                            fullWidth
                            label="Expiration Date"
                            type="date"
                            variant="outlined"
                            value={expirationDate}
                            onChange={(e) => setExpirationDate(e.target.value)}
                            required
                            InputLabelProps={{ shrink: true }}
                            sx={{ mb: 2 }}
                        />
                    )}

                    <TextField
                        select
                        fullWidth
                        label="Unit"
                        variant="outlined"
                        value={unitId}
                        onChange={(e) => setUnitId(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    >
                        <MenuItem value="" disabled>Select Unit</MenuItem>
                        {units.map((unit) => (
                            <MenuItem key={unit._id} value={unit._id}>
                                {unit.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        label="Category"
                        variant="outlined"
                        value={categoryId}
                        onChange={(e) => setCategoryId(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    >
                        <MenuItem value="" disabled>Select category</MenuItem>
                        {categories.map((category) => (
                            <MenuItem key={category._id} value={category._id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        fullWidth
                        label="Reorder Level"
                        variant="outlined"
                        type="text"
                        value={formatNumberWithCommas(reorderLevel)}
                        onChange={(e) => setReorderLevel(e.target.value.replace(/,/g, ''))}
                        required
                        sx={{ mb: 2 }}
                        InputProps={{ inputProps: { min: 0 } }}
                    />
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ mb: 2, display: 'block', width: '100%' }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ 
                            mt: 3,
                            bgcolor: '#367588',
                            '&:hover': {
                                bgcolor: '#367588',
                            } 
                        }}
                    >
                        Update Product
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default EditProduct;
