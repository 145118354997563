// useProducts.js
import { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';

export const useProducts = (shopId) => {
  const [products, setProducts] = useState([]);
  const [availableQuantities, setAvailableQuantities] = useState({}); // Define setAvailableQuantities

  const refreshProductQuantities = async () => {
    try {
      const response = await axiosInstance.get(`/distributions/shop/${shopId}/products`);
      const productsData = response.data.products;
      setProducts(productsData);
      const quantitiesMap = productsData.reduce((acc, product) => {
        acc[product._id] = product.availableQuantity;
        return acc;
      }, {});
      setAvailableQuantities(quantitiesMap);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    refreshProductQuantities();
  }, [shopId]);

  return { products, availableQuantities, setAvailableQuantities, refreshProductQuantities };
};
