// auth/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { isProduction } from '../../utility/environment';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null);
  const [userId, setUserId] = useState(null);
  const [username, setUsername] = useState(null);
  const [shopId, setShopId] = useState(null);
  const [shopName, setShopName] = useState(null);
  const [refreshToken, setRefreshToken] = useState(
    typeof window !== 'undefined' ? localStorage.getItem('refreshToken') : null
  );

  useEffect(() => {
    const checkAuth = async () => {
      const storedToken = localStorage.getItem('authToken');

      if (storedToken) {
        try {
          if (storedToken.split('.').length !== 3) {
            throw new Error('Invalid token format');
          }

          let decodedToken;
          try {
            decodedToken = jwtDecode(storedToken);
          } catch (error) {
            clearSession();
            return;
          }

          const currentTime = Date.now() / 1000;
          if (decodedToken.exp > currentTime) {
            setIsAuthenticated(true);
            axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;

            const userDetails = await axios.get(`${process.env.REACT_APP_AUTH_URL}/auth/user`, {
              headers: { Authorization: `Bearer ${storedToken}` },
            });

            setRole(userDetails.data.role);
            setUserId(userDetails.data._id);
            setUsername(userDetails.data.username);
            setShopId(userDetails.data.shop?._id);
            setShopName(userDetails.data.shop?.name);
          } else if (refreshToken) {
            try {
              const refreshResponse = await axios.post(
                `${process.env.REACT_APP_AUTH_URL}/auth/refresh`,
                { refreshToken },
                {
                  headers: { Authorization: `Bearer ${refreshToken}` }
                }
              );

              const { token, newRefreshToken } = refreshResponse.data;
              localStorage.setItem('authToken', token);
              localStorage.setItem('refreshToken', newRefreshToken);
              setRefreshToken(newRefreshToken);
              axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

              const userDetails = await axios.get(`${process.env.REACT_APP_AUTH_URL}/auth/user`, {
                headers: { Authorization: `Bearer ${token}` }
              });

              setRole(userDetails.data.role);
              setUserId(userDetails.data._id);
              setUsername(userDetails.data.username);
              setShopId(userDetails.data.shop?._id);
              setShopName(userDetails.data.shop?.name);
              setIsAuthenticated(true);
            } catch (refreshError) {
              clearSession();
            }
          } else {
            clearSession();
          }
        } catch (error) {
          clearSession();
        }
      } else {
        clearSession();
      }
      setLoading(false);
    };

    checkAuth();
  }, [refreshToken]);

  const clearSession = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    setIsAuthenticated(false);
    setRole(null);
    setUserId(null);
    setUsername(null);
    setShopId(null);
    setShopName(null);
    setRefreshToken(null);
  };

  const login = async (email, password) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/signin`, {
            email,
            password,
        });

        const { token, refreshToken: newRefreshToken } = response.data;

        if (!token) {
            return { success: false, message: 'No token received from login.' };
        }

        localStorage.setItem('authToken', token);
        localStorage.setItem('refreshToken', newRefreshToken);
        setRefreshToken(newRefreshToken);

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        const userDetails = await axios.get(`${process.env.REACT_APP_AUTH_URL}/auth/user`, {
            headers: { Authorization: `Bearer ${token}` },
        });

        setIsAuthenticated(true);
        setRole(userDetails.data.role);
        setUserId(userDetails.data._id);
        setUsername(userDetails.data.username);
        setShopId(userDetails.data.shop?._id);
        setShopName(userDetails.data.shop?.name);

        return { success: true, role: userDetails.data.role, message: '' };

    } catch (error) {
        const errorMessage = error.response?.data?.message || 'Signin failed. Please try again.';

        if (errorMessage.includes('Account is deactivated')) {
            return { success: false, message: 'Your account has been deactivated. Please contact support.' };
        }

        // Use the isProduction helper to log errors only in production
        if (isProduction()) {
            console.error('Error during login:', error);
        }

        return { success: false, message: errorMessage };
    }
};

  const logout = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken) {
        axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`, {
          refreshToken
        }).catch(() => {});
      }

      clearSession();
      delete axios.defaults.headers.common['Authorization'];
    } catch {
      // Handle logout error silently
    }
  };

  const verifySecurityKey = async (securityKey) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/verify-security-key`, {
        securityKey,
      });

      return response.data.valid;
    } catch {
      throw new Error('An error occurred while verifying the security key.');
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, role, loading, userId, shopName, username, shopId, verifySecurityKey }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
