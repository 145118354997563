import React, { useState, useEffect, useCallback, useContext } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { AuthContext } from '../auth/AuthContext';
import { TextField, Button, MenuItem, Typography, Container, Box, Alert, Breadcrumbs, CircularProgress, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const AddPurchase = () => {
    const [products, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState('');
  const [productItems, setProductItems] = useState([{ product: '', quantity: '', unitPrice: '', searchTerm: '', isVisible: true, }]);
  const [amountPaid, setAmountPaid] = useState('0');
  const [dueDate, setDueDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [duplicateProduct, setDuplicateProduct] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dueDateRequired, setDueDateRequired] = useState(false);
  const [productErrors, setProductErrors] = useState([]);
  const [showNewSupplierForm, setShowNewSupplierForm] = useState(false);
  const [newSupplier, setNewSupplier] = useState({ name: '', phone: '' });
  const [showProducts, setShowProducts] = useState(false);
  const { userId } = useContext(AuthContext);


    useEffect(() => {
        const fetchProducts = async () => {
          try {
            const response = await axiosInstance.get('/products', { params: { deleted: false } });
            setProducts(response.data);
          } catch (error) {
            console.error('Error fetching products:', error);
          }
        };
    
        const fetchSuppliers = async () => {
          try {
            const response = await axiosInstance.get('/suppliers');
            setSuppliers(response.data);
          } catch (error) {
            console.error('Error fetching suppliers:', error);
          }
        };
    
        fetchProducts();
        fetchSuppliers();
      }, []);

    const handleSupplierChange = useCallback((event) => {
        const value = event.target.value;
        setSupplier(value);
        setError('');
        setShowNewSupplierForm(value === 'new');
      }, []);

    const formatNumberWithCommas = useCallback((value) => {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }, []);
    

    const handleProductChange = useCallback((index, event) => {
        const newProductItems = [...productItems];
        let newValue = event.target.value;
    
        if (newProductItems.some((item, idx) => item.product === newValue && idx !== index)) {
          setDuplicateProduct(products.find(prod => prod._id === newValue)?.name || '');
          newProductItems[index].product = '';
        } else {
          if (event.target.name === "quantity" || event.target.name === "unitPrice") {
            newValue = newValue.replace(/,/g, '');
            newValue = !isNaN(newValue) ? formatNumberWithCommas(newValue) : newValue;
          }
    
          newProductItems[index][event.target.name] = newValue;
          setDuplicateProduct('');
        }
    
        setProductItems(newProductItems);
    
        if (newValue && productErrors[index]?.[event.target.name]) {
          const newProductErrors = [...productErrors];
          newProductErrors[index][event.target.name] = false;
          setProductErrors(newProductErrors);
        }
      }, [productItems, products, formatNumberWithCommas, productErrors]);

    

      const handleAddProduct = () => {
        const newProductErrors = [...productErrors];
    
        // Check if the supplier has been selected
        if (!supplier) {
            setError('Please select a supplier before adding products.');
            return; // Prevent adding a new product if no supplier is selected
        }
    
        // Check if the last product has product, quantity, and unitPrice filled
        const lastItem = productItems[productItems.length - 1];
        const hasProductError = !lastItem.product;
        const hasQuantityError = !lastItem.quantity;
        const hasUnitPriceError = !lastItem.unitPrice;
    
        if (hasProductError || hasQuantityError || hasUnitPriceError) {
            // If not filled, add an error for that product
            newProductErrors[productItems.length - 1] = {
                product: hasProductError,
                quantity: hasQuantityError,
                unitPrice: hasUnitPriceError,
            };
            setProductErrors(newProductErrors);
            return; // Prevent adding a new product
        }
    
        // Clear errors and add a new product with visibility set to true
        setProductErrors([...newProductErrors, { product: false, quantity: false, unitPrice: false }]);
        setProductItems([...productItems, { product: '', quantity: '', unitPrice: '', isVisible: true }]);
    };
    
    
    

    const handleRemoveProduct = (index) => {
        // Remove the product at the specified index
        const newProductItems = productItems.filter((_, i) => i !== index);
        setProductItems(newProductItems);
    
        // Remove the corresponding error at the same index
        const newProductErrors = productErrors.filter((_, i) => i !== index);
        setProductErrors(newProductErrors);
    
        // Ensure at least one product is visible if there are 3 or fewer remaining
        if (newProductItems.length <= 3) {
            const updatedProductItems = newProductItems.map(item => ({
                ...item,
                isVisible: true, // Make all visible
            }));
            setProductItems(updatedProductItems);
        }
    
        // Reset duplicate message when removing a product field
        setDuplicateProduct('');
    };
    
    

    const handleAmountPaidChange = (event) => {
        let value = event.target.value;
    
        // Remove existing commas
        value = value.replace(/,/g, '');
    
        // Check if the user is typing a new number
        if (value.startsWith('0') && event.nativeEvent.data && event.nativeEvent.data !== '0') {
            // Replace '0' with the new character
            value = value.slice(1); // Remove the leading '0'
        }
    
        // If the input is cleared, set value to '0'
        if (value === '') {
            value = '0';
        } else if (!isNaN(value)) {
            value = formatNumberWithCommas(value); // Format with commas
        }
    
        setAmountPaid(value); // Update the amountPaid state
    };
    
    
    
    

    const handleDueDateChange = (date) => {
        setDueDate(date);
    };

    const handleConfirm = async () => {
        setOpenConfirmDialog(false);
        await submitPurchase();
    };

    const submitPurchase = async () => {
        if (supplier === 'new') {
            setError('Please complete adding the new supplier before submitting the purchase.');
            return;
        }
        setError('');
        setSuccess('');
        setSubmitting(true);

        const cleanProductItems = productItems.map(item => ({
        product: item.product,
        quantity: parseFloat(item.quantity.replace(/,/g, '')), // Remove commas and parse as float
        unitPrice: parseFloat(item.unitPrice.replace(/,/g, '')), // Remove commas and parse as float
        }));

        const newPurchase = {
            supplier,
            products: cleanProductItems,
            amountPaid: parseFloat(amountPaid.replace(/,/g, '')) || 0,
            dueDate: dueDate ? dueDate.toISOString() : null, // Send dueDate as ISO string if provided,
            purchasedBy:userId
        };

        try {
            await axiosInstance.post('/purchases', newPurchase);
            setSuccess('Purchase added successfully.');
            setSupplier('');
            setProductItems([{ product: '', quantity: '', unitPrice: '' }]);
            setAmountPaid('0'); // Reset amountPaid field
            setDueDate(null); // Reset dueDate field
        } catch (error) {
            setError('There was an error creating the purchase: ' + (error.response?.data?.message || error.message));
        } finally {
            setSubmitting(false);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const totalPrice = calculateTotalPrice();
        const paidAmount = parseFloat(amountPaid.replace(/,/g, '')) || 0;
    
        if (paidAmount < totalPrice) {
            if (paidAmount === 0) {
                setOpenConfirmDialog(true);
            } else {
                submitPurchase();
            }
        } else {
            submitPurchase();
        }
    };
        

    const calculateTotalPrice = () => {
        return productItems.reduce((total, item) => {
            const quantity = parseFloat(item.quantity.replace(/,/g, '')) || 0;
            const unitPrice = parseFloat(item.unitPrice.replace(/,/g, '')) || 0;
            return total + (quantity * unitPrice);
        }, 0);
    };
    
    

    // Filter out selected products from the dropdown
    const getAvailableProducts = (index) => {
        const selectedProductIds = productItems.map(item => item.product);
        return products.filter(prod => !selectedProductIds.includes(prod._id) || selectedProductIds.indexOf(prod._id) === index);
    };

    const handleNewSupplierSubmit = async () => {
        const { name, phone } = newSupplier;

        // Simple form validation: only name and phone are required
        if (!name || !phone) {
            setError('Please provide both name and phone for the new supplier.');
            return;
        }

        try {
            setLoading(true); // Set loading state
            const response = await axiosInstance.post('/suppliers', { name, phones: [phone] });
            const newSupplierId = response.data._id;
            setSuppliers([...suppliers, response.data]); // Add new supplier to the list
            setSupplier(newSupplierId); // Set the new supplier as selected
            setShowNewSupplierForm(false); // Hide the form after submission
            setNewSupplier({ name: '', phone: '' }); // Reset the form
        } catch (error) {
            setError('Error creating supplier: ' + error.message);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    const handleProductSearch = (index, event) => {
        const newProductItems = [...productItems];
        newProductItems[index].searchTerm = event.target.value;
        setProductItems(newProductItems);
    };
    

    const handleToggleProductVisibility = (index) => {
        setProductItems(prevItems => 
            prevItems.map((item, idx) => 
                idx === index ? { ...item, isVisible: !item.isVisible } : item
            )
        );
    };

    const getProductNameById = (productId) => {
        const product = products.find((p) => p._id === productId); // Assuming `products` is the list of all available products
        return product ? product.name : '';
    };
    



    // Determine if due date field should be disabled
    const isDueDateDisabled = parseFloat(amountPaid.replace(/,/g, '')) >= calculateTotalPrice();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container maxWidth="lg">
                <Box sx={{ my: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                        <Typography variant="h4" component="h2" color="primary" gutterBottom>
                            Add Purchase
                        </Typography>
                        <Breadcrumbs aria-label="breadcrumb">
                            <RouterLink to="/admin/purchases" style={{ textDecoration: 'none', color: 'inherit' }}>
                                Purchases List
                            </RouterLink>
                            <Typography color="textPrimary">Add Purchase</Typography>
                        </Breadcrumbs>
                    </Box>
                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                    {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
                    {duplicateProduct && <Alert severity="warning" sx={{ mb: 2 }}>You have already included this product: {duplicateProduct}</Alert>}
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    <TextField
                        select
                        fullWidth
                        label="Select Supplier"
                        value={supplier}
                        onChange={handleSupplierChange}
                        variant="outlined"
                        margin="normal"
                        required
                        sx={{ mb: 2 }}
                    >
                        <MenuItem value="" disabled>Select supplier</MenuItem>
                        {suppliers
                            .filter(supp => supp.name === 'Import') // Get the Import supplier
                            .concat(
                                suppliers
                                    .filter(supp => supp.name !== 'Import') // Get the rest excluding Import
                                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                            )
                            .map((supp) => (
                                <MenuItem
                                    key={supp._id}
                                    value={supp._id}
                                    sx={{ color: supp.name === 'Import' ? 'orange' : 'inherit' }} // Change color for Import
                                >
                                    {supp.name === 'Import' 
                                        ? `${supp.name} | If it's an import, use this supplier.` 
                                        : `${supp.name} | ${supp.phones[0]}`}
                                </MenuItem>
                            ))}
                        <MenuItem value="new" sx={{ color: '#367588' }}>Add New Supplier</MenuItem>
                    </TextField>



            {/* Show New Supplier Form */}
            {showNewSupplierForm && (
                <Box sx={{ mb: 2 }}>
                    <TextField
                        label="Supplier Name"
                        value={newSupplier.name}
                        onChange={(e) => setNewSupplier({ ...newSupplier, name: e.target.value })}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Supplier Phone"
                        value={newSupplier.phone}
                        onChange={(e) => setNewSupplier({ ...newSupplier, phone: e.target.value })}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                    {error && <Typography color="error">{error}</Typography>}
                    <Button onClick={handleNewSupplierSubmit} variant="contained" color="primary" disabled={loading}>
                        {loading ? 'Saving...' : 'Save Supplier'}
                    </Button>
                </Box>
            )}
{productItems.map((item, index) => (
    <Box key={index} sx={{ display: 'flex', flexDirection: 'column', mb: 2, border: '1px solid #ddd', borderRadius: 2, p: 2, boxShadow: 2 }}>
        {/* Toggle Button for Hiding/Unhiding */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography variant="h6" color="primary">
                {item.product ? getProductNameById(item.product) : `New Product`}
            </Typography>
            
            {/* Conditionally render the hide/unhide button only if a product is selected and there are more than 3 products */}
            {item.product && productItems.length > 3 && (
                <IconButton onClick={() => handleToggleProductVisibility(index)} sx={{ color: '#367588' }}>
                    {item.isVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            )}

        </Box>

        {/* If product is hidden, show a placeholder */}
        {!item.isVisible && item.product && productItems.length > 3 && (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#f9f9f9',
                    padding: 1,
                    borderRadius: 1,
                    mb: 2,
                    border: '1px solid #ccc',
                }}
            >
                <Typography variant="body2" color="textSecondary">
                    Product details are hidden.
                </Typography>
                <IconButton onClick={() => handleToggleProductVisibility(index)} size="small">
                    <ExpandMoreIcon />
                </IconButton>
            </Box>
        )}

        {/* Conditionally Render the Product Details */}
        {item.isVisible && (
            <>
                {/* Search Field */}
                <TextField
                    fullWidth
                    label="Search Product"
                    variant="outlined"
                    value={item.searchTerm || ''}
                    onChange={(event) => handleProductSearch(index, event)}
                    margin="normal"
                    sx={{ mb: 1 }}
                />

                {/* Product Dropdown */}
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        select
                        fullWidth
                        label="Select Product"
                        name="product"
                        value={item.product}
                        onChange={(event) => handleProductChange(index, event)}
                        variant="outlined"
                        margin="normal"
                        required
                        error={productErrors[index]?.product || false}
                        helperText={productErrors[index]?.product ? 'Product selection is required' : ''}
                        sx={{ mr: 2 }}
                    >
                        <MenuItem value="" disabled>Select product</MenuItem>
                        {getAvailableProducts(index)
                            .filter((prod) =>
                                prod.name.toLowerCase().includes(item.searchTerm?.toLowerCase() || '') ||
                                prod.description.toLowerCase().includes(item.searchTerm?.toLowerCase() || '')
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((prod) => (
                                <MenuItem key={prod._id} value={prod._id}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body2">{prod.description}</Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {prod.name}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                    </TextField>

                    {/* Quantity Field */}
                    <TextField
                        type="text"
                        fullWidth
                        label="Quantity"
                        name="quantity"
                        value={item.quantity}
                        onChange={(event) => handleProductChange(index, event)}
                        variant="outlined"
                        margin="normal"
                        required
                        error={productErrors[index]?.quantity || false}
                        helperText={productErrors[index]?.quantity ? 'Quantity is required' : ''}
                        sx={{ mr: 2 }}
                        InputProps={{ inputProps: { min: 0 } }}
                        disabled={!item.product}  // Disable if no product is selected
                    />

                    {/* Unit Price Field */}
                    <TextField
                        type="text"
                        fullWidth
                        label="Unit Price"
                        name="unitPrice"
                        value={item.unitPrice}
                        onChange={(event) => handleProductChange(index, event)}
                        variant="outlined"
                        margin="normal"
                        required
                        error={productErrors[index]?.unitPrice || false}
                        helperText={productErrors[index]?.unitPrice ? 'Unit price is required' : ''}
                        sx={{ mr: 2 }}
                        InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                        disabled={!item.product}  // Disable if no product is selected
                    />

                    {/* Remove Product Button */}
                    {productItems.length > 1 && (
                        <IconButton onClick={() => handleRemoveProduct(index)} color="error" sx={{ mt: 1 }}>
                            <RemoveIcon />
                        </IconButton>
                    )}
                </Box>
            </>
        )}
    </Box>
))}





                        <Button
                            type="button"
                            onClick={handleAddProduct}
                            variant="outlined"
                            startIcon={<AddIcon />}
                            sx={{ mb: 2, mr: 2 }}
                            disabled={productItems.every(item => !item.product)}  // Disable if no products are selected
                        >
                            Add Another Product
                        </Button>

                        <TextField
                            type="text"  // Use text type to allow formatting with commas
                            fullWidth
                            label="Amount Paid"
                            value={amountPaid}
                            onChange={handleAmountPaidChange}
                            variant="outlined"
                            margin="normal"
                            required
                            sx={{ mb: 2 }}
                            InputProps={{ inputProps: { min: 0 } }}  // Enforce non-negative numbers
                        />
                        <DatePicker
                            label="Due Date"
                            value={dueDate}
                            onChange={handleDueDateChange}
                            textField={(params) => <TextField {...params} fullWidth variant="outlined" margin="normal" sx={{ mb: 2 }} required={dueDateRequired} />}
                            disabled={isDueDateDisabled}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                bgcolor: '#367588',
                                '&:hover': {
                                    bgcolor: '#25525D',
                                }
                            }}
                            disabled={submitting || !supplier || productItems.length === 0 || productItems.every(item => !item.product || !item.quantity || !item.unitPrice)}
                        >
                            {submitting ? (
                                <CircularProgress size={24} sx={{ color: 'white', mr: 2 }} />
                            ) : 'Add Purchase'}
                        </Button>

                        <Button
                            type="button"
                            onClick={() => {
                                setSupplier('');
                                setProductItems([{ product: '', quantity: '', unitPrice: '', searchTerm: '', isVisible: true, }]);
                                setAmountPaid('0');
                                setDueDate(null); // Reset dueDate field
                                setError('');
                                setSuccess('');
                            }}
                            variant="outlined"
                            startIcon={<CancelIcon />}
                            sx={{ mt: 2 }}
                        >
                            Cancel
                        </Button>
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            <span style={{ color: '#367588' }}>Total Purchase Price:</span> {formatNumberWithCommas(calculateTotalPrice().toFixed(2))} Br.
                        </Typography>
                    </Box>

                    {/* Confirmation Dialog */}
                    <Dialog
                        open={openConfirmDialog}
                        onClose={() => setOpenConfirmDialog(false)}
                    >
                        <DialogTitle>Confirm Full Credit</DialogTitle>
                        <DialogContent>
                            <Typography>It looks like you haven't entered an amount paid. This purchase will be recorded as full credit. Are you sure you want to proceed?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenConfirmDialog(false)} variant='outlined' color="error">
                                Cancel
                            </Button>
                            <Button onClick={handleConfirm} variant='contained' color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Container>
        </LocalizationProvider>
    );
};

export default AddPurchase;
