import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, MenuItem, Modal, Box, Typography } from '@mui/material';
import { debounce } from 'lodash';

const CustomerPicker = ({ customers = [], formData, handleChange, setNewCustomerName, setNewCustomerPhone }) => {
    const [search, setSearch] = useState('');
    const [filteredCustomers, setFilteredCustomers] = useState(customers);
    const [isModalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        const filterCustomers = debounce(() => {
            if (customers.length > 0) {
                setFilteredCustomers(
                    customers.filter(customer =>
                        customer.name.toLowerCase().includes(search.toLowerCase()) ||
                        customer.phone.toLowerCase().includes(search.toLowerCase())
                    )
                );
            }
        }, 300); // 300ms debounce for better performance
        
        filterCustomers();
        return () => filterCustomers.cancel(); // Cleanup debounce on unmount
    }, [search, customers]);

    const handleSearch = (text) => setSearch(text);

    const handleCustomerSelect = (customer) => {
        handleChange('customer', customer._id);
        setSearch('');
        setModalVisible(false);
    };

    const selectedCustomer = customers.find(c => c._id === formData.customer);

    return (
        <Box mb={2}>
            <Button
                variant="outlined"
                onClick={() => setModalVisible(true)}
                fullWidth
                aria-haspopup="dialog"
                aria-expanded={isModalVisible}
            >
                {selectedCustomer ? `${selectedCustomer.name} (${selectedCustomer.phone})` : 'Select a customer'}
            </Button>

            {formData.customer === 'new_customer' && (
                <Box mt={1}>
                    <TextField
                        label="New Customer Name"
                        value={formData.newCustomerName}
                        onChange={(e) => setNewCustomerName(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="New Customer Phone"
                        value={formData.newCustomerPhone}
                        onChange={(e) => setNewCustomerPhone(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </Box>
            )}

            <Modal open={isModalVisible} onClose={() => setModalVisible(false)}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" gutterBottom>Select Customer</Typography>

                    <TextField
                        label="Search customers..."
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        fullWidth
                        margin="normal"
                        aria-label="Search customers"
                    />

                    <Box sx={{ maxHeight: 200, overflowY: 'auto', mt: 1 }}>
                        {filteredCustomers.length > 0 ? (
                            filteredCustomers.map((customer) => (
                                <Box
                                    key={customer._id}
                                    onClick={() => handleCustomerSelect(customer)}
                                    sx={{
                                        p: 1,
                                        cursor: 'pointer',
                                        borderBottom: '1px solid #ccc',
                                        '&:hover': { backgroundColor: '#f5f5f5' }
                                    }}
                                    aria-label={`Select ${customer.name}`}
                                >
                                    {customer.name} ({customer.phone})
                                </Box>
                            ))
                        ) : (
                            <Typography variant="body2">No customers found</Typography>
                        )}
                    </Box>

                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setModalVisible(false)}
                        sx={{ mt: 2, ml: 6 }}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            handleChange('customer', 'new_customer');
                            setModalVisible(false);
                        }}
                        sx={{ mt: 2, ml: 2}}
                    >
                        New Customer
                    </Button>

                    
                </Box>
            </Modal>
        </Box>
    );
};

// Define PropTypes for the component
CustomerPicker.propTypes = {
    customers: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired
    })),
    formData: PropTypes.shape({
        customer: PropTypes.string,
        newCustomerName: PropTypes.string,
        newCustomerPhone: PropTypes.string
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    setNewCustomerName: PropTypes.func.isRequired,
    setNewCustomerPhone: PropTypes.func.isRequired
};

// Styling for the modal box
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'background.paper',
    p: 3,
    boxShadow: 24,
    borderRadius: 2,
    width: { xs: '90%', sm: '400px' },
};

export default CustomerPicker;
