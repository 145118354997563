import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useAuth } from '../auth/AuthContext';

// Format number with commas
const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const VoidedSales = () => {
  const { username } = useAuth();
  const [voidedSales, setVoidedSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchVoidedSales = async () => {
      if (username) {
        try {
          const response = await axiosInstance.get('/sales/voided', {
            params: { createdBy: username }
          });
          setVoidedSales(response.data);
        } catch (error) {
          console.error('Error fetching voided sales:', error);
          setError('Failed to fetch voided sales data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchVoidedSales();
  }, [username]);

  if (loading) return <CircularProgress />;
  if (error) return <Snackbar open={true} autoHideDuration={6000}><Alert severity="error">{error}</Alert></Snackbar>;

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" color="primary" gutterBottom>
        Voided Sales
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Total Price</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Sale Date</TableCell>
              <TableCell>Customer Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {voidedSales.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} sx={{ textAlign: 'center', padding: 2 }}>
                  <Typography variant="h6" color="textSecondary">
                    No voided sales
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              voidedSales.map((sale) => (
                <TableRow key={sale._id}>
                  <TableCell>{sale.invoiceId}</TableCell>
                  <TableCell>{sale.product.name}</TableCell>
                  <TableCell>{sale.quantity}</TableCell>
                  <TableCell>{formatNumberWithCommas(sale.unitPrice.toFixed(2))} Br.</TableCell>
                  <TableCell>{formatNumberWithCommas(sale.totalPrice.toFixed(2))} Br.</TableCell>
                  <TableCell>{sale.paymentMethod}</TableCell>
                  <TableCell>{sale.paymentStatus}</TableCell>
                  <TableCell>{new Date(sale.saleDate).toLocaleString()}</TableCell>
                  <TableCell>{sale.customerName || 'N/A'}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default VoidedSales;
