import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { useNavigate } from 'react-router-dom';
import { Button, Alert, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import { FormControl, FormHelperText } from '@mui/material';

const AddDistribution = () => {
    const [products, setProducts] = useState([]);
    const [shops, setShops] = useState([]);
    const [productItems, setProductItems] = useState([{ product: '', quantity: '0' }]);
    const [shop, setShop] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [productStock, setProductStock] = useState({});
    const [invalidQuantities, setInvalidQuantities] = useState({});
    const [duplicateProductError, setDuplicateProductError] = useState({});
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [confirmDetails, setConfirmDetails] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axiosInstance.get('/products');
                // Filter products to exclude those with unset minimum price
                const filteredProducts = response.data.filter(prod => prod.stock > 0 && prod.minPrice);
                setProducts(filteredProducts);
                setProductStock(filteredProducts.reduce((acc, prod) => {
                    acc[prod._id] = prod.stock;
                    return acc;
                }, {}));
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        

        const fetchShops = async () => {
            try {
                const response = await axiosInstance.get('/shops');
                setShops(response.data);
            } catch (error) {
                console.error('Error fetching shops:', error);
            }
        };

        fetchProducts();
        fetchShops();
    }, []);

    useEffect(() => {
        if (error || success) {
            const timer = setTimeout(() => {
                setError('');
                setSuccess('');
            }, 6000);
            return () => clearTimeout(timer); // Cleanup timer on unmount or when dependencies change
        }
    }, [error, success]);

    const formatNumberWithCommas = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };  

    const handleProductChange = (event, index) => {
        const { value } = event.target;
        const updatedProducts = [...productItems];
        updatedProducts[index].product = value;
    
        // Check if the selected product has a valid minimum price
        const selectedProduct = products.find(prod => prod._id === value);
        if (selectedProduct && !selectedProduct.minPrice) {
            setError('Selected product does not have a valid minimum price.');
            updatedProducts[index].product = ''; // Clear the selection
            setProductItems(updatedProducts);
            return;
        }
    
        // Check for duplicate product
        const selectedProducts = updatedProducts.map(item => item.product).filter(p => p);
        const isDuplicate = selectedProducts.indexOf(value) !== selectedProducts.lastIndexOf(value);
    
        setDuplicateProductError(prev => ({
            ...prev,
            [index]: isDuplicate
        }));
    
        setProductItems(updatedProducts);
        // Reset invalid quantity for the product if changed
        setInvalidQuantities(prev => ({ ...prev, [index]: false }));
    };
    

    const handleQuantityChange = (event, index) => {
        const { value } = event.target;
        const updatedProducts = [...productItems];
        
        // Convert value to integer, default to 0 if empty
        const parsedValue = value === '' ? 0 : parseInt(value, 10);
        
        // Get the stock for the selected product
        const selectedProductId = updatedProducts[index].product;
        const stock = productStock[selectedProductId] || 0; // Default to 0 if no stock info
        
        // Update the quantity and handle leading zeros
        if (parsedValue > 0) {
            updatedProducts[index].quantity = parsedValue.toString();
        } else {
            updatedProducts[index].quantity = '0';
        }
        
        // Validate quantity
        const quantity = parsedValue;
        if (quantity < 0 || quantity > stock) {
            setInvalidQuantities(prev => ({ ...prev, [index]: true }));
        } else {
            setInvalidQuantities(prev => ({ ...prev, [index]: false }));
        }
    
        setProductItems(updatedProducts);
    };
    

    const calculateTotalMinPrice = () => {
        return productItems.reduce((total, item) => {
            const product = products.find(prod => prod._id === item.product);
            if (product && product.minPrice) {
                return total + (product.minPrice * parseInt(item.quantity, 10));
            }
            return total;
        }, 0);
    };
    

    const handleRemoveProduct = (index) => {
        const updatedProducts = [...productItems];
        updatedProducts.splice(index, 1);
        setProductItems(updatedProducts);

        // Remove related errors
        setInvalidQuantities(prev => {
            const newInvalidQuantities = { ...prev };
            delete newInvalidQuantities[index];
            return newInvalidQuantities;
        });
        setDuplicateProductError(prev => {
            const newDuplicateProductError = { ...prev };
            delete newDuplicateProductError[index];
            return newDuplicateProductError;
        });
    };

    const handleAddProduct = () => {
        setProductItems([...productItems, { product: '', quantity: '0' }]);
    };    

    const isAddButtonDisabled = productItems.some((item, index) => !item.quantity && item.product) || productItems.some(item => item.quantity === '0');


    const handleConfirm = async () => {
        setOpenConfirmDialog(false);

        const invalidProducts = productItems.some(item => !item.product || !item.quantity);
        if (invalidProducts) {
            setError('All products and quantities must be filled.');
            return;
        }

        const duplicateProducts = Object.values(duplicateProductError).some(error => error);
        if (duplicateProducts) {
            setError('Each product can only be selected once.');
            return;
        }

        const exceedsStock = productItems.some((item) => {
            const stock = productStock[item.product];
            return item.quantity > stock;
        });

        if (exceedsStock) {
            setError('One or more quantities exceed available stock.');
            return;
        }

        const newDistributions = productItems.map(item => ({
            product: item.product,
            shop,
            quantity: parseInt(item.quantity)
        }));

        try {
            await axiosInstance.post('/distributions/batch', { distributions: newDistributions });
            setSuccess('Distributions added successfully!');
            setTimeout(() => {
                clearForm();
            }); // Redirect after 2 seconds
        } catch (error) {
            console.error('Error creating distributions:', error);
            if (error.response) {
                const errorMessage = error.response.data.message;
    
                // Check for the specific error about minPrice
                if (errorMessage.includes('does not have a valid minimum price set')) {
                    setError(errorMessage);  // Set the specific error message returned by the API
                } else {
                    setError('Failed to create distributions. Please try again.');
                }
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const confirmationDetails = productItems.map(item => ({
            product: products.find(p => p._id === item.product)?.name || 'Unknown',
            quantity: item.quantity
        }));
        setConfirmDetails(confirmationDetails);
        setOpenConfirmDialog(true);
    };

    const clearForm = () => {
        setProductItems([{ product: '', quantity: '' }]);
        setShop('');
        setInvalidQuantities({});
        setDuplicateProductError({});
    };



    return (
        <div style={{ backgroundColor: '#edf6f7', padding: '20px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h4" component="h2" color="primary" gutterBottom>
                    Add Distribution
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="inherit">Distributions</Typography>
                    <Typography color="textPrimary">Add Distribution</Typography>
                </Breadcrumbs>
            </Box>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}
            <form onSubmit={handleSubmit}>
                {productItems.map((item, index) => (
                    <Box key={index} sx={{ mb: 2, p: 2, backgroundColor: '#ffffff', borderRadius: '5px' }}>
                        <TextField
                            select
                            label="Product"
                            value={item.product}
                            onChange={(e) => handleProductChange(e, index)}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            required
                            error={!!duplicateProductError[index]}
                            helperText={duplicateProductError[index] && 'This product is already selected.'}
                        >
                            {products.map((prod) => (
                               <MenuItem key={prod._id} value={prod._id} disabled={!prod.minPrice}>
                               <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                   <Typography variant="body1">{prod.description}</Typography>
                                   <Typography variant="body2" color="textSecondary">{prod.name}</Typography>
                                   {prod.minPrice ? (
                                       <Typography variant="caption" color="textSecondary">
                                           Min Price: {formatNumberWithCommas(prod.minPrice.toFixed(2))} Br.
                                       </Typography>
                                   ) : (
                                       <Typography variant="caption" color="error">Min Price Not Set</Typography>
                                   )}
                                   <Typography variant="caption" color="textSecondary">Quantity Available: {prod.stock}</Typography>
                               </Box>
                           </MenuItem>
                           
                            ))}
                        </TextField>

                        <FormControl fullWidth variant="outlined" margin="normal" error={!!invalidQuantities[index]}>
                            <TextField
                                type="number"
                                label="Quantity"
                                value={item.quantity}
                                onChange={(e) => handleQuantityChange(e, index)}
                                required
                                inputProps={{ min: 0, step: 1 }} // Allow zero and positive integers
                                disabled={!item.product || Object.values(duplicateProductError).some((isDuplicate) => isDuplicate && item.product)}
                            />
                            <FormHelperText>
                                {invalidQuantities[index] ? 'Quantity exceeds available stock' : ''}
                            </FormHelperText>
                        </FormControl>



                        {productItems.length > 1 && (
                            <Button variant="contained" color="error" onClick={() => handleRemoveProduct(index)}>
                                Remove Product
                            </Button>
                        )}
                    </Box>
                ))}
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={handleAddProduct}
                    sx={{ mr: 2, display: 'flex', alignItems: 'center' }}
                    disabled={isAddButtonDisabled}
                >
                    <AddIcon sx={{ mr: 1 }} />
                    Another Product
                </Button>
                <TextField
                    select
                    label="Shop"
                    value={shop}
                    onChange={(e) => setShop(e.target.value)}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                >
                    {shops.map((sh) => (
                        <MenuItem key={sh._id} value={sh._id}>
                            {sh.name}
                        </MenuItem>
                    ))}
                </TextField>
                <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                    <span style={{ color: '#367588' }}>Total Minimum Selling Price:</span> {formatNumberWithCommas(calculateTotalMinPrice().toFixed(2))} Br.
                  </Typography>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ mt: 2 }}
                    disabled={isAddButtonDisabled}
                >
                    Add Distributions
                </Button>
            </form>

            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
            >
                <DialogTitle>Confirm Distribution</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to record the following distributions?
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Distribution Details:</Typography>
                        <Box sx={{ mt: 2 }}>
                            {confirmDetails.map((item, index) => (
                                <Typography key={index} variant="body1">
                                    Product: {item.product} - Quantity: {item.quantity}
                                </Typography>
                            ))}
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                Shop: {shops.find(s => s._id === shop)?.name || 'Unknown'}
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary" >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddDistribution;
