import React, { useEffect, useState, useCallback } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Breadcrumbs,
  TextField,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { format } from 'date-fns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const formatNumberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const PurchaseList = () => {
  const [purchases, setPurchases] = useState([]);
  const [products, setProducts] = useState({});
  const [suppliers, setSuppliers] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [deletePurchaseId, setDeletePurchaseId] = useState(null);
  const [deletePurchaseDetails, setDeletePurchaseDetails] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [datePickerType, setDatePickerType] = useState(null);

  const fetchPurchases = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      let url = '/purchases?';

      if (searchTerm) {
        url += `search=${searchTerm}&`;
      }
      if (startDate) {
        url += `startDate=${format(startDate, 'yyyy-MM-dd')}&`;
      }
      if (endDate) {
        const adjustedEndDate = format(new Date(endDate.setHours(23, 59, 59, 999)), 'yyyy-MM-dd');
        url += `endDate=${adjustedEndDate}&`;
      }

      const response = await axiosInstance.get(url);
      const purchaseData = Array.isArray(response.data) ? response.data : [];

      setPurchases(purchaseData);

      const productIds = [...new Set(purchaseData.flatMap(p => p.products.map(item => item.product._id)))];
      const productResponses = await Promise.all(productIds.map(id => axiosInstance.get(`/products/${id}`)));
      const productData = productResponses.reduce((acc, curr) => {
        acc[curr.data._id] = curr.data;
        return acc;
      }, {});

      setProducts(productData);

      const supplierIds = [...new Set(purchaseData.map(p => p.supplier._id))];
      const supplierResponses = await Promise.all(supplierIds.map(id => axiosInstance.get(`/suppliers/${id}`)));
      const supplierData = supplierResponses.reduce((acc, curr) => {
        acc[curr.data._id] = curr.data;
        return acc;
      }, {});

      setSuppliers(supplierData);
    } catch (error) {
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  }, [searchTerm, startDate, endDate]);

  useEffect(() => {
    fetchPurchases();
  }, [fetchPurchases]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeletePurchase = async () => {
    try {
      await axiosInstance.delete(`/purchases/${deletePurchaseId}`);
      setPurchases(purchases.filter(purchase => purchase._id !== deletePurchaseId));
      handleCloseDeleteDialog();
    } catch (error) {
      setError('Error deleting purchase');
    }
  };

  const handleOpenDeleteDialog = (purchase) => {
    setDeletePurchaseId(purchase._id);
    setDeletePurchaseDetails(purchase);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeletePurchaseId(null);
    setDeletePurchaseDetails(null);
    setOpenDeleteDialog(false);
  };

  const handleDatePickerOpen = (type) => {
    setDatePickerType(type);
    setDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setDatePickerOpen(false);
  };

  const handleDateChange = (date) => {
    if (datePickerType === 'startDate') {
      setStartDate(date);
    } else if (datePickerType === 'endDate') {
      setEndDate(date);
    }
    handleDatePickerClose();
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" color="primary">Purchase History</Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit">Purchases</Typography>
          <Typography color="textPrimary">Purchase History</Typography>
        </Breadcrumbs>
      </Box>

      <TextField
        label="Search purchases"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        fullWidth
        sx={{ mb: 3 }}
      />

      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <Button
          variant="outlined"
          onClick={() => handleDatePickerOpen('startDate')}
        >
          Start Date: {startDate ? format(startDate, 'MM/dd/yyyy') : 'Select date'}
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleDatePickerOpen('endDate')}
        >
          End Date: {endDate ? format(endDate, 'MM/dd/yyyy') : 'Select date'}
        </Button>
      </Box>

      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/admin/purchases/add"
        sx={{ mb: 3 }}
      >
        Add Purchase
      </Button>

      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : error ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" color="error">{error}</Typography>
        </Box>
      ) : purchases.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Number Of Product's</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Deleted</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchases.map((purchase) => {
                const productCount = purchase.products.length;
                const totalPurchasePrice = purchase.products.reduce((total, item) => total + item.totalPrice, 0);
                const supplierName = suppliers[purchase.supplier._id]?.name || 'Supplier not found';

                return (
                  <TableRow key={purchase._id}>
                    <TableCell>{productCount}</TableCell>
                    <TableCell>{formatNumberWithCommas(totalPurchasePrice.toFixed(2))}</TableCell>
                    <TableCell>{supplierName}</TableCell>
                    <TableCell>{format(new Date(purchase.purchaseDate), 'MM/dd/yyyy')}</TableCell>
                    <TableCell>
                      {purchase.products.some(item => products[item.product]?._id?.deleted) ? <DeleteForeverIcon color="error" /> : ''}
                    </TableCell>
                    <TableCell>
                      <Button
                        component={Link}
                        to={`/admin/purchases/detail/${purchase._id}`}
                        startIcon={<VisibilityIcon />}
                      >
                        View
                      </Button>
                      <Button
                        onClick={() => handleOpenDeleteDialog(purchase)}
                        startIcon={<DeleteIcon />}
                        color="error"
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No purchases available.</Typography>
      )}

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          {deletePurchaseDetails && (
            <>
              <Typography variant="h6">Please confirm if you wish to delete the following purchase:</Typography>
              <Typography><strong>Supplier:</strong> {suppliers[deletePurchaseDetails.supplier._id]?.name || 'Supplier not found'}</Typography>
              <Typography><strong>Date:</strong> {format(new Date(deletePurchaseDetails.purchaseDate), 'MM/dd/yyyy')}</Typography>
              <Typography><strong>Number of Products:</strong> {deletePurchaseDetails.products.length}</Typography>
              <Typography><strong>Total Price:</strong> {formatNumberWithCommas(deletePurchaseDetails.products.reduce((total, item) => total + item.totalPrice, 0).toFixed(2))}</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDeleteDialog}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDeletePurchase}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={datePickerOpen} onClose={handleDatePickerClose}>
        <DialogTitle>Select Date</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={datePickerType === 'startDate' ? 'Start Date' : 'End Date'}
              value={datePickerType === 'startDate' ? startDate : endDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDatePickerClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PurchaseList;
