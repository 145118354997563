import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon} from '@mui/icons-material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import useShops from '../hooks/useShops';

const ShopManagement = () => {
  const { shops, loading, error, createShop, updateShop, deleteShop } = useShops();
  const [newShopName, setNewShopName] = useState('');
  const [newShopLocation, setNewShopLocation] = useState('');
  const [editingShop, setEditingShop] = useState(null);
  const [editShopName, setEditShopName] = useState('');
  const [editShopLocation, setEditShopLocation] = useState('');
  const [deleteShopId, setDeleteShopId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // Error state for inputs
  const [newShopNameError, setNewShopNameError] = useState('');
  const [newShopLocationError, setNewShopLocationError] = useState('');
  const [editShopNameError, setEditShopNameError] = useState('');
  const [editShopLocationError, setEditShopLocationError] = useState('');

  const navigate = useNavigate();

  const handleCreateShop = async () => {
    let hasError = false;

    if (!newShopName) {
      setNewShopNameError('Shop Name is required.');
      hasError = true;
    } else {
      setNewShopNameError('');
    }

    if (!newShopLocation) {
      setNewShopLocationError('Shop Location is required.');
      hasError = true;
    } else {
      setNewShopLocationError('');
    }

    if (hasError) return;

    try {
      await createShop(newShopName, newShopLocation);
      setNewShopName('');
      setNewShopLocation('');
    } catch (error) {
      console.error('Error creating shop:', error.message);
    }
  };

  const handleEditShop = async (id) => {
    let hasError = false;

    if (!editShopName) {
      setEditShopNameError('Shop Name is required.');
      hasError = true;
    } else {
      setEditShopNameError('');
    }

    if (!editShopLocation) {
      setEditShopLocationError('Shop Location is required.');
      hasError = true;
    } else {
      setEditShopLocationError('');
    }

    if (hasError) return;

    try {
      await updateShop(id, editShopName, editShopLocation);
      setEditingShop(null);
      setEditShopName('');
      setEditShopLocation('');
    } catch (error) {
      console.error('Error updating shop:', error.message);
    }
  };

  const handleDeleteShop = async (id) => {
    try {
      await deleteShop(id);
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Error deleting shop:', error.message);
    }
  };

  const handleOpenDeleteDialog = (shopId) => {
    setDeleteShopId(shopId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteShopId(null);
    setOpenDeleteDialog(false);
  };

  const handleNavigateToDetails = (id) => {
    navigate(`/admin/shop/detail/${id}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" color="primary">Shop List</Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit">Shops</Typography>
          <Typography color="textPrimary">Shop List</Typography>
        </Breadcrumbs>
      </Box>
      <Box sx={{ mb: 3 }}>
        {newShopNameError && (
          <Typography color="error" sx={{ mb: 1 }}>
            {newShopNameError}
          </Typography>
        )}
        <TextField
          label="New Shop Name"
          variant="outlined"
          value={newShopName}
          onChange={(e) => {
            setNewShopName(e.target.value);
            if (e.target.value) {
              setNewShopNameError('');
            }
          }}
          fullWidth
          sx={{ mb: 2 }}
        />

        {newShopLocationError && (
          <Typography color="error" sx={{ mb: 1 }}>
            {newShopLocationError}
          </Typography>
        )}
        <TextField
          label="New Shop Location"
          variant="outlined"
          value={newShopLocation}
          onChange={(e) => {
            setNewShopLocation(e.target.value);
            if (e.target.value) {
              setNewShopLocationError('');
            }
          }}
          fullWidth
          sx={{ mb: 2 }}
        />

        <Button
          onClick={handleCreateShop}
          variant="contained"
          color="primary"
          disabled={!newShopName.trim() || !newShopLocation.trim()}  // Disable button if either field is empty
        >
          Add Shop
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : error ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" color="error">{error}</Typography>
        </Box>
      ) : shops.length > 0 ? (
        <Box sx={{ overflowX: 'auto' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shops.map((shop) => (
                  <TableRow key={shop._id}>
                    <TableCell>
                      {editingShop === shop._id ? (
                        <>
                          {editShopNameError && (
                            <Typography color="error" sx={{ mb: 1 }}>
                              {editShopNameError}
                            </Typography>
                          )}
                          <TextField
                            variant="outlined"
                            value={editShopName}
                            onChange={(e) => {
                              setEditShopName(e.target.value);
                              if (e.target.value) {
                                setEditShopNameError('');
                              }
                            }}
                            fullWidth
                          />
                        </>
                      ) : (
                        shop.name
                      )}
                    </TableCell>
                    <TableCell>
                      {editingShop === shop._id ? (
                        <>
                          {editShopLocationError && (
                            <Typography color="error" sx={{ mb: 1 }}>
                              {editShopLocationError}
                            </Typography>
                          )}
                          <TextField
                            variant="outlined"
                            value={editShopLocation}
                            onChange={(e) => {
                              setEditShopLocation(e.target.value);
                              if (e.target.value) {
                                setEditShopLocationError('');
                              }
                            }}
                            fullWidth
                          />
                        </>
                      ) : (
                        shop.location
                      )}
                    </TableCell>
                    <TableCell>
                      {editingShop === shop._id ? (
                        <>
                          <Button
                            onClick={() => handleEditShop(shop._id)}
                            variant="contained"
                            color="primary"
                            sx={{ mr: 1 }}
                          >
                            Save
                          </Button>
                          <Button
                            onClick={() => setEditingShop(null)}
                            color="error"
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => handleNavigateToDetails(shop._id)}
                            color="primary"
                            startIcon={<VisibilityIcon />}
                          >
                            View
                          </Button>
                          <Button
                            onClick={() => {
                              setEditingShop(shop._id);
                              setEditShopName(shop.name);
                              setEditShopLocation(shop.location);
                            }}
                            color="primary"
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                          <Button
                            color="error"
                            onClick={() => handleOpenDeleteDialog(shop._id)}
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Typography>No shops available.</Typography>
      )}

      {/* Confirm Delete Dialog */}
      <Dialog
  open={openDeleteDialog}
  onClose={handleCloseDeleteDialog}
  maxWidth="sm"
  fullWidth
>
  <DialogTitle className="text-danger">Confirm Shop Deletion</DialogTitle>
  <DialogContent>
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
      Please confirm if you wish to delete the following shop{' '}
        <strong>{shops.find((shop) => shop._id === deleteShopId)?.name}</strong>?
      </Typography>
    </Box>
  </DialogContent>
  <DialogActions>
    <Button
      variant="outlined"
      color="primary"
      onClick={handleCloseDeleteDialog}
    >
      Cancel
    </Button>
    <Button
      variant="contained"
      color="error"
      onClick={() => handleDeleteShop(deleteShopId)}
    >
      Delete
    </Button>
  </DialogActions>
</Dialog>

    </Box>
  );
};

export default ShopManagement;
