import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  CircularProgress,
  Paper,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon} from '@mui/icons-material';
import useUnits from '../hooks/useUnits';

const UnitManagement = () => {
  const {
    units,
    loading,
    error,
    success,
    createUnit,
    updateUnit,
    deleteUnit,
    clearMessages,
  } = useUnits();

  const [newUnit, setNewUnit] = useState('');
  const [editingUnit, setEditingUnit] = useState(null);
  const [editUnitName, setEditUnitName] = useState('');
  const [deleteUnitId, setDeleteUnitId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [localError, setLocalError] = useState('');

  useEffect(() => {
    if (error || success) {
      const timer = setTimeout(() => {
        clearMessages();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error, success, clearMessages]);

  useEffect(() => {
    if (localError) {
      const timer = setTimeout(() => {
        setLocalError('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [localError]);

  const handleCreateUnit = async () => {
    if (newUnit.trim() === '') {
      setLocalError('Unit name is required.');
      return;
    }
    setLocalError('');
    await createUnit(newUnit);
    setNewUnit('');
  };

  const handleEditUnit = async (id) => {
    if (editUnitName.trim() === '') {
      setLocalError('Unit name is required.');
      return;
    }
    setLocalError('');
    await updateUnit(id, editUnitName);
    setEditingUnit(null);
    setEditUnitName('');
  };

  const handleDeleteUnit = async (id) => {
    await deleteUnit(id);
    handleCloseDeleteDialog();
  };

  const handleOpenDeleteDialog = (unitId) => {
    setDeleteUnitId(unitId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteUnitId(null);
    setOpenDeleteDialog(false);
  };

  return (
    <Box sx={{ p: 3, maxWidth: 'lg', mx: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" color="primary">Unit of Measure</Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit">Units</Typography>
          <Typography color="textPrimary">Unit Management</Typography>
        </Breadcrumbs>
      </Box>
  
      <Box sx={{ mb: 3 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 3 }}>
            {success}
          </Alert>
        )}
        {localError && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {localError}
          </Alert>
        )}
  
        <TextField
        label="New Unit Name"
        variant="outlined"
        value={newUnit}
        onChange={(e) => setNewUnit(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
        error={!!localError}
        helperText={localError}
      />
      <Button
        onClick={handleCreateUnit}
        variant="contained"
        color="primary"
        disabled={!newUnit.trim()}  // Disable if input is empty or just whitespace
      >
        Add Unit
      </Button>

      </Box>
  
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <CircularProgress />
                  <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
                </TableCell>
              </TableRow>
            ) : units.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography>No units available</Typography>
                </TableCell>
              </TableRow>
            ) : (
              units.map((unit) => (
                <TableRow key={unit._id}>
                  <TableCell>
                    {editingUnit === unit._id ? (
                      <TextField
                        variant="outlined"
                        value={editUnitName}
                        onChange={(e) => setEditUnitName(e.target.value)}
                        fullWidth
                        error={!!localError}
                        helperText={localError}
                      />
                    ) : (
                      unit.name
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {editingUnit === unit._id ? (
                      <>
                        <Button
                          onClick={() => handleEditUnit(unit._id)}
                          variant="contained"
                          color="primary"
                          sx={{ mr: 1 }}
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => setEditingUnit(null)}
                          color="error"
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() => {
                            setEditingUnit(unit._id);
                            setEditUnitName(unit.name);
                          }}
                          color="primary"
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => handleOpenDeleteDialog(unit._id)}
                          color="error"
                          startIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
  
      {/* Confirm Delete Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="text-danger">Confirm Delete</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Are you sure you want to delete this unit?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteUnit(deleteUnitId)}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
  
};

export default UnitManagement;
