import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { useParams, Link } from 'react-router-dom';
import { Cancel as CancelIcon, ArrowBack as BackIcon, Store as StoreIcon } from '@mui/icons-material';
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartTooltip, Legend);

const ProductDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shops, setShops] = useState([]);
  const [visibleStockHistory, setVisibleStockHistory] = useState(5);
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // New state for active tab
  const theme = useTheme();

  useEffect(() => {
    fetchProductDetail(productId);
  }, [productId]);

  const fetchProductDetail = async (productId) => {
    try {
      const response = await axiosInstance.get(`/products/${productId}`);
      setProduct(response.data);
      setLoading(false);
      fetchShopsByProduct(productId);
    } catch (error) {
      console.error('Error fetching product detail:', error);
      setLoading(false);
    }
  };

  const fetchShopsByProduct = async (productId) => {
    try {
      const response = await axiosInstance.get(`/distributions?productId=${productId}`);
      const distributionData = response.data.data;

      const shopsWithProduct = distributionData
        .filter(dist => dist.product && dist.product._id === productId && dist.amountLeft > 0)
        .map(dist => ({
          name: dist.shop.name,
          location: dist.shop.location,
          amountLeft: dist.amountLeft,
        }));

      const combinedShops = shopsWithProduct.reduce((acc, shop) => {
        const existingShop = acc.find(s => s.name === shop.name);
        if (existingShop) {
          existingShop.amountLeft += shop.amountLeft;
        } else {
          acc.push(shop);
        }
        return acc;
      }, []);

      setShops(combinedShops);
    } catch (error) {
      console.error('Error fetching shops by product:', error);
    }
  };

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const calculateTotalQuantity = () => {
    if (product && shops.length > 0) {
      const totalShopQuantity = shops.reduce((total, shop) => total + (shop.amountLeft || 0), 0);
      return product.stock + totalShopQuantity;
    }
    return product ? product.stock : 0;
  };

  const calculateTotalValue = () => {
    if (product && product.stockHistory.length > 0) {
      return product.stockHistory.reduce((total, history) => total + (history.quantity * history.unitPrice), 0).toFixed(2);
    }
    return 0;
  };

  const handleShowMore = () => {
    setVisibleStockHistory(prev => prev + 5);
    setIsExpanded(true);
  };

  const handleShowLess = () => {
    setVisibleStockHistory(5);
    setIsExpanded(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filteredStockHistory = product?.stockHistory.filter(history => !history.removed) || [];

  const stockHistoryData = {
    labels: filteredStockHistory.map(history => new Date(history.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Quantity',
        data: filteredStockHistory.map(history => history.quantity),
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
      },
      {
        label: 'Unit Price',
        data: filteredStockHistory.map(history => history.unitPrice),
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 5,
      }
    ]
  };


  return (
    <Box sx={{ p: 4, backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
      <Paper elevation={6} sx={{ p: 4, borderRadius: 2 }}>
        <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h4" component="h1" color="primary" sx={{ fontWeight: 'light' }}>
            Product Details
          </Typography>
          <Button
            component={Link}
            to="/admin/products"
            variant="contained"
            startIcon={<BackIcon />}
            sx={{
              backgroundColor: theme.palette.primary.main,
              '&:hover': { backgroundColor: theme.palette.primary.dark }
            }}
          >
            Back to Product List
          </Button>
        </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
            <CircularProgress size={60} />
          </Box>
        ) : (
          product && (
            <>
              {/* Tabs for section navigation */}
              <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                <Tab 
                  label="Details" 
                  sx={{ fontWeight: activeTab === 0 ? 'bold' : 'normal' }} 
                />
                <Tab 
                  label="Shops" 
                  sx={{ fontWeight: activeTab === 1 ? 'bold' : 'normal' }} 
                />
                <Tab 
                  label="Image" 
                  sx={{ fontWeight: activeTab === 2 ? 'bold' : 'normal' }} 
                />
                <Tab 
                  label="Stock History" 
                  sx={{ fontWeight: activeTab === 3 ? 'bold' : 'normal' }} 
                />
              </Tabs>


              {activeTab === 0 && (
                <Card variant="outlined" sx={{ mt: 4, borderRadius: 2, boxShadow: 3 }}>
                  <CardContent sx={{ p: 4 }}>
                    {/* Product Name and Divider */}
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ fontWeight: 'bold', textAlign: 'center', mb: 3 }}
                    >
                      {product.name}
                    </Typography>
                    <Divider sx={{ mb: 3 }} />

                    {/* Product Details */}
                    <Box sx={{ mb: 4 }}>
                      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Product Details
                      </Typography>

                      <Box sx={{ mb: 1 }}>
                        <Typography variant="body1" gutterBottom>
                          <strong>Description:</strong> {product.description || 'N/A'}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Unit Price:</strong> {formatNumberWithCommas(product.price.toFixed(2))} Br
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Minimum Selling Price:</strong> {formatNumberWithCommas(product.minPrice.toFixed(2))} Br
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Stock Quantity:</strong> {formatNumberWithCommas(product.stock)}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <Tooltip title="When stock falls below this level, a reorder is needed">
                            <span><strong>Reorder Level:</strong> {product.reorderLevel}</span>
                          </Tooltip>
                        </Typography>
                      </Box>

                      <Box sx={{ mb: 1 }}>
                        <Typography variant="body1" gutterBottom>
                          <strong>Unit of Measure:</strong> {product.unit ? product.unit.name : 'N/A'}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Category:</strong> {product.category ? product.category.name : 'N/A'}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Product Type:</strong> {product.productType || 'N/A'}
                        </Typography>
                      </Box>

                      {['Liquid', 'Gel', 'Powder'].includes(product.productType) && (
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="body1" gutterBottom>
                            <strong>Expiration Date:</strong> {product.expirationDate ? new Date(product.expirationDate).toLocaleDateString() : 'N/A'}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    {/* Total Available Quantity and Value */}
                    <Divider sx={{ my: 3 }} />
                    <Box sx={{ textAlign: 'center', mb: 4 }}>
                      <Typography
                        variant="h6"
                        sx={{ color: '#367588', fontWeight: 'bold' }}
                      >
                        Total Available Quantity:
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        {formatNumberWithCommas(calculateTotalQuantity())}
                      </Typography>

                      <Typography
                        variant="h6"
                        sx={{ color: 'secondary.dark', fontWeight: 'bold', mt: 2 }}
                      >
                        Total Inventory Value:
                      </Typography>
                      <Typography variant="body1">
                        {formatNumberWithCommas(calculateTotalValue())} Br
                      </Typography>
                    </Box>

                    {/* Barcode Section */}
                    <Divider sx={{ my: 3 }} />
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography
                        variant="h6"
                        color="primary"
                        sx={{ fontWeight: 'bold', mb: 2 }}
                      >
                        Product Barcode
                      </Typography>

                      {product.barcode ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                          <img
                            src={product.barcode}
                            alt={`${product.name} barcode`}
                            style={{
                              maxWidth: '250px',
                              height: 'auto',
                              borderRadius: 8,
                              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                          />
                        </Box>
                      ) : (
                        <Typography variant="body1" color="textSecondary">
                          No barcode available for this product.
                        </Typography>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              )}


              {activeTab === 1 && (
                <Card variant="outlined" sx={{ mt: 4, borderRadius: 2 }}>
                  <CardContent>
                  <Typography variant="h6" gutterBottom>
                    <span style={{ color: '#367588', fontWeight: 'bold' }}>{product.name}</span> is also available at:
                  </Typography>
                    {shops.length > 0 ? (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Shop Name</TableCell>
                              <TableCell align="right">Available Quantity</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {shops.map((shop, index) => (
                              <TableRow key={index}>
                                <TableCell>{shop.name}</TableCell>
                                <TableCell align="right">{shop.amountLeft}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center'}}>
                        No shops currently have this product available.
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              )}


              {activeTab === 2 && (
                <Card variant="outlined" sx={{ mt: 4, borderRadius: 2 }}>
                  <CardContent>
                  <Typography variant="h6" gutterBottom>
                    <span style={{ color: '#367588', fontWeight: 'bold' }}>{product.name}</span>'s image:
                  </Typography>

                    {product.image ? (
                      <Box sx={{ textAlign: 'center' }}>
                        <img src={product.image} alt={product.name} style={{ maxWidth: '200px', borderRadius: 8 }} />
                      </Box>
                    ) : (
                      <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center' }}>
                        No image available for this product.
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              )}


              {activeTab === 3 && (
                <Card variant="outlined" sx={{ mt: 4, borderRadius: 2 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                    <span style={{ color: '#367588', fontWeight: 'bold' }}>{product.name}</span>'s stock history:
                  </Typography>
                    {filteredStockHistory.length > 0 ? (
                      <>
                        <Bar data={stockHistoryData} options={{ responsive: true }} />
                        <List>
                          {filteredStockHistory.slice(0, visibleStockHistory).map((history, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                primary={
                                  <>
                                    <span style={{ color: '#367588', fontWeight: 'bold' }}>Date:</span> {new Date(history.date).toLocaleDateString()}
                                    {' - '}
                                    <span style={{ color: '#367588', fontWeight: 'bold' }}>Quantity:</span> {history.quantity}
                                    {' - '}
                                    <span style={{ color: '#367588', fontWeight: 'bold' }}>Unit Price:</span> {history.unitPrice.toFixed(2)} Br.
                                  </>
                                }
                              />


                              {history.removed && (
                                <Tooltip title="This stock has been removed">
                                  <CancelIcon color="error" />
                                </Tooltip>
                              )}
                            </ListItem>
                          ))}
                        </List>
                        <Box sx={{ mt: 2 }}>
                          {!isExpanded && filteredStockHistory.length > visibleStockHistory && (
                            <Button onClick={handleShowMore} variant="outlined">
                              Show More
                            </Button>
                          )}
                          {isExpanded && (
                            <Button onClick={handleShowLess} variant="outlined">
                              Show Less
                            </Button>
                          )}
                        </Box>
                      </>
                    ) : (
                      <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center' }}>
                        No stock history available for this product.
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              )}

            </>
          )
        )}
      </Paper>
    </Box>
  );
};

export default ProductDetail;
