import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert
} from '@mui/material';
import { axiosInstance } from '../../Axios/AxiosInstance';
import ExcelJS from 'exceljs';

const QuoteDetailPage = () => {
    const { quoteId } = useParams();
    const [quote, setQuote] = useState(null);
    const [updatedQuantity, setUpdatedQuantity] = useState({});
    const [isEditable, setIsEditable] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [productToUpdate, setProductToUpdate] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuote = async () => {
            try {
                const response = await axiosInstance.get(`/quotes/${quoteId}`);
                setQuote(response.data);
                // Initialize updatedQuantity with current quantities
                const initialQuantities = {};
                response.data.products.forEach(product => {
                    initialQuantities[product.productId._id] = product.quantity;
                });
                setUpdatedQuantity(initialQuantities);
            } catch (error) {
                console.error('Error fetching quote details:', error);
            }
        };

        fetchQuote();
    }, [quoteId]);

    const handleQuantityChange = (productId, value) => {
        setUpdatedQuantity({ ...updatedQuantity, [productId]: value });
    };

    const handleUpdateProductClick = (product) => {
        setProductToUpdate(product);
        setOpenUpdateDialog(true);
    };
    
    const confirmUpdateQuantity = async () => {
        if (!productToUpdate) return;
    
        const productId = productToUpdate.productId._id;
        const newQuantity = updatedQuantity[productId];
        const originalQuantity = quote.products.find(product => product.productId._id === productId).quantity;
    
        if (newQuantity <= 0) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Invalid quantity');
            setSnackbarOpen(true);
            return;
        }
    
        if (newQuantity === originalQuantity) {
            setSnackbarSeverity('warning');
            setSnackbarMessage('You have not made a change');
            setSnackbarOpen(true);
            return;
        }
    
        try {
            await axiosInstance.put(`/quotes/${quoteId}/product/${productId}/quantity`, { newQuantity });
            setSnackbarMessage('Quantity updated');
            setSnackbarOpen(true);
            setQuote((prevQuote) => ({
                ...prevQuote,
                products: prevQuote.products.map((product) =>
                    product.productId._id === productId
                        ? { ...product, quantity: newQuantity }
                        : product
                ),
            }));
        } catch (error) {
            console.error('Error updating quantity:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage('Error updating quantity');
            setSnackbarOpen(true);
        } finally {
            setOpenUpdateDialog(false);
            setProductToUpdate(null);
        }
    };
    

    const handleRemoveProductClick = (product) => {
        setSelectedProduct(product);
        setOpenDialog(true);
    };

    const removeProduct = async () => {
        if (!selectedProduct) return;

        const { _id: productId } = selectedProduct.productId;
        
        try {
            await axiosInstance.delete(`/quotes/${quoteId}/product/${productId}`);
            setSnackbarMessage('Product removed');
            setSnackbarOpen(true);
            setQuote((prevQuote) => ({
                ...prevQuote,
                products: prevQuote.products.filter((product) => product.productId._id !== productId),
            }));
        } catch (error) {
            console.error('Error removing product:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage('Error removing product');
            setSnackbarOpen(true);
        } finally {
            setOpenDialog(false);
            setSelectedProduct(null);
        }
    };

    const toggleEditMode = () => {
        setIsEditable(!isEditable);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedProduct(null);
    };

    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Quote Details');
    
        // Add a title row for quote details
        worksheet.mergeCells('A1:C1');
        const titleRow = worksheet.getCell('A1');
        titleRow.value = `Quote Details - Quote ID: ${quoteId}`;
        titleRow.font = { size: 16, bold: true };
        titleRow.alignment = { horizontal: 'center' };
    
        // Add an empty row for spacing
        worksheet.addRow([]);
    
        // Add column headers
        const headerRow = worksheet.addRow(['Product Name', 'Description', 'Quantity']);
        headerRow.font = { bold: true };
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '367588' },  // Light pink color
            };
            cell.font = { color: { argb: 'FFFFFFFF' }, bold: true };
            cell.alignment = { horizontal: 'center' };
        });
    
        // Add product data
        quote.products.forEach((product) => {
            worksheet.addRow([
                product.productId.name,
                product.productId.description,
                product.quantity,
            ]);
        });
    
        // Adjust column widths
        worksheet.columns = [
            { width: 30 },  // Product Name
            { width: 50 },  // Description
            { width: 20 },  // Quantity
        ];
    
        // Generate Excel file and download it
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `quote_${quoteId}.xlsx`;
        link.click();
    };
    

    if (!quote) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box p={3}>
            <Typography variant="h4" color="primary" gutterBottom sx={{ mb: 3 }} >
                Quote Details
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}> {/* Flexbox container */}
                <Button 
                    onClick={() => navigate(-1)} 
                    variant="outlined" 
                    color="primary" 
                    sx={{ mr: 2 }} // Add right margin for spacing
                >
                    Back to Quotes
                </Button>
                <Button
                    onClick={toggleEditMode}
                    variant="outlined"
                    color={isEditable ? "error" : "primary"}
                    sx={{ mr: 2 }} // Add right margin for spacing
                >
                    {isEditable ? 'Stop Editing' : 'Enable Editing'}
                </Button>
                <Button
                    onClick={exportToExcel}  // Export to Excel Button
                    variant="contained"
                    color="primary"
                >
                    Export to Excel
                </Button>
            </Box>


            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {quote.products.map((product) => (
                            <TableRow key={product.productId._id}>
                                <TableCell>{product.productId.name}</TableCell>
                                <TableCell>{product.productId.description}</TableCell>
                                <TableCell>
                                    <TextField
                                        value={updatedQuantity[product.productId._id] || product.quantity}
                                        onChange={(e) =>
                                            handleQuantityChange(product.productId._id, e.target.value)
                                        }
                                        disabled={!isEditable}
                                    />
                                </TableCell>
                                <TableCell>
                                {isEditable && (
                                        <Button 
                                            variant='outlined'
                                            color="primary"
                                            onClick={() => handleUpdateProductClick(product)}
                                            disabled={updatedQuantity[product.productId._id] === product.quantity} // Disable if no change
                                        >
                                            Update
                                        </Button>
                                    )}

                                    <Button
                                        variant='outlined'
                                        color="error"
                                        onClick={() => handleRemoveProductClick(product)}
                                        sx={{ marginLeft: 2}}
                                    >
                                        Remove
                                    </Button>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Confirm Removal</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove the following product?
                    </DialogContentText>
                    {selectedProduct && (
                        <>
                            <Typography variant="subtitle1">
                                {selectedProduct.productId.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {selectedProduct.productId.description}
                            </Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={removeProduct} color="secondary">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openUpdateDialog} onClose={() => setOpenUpdateDialog(false)}>
                <DialogTitle>Confirm Quantity Update</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to update the quantity for the following product?
                    </DialogContentText>
                    {productToUpdate && (
                        <>
                            <Typography variant="subtitle1">
                                {productToUpdate.productId.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {productToUpdate.productId.description}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                New Quantity: {updatedQuantity[productToUpdate.productId._id]}
                            </Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateDialog(false)} variant='outlined' color="error">
                        Cancel
                    </Button>
                    <Button onClick={confirmUpdateQuantity} variant='contained' color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Snackbar for notifications */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default QuoteDetailPage;
