import React, { useState } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useAuth } from '../auth/AuthContext';

const MakeReturn = () => {
  const { shopId, userId } = useAuth(); // Get userId and shopId from useAuth
  const [invoiceId, setInvoiceId] = useState('');
  const [returnReason, setReturnReason] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); // State for confirmation dialog

  const handleReturn = async () => {
    const returnItems = Object.keys(selectedProducts)
      .filter(productId => selectedProducts[productId].isSelected)
      .map(productId => ({
        productId,
        quantity: selectedProducts[productId].quantity || 1 // Use selected quantity or default to 1
      }));

    // Validate input
    if (!invoiceId.trim() || returnItems.length === 0 || !returnReason.trim()) {
      setError('Please provide a valid Invoice ID, select at least one product, and enter a return reason.');
      return;
    }

    setOpenConfirmDialog(true); // Open confirmation dialog
  };

  const confirmReturn = async () => {
    setOpenConfirmDialog(false); // Close confirmation dialog
    setLoading(true);

    const returnItems = Object.keys(selectedProducts)
      .filter(productId => selectedProducts[productId].isSelected)
      .map(productId => ({
        productId,
        quantity: selectedProducts[productId].quantity || 1 // Use selected quantity or default to 1
      }));

    try {
      const response = await axiosInstance.post(`/sales/return/${invoiceId}`, {
        returnReason,
        returnItems,
        userId, // Include userId in the request body
        shopId, // Include shopId in the request body
      });

      if (response.data && response.data.message) {
        setSuccess(response.data.message);
      } else {
        setError('Failed to process return');
      }

      // Clear fields and reset states
      setReturnReason('');
      setSelectedProducts({});
      setInvoiceId('');
      setProducts([]);
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'Failed to process return');
      } else {
        setError('Failed to process return');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
  };

  const fetchProducts = async () => {
    if (!invoiceId.trim()) return;

    setFetchingProducts(true);

    try {
      const response = await axiosInstance.get('/sales/products', { params: { invoiceId } });
      if (response.data.length === 0) {
        setError('No products found for this Invoice ID.');
      } else {
        setProducts(response.data);
        setError(''); // Clear previous errors
      }
    } catch (error) {
      setError('Invalid Invoice ID or failed to fetch products.');
    } finally {
      setFetchingProducts(false);
    }
  };

  const handleProductSelection = (productId, isSelected, quantity) => {
    setSelectedProducts(prev => ({
      ...prev,
      [productId]: { isSelected, quantity: isSelected ? (quantity || 1) : 0 }
    }));
  };

  const getProductName = (productId) => {
    const product = products.find(p => p._id === productId);
    return product ? product.name : 'Unknown Product';
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" color="primary" gutterBottom>
        Make Return
      </Typography>
      <TextField
        label="Invoice ID"
        variant="outlined"
        fullWidth
        value={invoiceId}
        onChange={(e) => setInvoiceId(e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={fetchProducts}
        disabled={fetchingProducts || !invoiceId.trim()}
      >
        {fetchingProducts ? <CircularProgress size={24} /> : 'Fetch Products'}
      </Button>
      {fetchingProducts && <CircularProgress sx={{ display: 'block', marginTop: 2 }} />}
      {products.length > 0 && (
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">Select Products to Return</Typography>
          {products.map((product) => (
  <Box key={product._id} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
    <FormControlLabel
      control={
        <Checkbox
          checked={!!selectedProducts[product._id]?.isSelected}
          onChange={(e) =>
            handleProductSelection(product._id, e.target.checked, selectedProducts[product._id]?.quantity)
          }
          disabled={product.quantity === 0} // Disable the checkbox if the quantity is 0
        />
      }
      label={`${product.name} - Quantity Sold: ${product.quantity}`}
    />
    {selectedProducts[product._id]?.isSelected && (
      <TextField
        type="number"
        variant="outlined"
        size="small"
        value={selectedProducts[product._id]?.quantity || 1}
        onChange={(e) =>
          handleProductSelection(product._id, true, Math.min(parseInt(e.target.value), product.quantity))
        }
        inputProps={{ min: 1, max: product.quantity }}
        sx={{ marginLeft: 2, width: 100 }}
      />
    )}
  </Box>
))}

        </Box>
      )}
      <TextField
        label="Return Reason"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={returnReason}
        onChange={(e) => setReturnReason(e.target.value)}
        sx={{ marginTop: 2 }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleReturn}
        disabled={loading || !invoiceId.trim() || Object.keys(selectedProducts).length === 0}
        sx={{ marginTop: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : 'Submit Return'}
      </Button>

      <Snackbar open={!!success} autoHideDuration={6000} onClose={() => setSuccess('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setSuccess('')} severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setError('')} severity="error">
          {error}
        </Alert>
      </Snackbar>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Return</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to submit this return?</Typography>
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            Invoice ID: {invoiceId}
          </Typography>
          <Typography variant="body2">
            Return Reason: {returnReason}
          </Typography>
          <Typography variant="body2">
            Products: {Object.keys(selectedProducts).filter(productId => selectedProducts[productId].isSelected)
              .map(productId => `${getProductName(productId)}: ${selectedProducts[productId].quantity}`)
              .join(', ')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={confirmReturn} color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MakeReturn;
