import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Box,
} from '@mui/material';

const ConfirmationDialog = ({ open, onClose, formData, customers, products, handleSubmit }) => {
  const formatPrice = (price) => {
    const numPrice = Number(price);
    if (isNaN(numPrice)) {
      return "0.00";
    }
    return numPrice.toFixed(2);
  };

  const getPaymentStatusText = () => formData.paymentStatus;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ backgroundColor: '#f5f5f5', color: '#367588', fontWeight: 'bold' }}>
        Confirm Sale
      </DialogTitle>
      <DialogContent sx={{ padding: '24px' }}>
        {/* Customer Section */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom>
            Customer Information
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <Typography variant="body1" gutterBottom>
            <strong>Customer:</strong> {formData.customer === 'new_customer' ? formData.newCustomerName : (customers.find(customer => customer._id === formData.customer)?.name || '')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Phone:</strong> {formData.customer === 'new_customer' ? formData.newCustomerPhone : (customers.find(customer => customer._id === formData.customer)?.phone || '')}
          </Typography>
        </Box>

        {/* Payment Section */}
        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom>
            Payment Information
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <Typography variant="body1" gutterBottom>
            <strong>Payment Method:</strong> {formData.paymentMethod}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Payment Status:</strong> {getPaymentStatusText()}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Total Price:</strong> {formatPrice(formData.totalPrice)} Br.
          </Typography>
        </Box>

        {/* Product List Section */}
        <Box>
          <Typography variant="h6" gutterBottom>
            Products
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Product</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Qty</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Price</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{products.find(product => product._id === item.product)?.name || ''}</TableCell>
                    <TableCell>{products.find(product => product._id === item.product)?.description || ''}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{formatPrice(item.unitPrice)} Br.</TableCell>
                    <TableCell>{formatPrice(Number(item.quantity) * Number(item.unitPrice))} Br.</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button onClick={onClose} color="error" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
