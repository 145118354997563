import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Breadcrumbs,
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
  Save as SaveIcon,
} from '@mui/icons-material';
import useSuppliers from '../hooks/useSuppliers';

const SupplierList = () => {
  const {
    suppliers,
    filteredSuppliers,
    searchTerm,
    deleteSupplierId,
    openDeleteDialog,
    isSearchFocused,
    submissionError,
    submissionSuccess,
    editMode,
    editName,
    editPhones,
    editEmail,
    editAddress,
    loading,
    fetchError,
    handleSearchChange,
    handleSearchFocus,
    handleSearchBlur,
    handleDeleteSupplier,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleEditClick,
    handleSaveEdit,
    handleCancelEdit,
    handleEditPhoneChange,
    handleAddPhone,
    setEditName,
    setEditEmail,
    setEditAddress,
    
  } = useSuppliers();

  // Snackbar state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Handle closing of snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Trigger snackbar for success or error
  const triggerSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    if (submissionSuccess) {
      triggerSnackbar(submissionSuccess, 'success');
    } else if (submissionError) {
      triggerSnackbar(submissionError, 'error');
    }
  }, [submissionSuccess, submissionError]);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" color="primary">Suppliers List</Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit">Suppliers</Typography>
          <Typography color="textPrimary">Supplier List</Typography>
        </Breadcrumbs>
      </Box>

      {/* Add New Supplier Button */}
      <Box sx={{ mb: 3 }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/admin/suppliers/add"
        >
          Add New Supplier
        </Button>
      </Box>

      <Box sx={{ mb: 3 }}>
        <TextField
          label="Search suppliers by name or phone"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
          fullWidth
        />
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : fetchError ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" color="error">{fetchError}</Typography>
        </Box>
      ) : filteredSuppliers.length === 0 ? (
        <Typography variant="h6" color="textSecondary">
          No suppliers available
        </Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Phones</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSuppliers.map((supplier) => (
                <TableRow key={supplier._id}>
                  <TableCell>
                    {editMode === supplier._id ? (
                      <TextField
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                        fullWidth
                      />
                    ) : (
                      supplier.name
                    )}
                  </TableCell>
                  <TableCell>
                    {editMode === supplier._id ? (
                      <>
                        {editPhones.map((phone, index) => (
                          <TextField
                            key={index}
                            value={phone}
                            onChange={(e) => handleEditPhoneChange(index, e.target.value)}
                            fullWidth
                            sx={{ mb: 1 }}
                            placeholder={`Phone ${index + 1}`}
                          />
                        ))}
                        <Button onClick={handleAddPhone} variant="outlined" sx={{ mt: 1 }}>
                          Add Phone
                        </Button>
                      </>
                    ) : (
                      supplier.phones.join(', ')
                    )}
                  </TableCell>
                  <TableCell>
                    {editMode === supplier._id ? (
                      <TextField
                        value={editEmail}
                        onChange={(e) => setEditEmail(e.target.value)}
                        fullWidth
                      />
                    ) : (
                      supplier.email
                    )}
                  </TableCell>
                  <TableCell>
                    {editMode === supplier._id ? (
                      <TextField
                        value={editAddress}
                        onChange={(e) => setEditAddress(e.target.value)}
                        fullWidth
                      />
                    ) : (
                      supplier.address
                    )}
                  </TableCell>
                  <TableCell>
                    {editMode === supplier._id ? (
                      <>
                        <IconButton onClick={handleSaveEdit} color="primary">
                          <SaveIcon />
                        </IconButton>
                        <IconButton onClick={handleCancelEdit} color="error">
                          <CancelIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        {supplier.name !== 'Import' && (
                          <>
                            <Button
                              onClick={() => handleEditClick(supplier)}
                              color="primary"
                              startIcon={<EditIcon />}
                            >
                              Edit
                            </Button>
                            <Button
                              onClick={() => handleOpenDeleteDialog(supplier._id)}
                              color="error"
                              startIcon={<DeleteIcon />}
                            >
                              Delete
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Delete Supplier Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this supplier?</DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">Cancel</Button>
          <Button onClick={() => handleDeleteSupplier(deleteSupplierId)} color="primary">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success and error messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SupplierList;
