import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { Typography, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Alert, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import '../../assets/styles/InvoiceDetail.css';

const ShopSaleDetails = () => {
  const { invoiceId, shopId } = useParams();
  const navigate = useNavigate();
  const [saleDetails, setSaleDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [salesIds, setSalesIds] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);

  useEffect(() => {
    const fetchSaleDetails = async () => {
      try {
        const response = await axiosInstance.get(`/sales/invoice/${invoiceId}`);
                
        const saleData = Array.isArray(response.data) ? response.data : [response.data]; // Ensure saleData is an array

        if (saleData.length > 0 && saleData[0].shop && saleData[0].shop.toString() === shopId) {
          setSaleDetails(saleData); // Use saleData directly as an array
          
        } else {
          setError('Sale does not belong to the specified shop or invalid sale data.');
          setSaleDetails([]);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sale details:', error);
        setError('Error fetching sale details.');
        setLoading(false);
      }
    };

    const fetchSalesIds = async () => {
      try {
        const response = await axiosInstance.get(`/sales/ids/${shopId}`);
        
        setSalesIds(response.data);
        const currentIndex = response.data.findIndex(id => id === invoiceId);
        
        setCurrentIndex(currentIndex);
      } catch (error) {
        console.error('Error fetching sales IDs:', error);
        setError('Error fetching sales IDs.');
      }
    };

    fetchSaleDetails();
    fetchSalesIds();
  }, [invoiceId, shopId]);

  const handlePrev = () => {
    if (currentIndex > 0) {
      navigate(`/admin/shop/${shopId}/sales/details/${salesIds[currentIndex - 1]}`);
    }
  };

  const handleNext = () => {
    if (currentIndex < salesIds.length - 1) {
      navigate(`/admin/shop/${shopId}/sales/details/${salesIds[currentIndex + 1]}`);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
      <CircularProgress size={40} />
      <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
    </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" color="error">{error}</Typography>
        </Box>
    );
  }
  if (saleDetails.length === 0) return <Box className="invoice-container"><Typography>No details available.</Typography></Box>;

  const invoiceInfo = saleDetails[0];
  const subtotal = invoiceInfo.totalPrice ? invoiceInfo.totalPrice.toFixed(2) : '0.00';
  const totalProfit = invoiceInfo.profit ? invoiceInfo.profit.toFixed(2) : '0.00';
  const grandTotal = parseFloat(subtotal);

  const paymentStatusStyle = {
    Paid: { color: 'green', fontWeight: 'bold' },
    Unpaid: { color: 'red', fontWeight: 'bold' },
    Partial: { color: 'orangeRed', fontWeight: 'bold' }
  };

  return (
    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px' }}>
      <Box sx={{ position: 'absolute', left: 0, display: 'flex', alignItems: 'center' }}>
        <Button variant="contained" color="primary" onClick={handlePrev} disabled={currentIndex === 0}>
          Previous
        </Button>
      </Box>

      <Box className="invoice-container" sx={{ width: '80%', maxWidth: '1200px' }}>
        <Box className="invoice-header-container">
          <Typography variant="h4" className="invoice-title">INVOICE</Typography>
          {invoiceInfo.voided && <Typography color="error"><strong>VOIDED</strong></Typography>}
          <Box className="invoice-header-details">
            <Typography className="invoice-customer"><strong>Invoice To:</strong> {invoiceInfo.customerName}</Typography>
          </Box>
        </Box>

        <Box className="invoice-header" sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box className="invoice-header-left">
            <Typography><strong>Invoice ID:</strong> {invoiceInfo.invoiceId}</Typography>
            <Typography><strong>Created By:</strong> {invoiceInfo.createdBy}</Typography>
            <Typography><strong>Date:</strong> {new Date(invoiceInfo.saleDate).toLocaleDateString()}</Typography>
          </Box>
          <Box className="invoice-header-right">
            <Typography><strong>Payment Method:</strong> {invoiceInfo.paymentMethod}</Typography>
            {invoiceInfo.paymentMethod === 'mobile_banking' && (
              <Typography><strong>Transaction ID:</strong> {invoiceInfo.transactionId || 'N/A'}</Typography>
            )}
            {invoiceInfo.paymentMethod === 'split_payment' && (
              <>
                <Typography><strong>In Cash:</strong> {invoiceInfo.cashAmount ? invoiceInfo.cashAmount.toFixed(2) : 'N/A'} Br.</Typography>
                <Typography><strong>In Mobile Banking:</strong> {invoiceInfo.mobileBankingAmount ? invoiceInfo.mobileBankingAmount.toFixed(2) : 'N/A'} Br.</Typography>
              </>
            )}
            {invoiceInfo.paymentMethod === 'credit' && (
              <Typography>
                <strong>Payment Status: </strong> 
                <span style={paymentStatusStyle[invoiceInfo.paymentStatus] || {}}>
                  {invoiceInfo.paymentStatus || 'N/A'}
                </span>
              </Typography>
            )}
          </Box>
        </Box>

        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Profit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={invoiceInfo._id}>
                <TableCell>{invoiceInfo.product?.name || 'N/A'}</TableCell>
                <TableCell>{invoiceInfo.quantity || 'N/A'}</TableCell>
                <TableCell>{invoiceInfo.unitPrice ? invoiceInfo.unitPrice.toFixed(2) : 'N/A'}</TableCell>
                <TableCell>{invoiceInfo.totalPrice ? invoiceInfo.totalPrice.toFixed(2) : 'N/A'}</TableCell>
                <TableCell>{invoiceInfo.profit ? invoiceInfo.profit.toFixed(2) : 'N/A'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box className="invoice-footer" sx={{ mb: 3 }}>
          <Box className="invoice-footer-line" sx={{ borderBottom: '1px solid #000', mb: 2 }}></Box>
          <Box className="invoice-footer-details" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box className="invoice-footer-detail">
              <Typography className="subtotal"><strong>Subtotal:</strong> {subtotal} Br.</Typography>
              <Typography className="total-profit"><strong>Total Profit:</strong> {totalProfit} Br.</Typography>
              <Typography className="grand-total"><strong>Grand Total:</strong> {grandTotal.toFixed(2)} Br.</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ position: 'absolute', right: 0, display: 'flex', alignItems: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleNext} disabled={currentIndex === salesIds.length - 1}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ShopSaleDetails;
