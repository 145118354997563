import React, { useState } from 'react';
import { Container, Box, Typography, Button, Card, CardContent, Menu, MenuItem, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const AccountPage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownType, setDropdownType] = useState(null);

  const handleMenuClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    setDropdownType(type);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setDropdownType(null);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h4" component="h1" color="primary" gutterBottom>
          Accounts Management
        </Typography>
        <Card sx={{ mt: 4, p: 4, bgcolor: '#f5f5f5', boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h6" color="primary" gutterBottom>
              Manage your accounts efficiently
            </Typography>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Box>
                <Button
                  variant="contained"
                  sx={{ bgcolor: '#367588', '&:hover': { bgcolor: '#25525D' }, position: 'relative' }}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(e) => handleMenuClick(e, 'payables')}
                  startIcon={<AccountBalanceWalletIcon />}
                >
                  Account Payables
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={dropdownType === 'payables'}
                  onClose={handleMenuClose}
                  PaperProps={{
                    sx: {
                      width: 200,
                      mt: 1,
                      '& .MuiMenuItem-root': {
                        '&:hover': {
                          bgcolor: '#e3f2fd',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem component={RouterLink} to="/admin/accounts/account-payables/purchases">Supplier Payments</MenuItem>
                  {/* Add other menu items if needed */}
                </Menu>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{ bgcolor: '#367588', '&:hover': { bgcolor: '#25525D' }, position: 'relative' }}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={(e) => handleMenuClick(e, 'receivables')}
                  startIcon={<AccountBalanceIcon />}
                >
                  Account Receivables
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={dropdownType === 'receivables'}
                  onClose={handleMenuClose}
                  PaperProps={{
                    sx: {
                      width: 200,
                      mt: 1,
                      '& .MuiMenuItem-root': {
                        '&:hover': {
                          bgcolor: '#e3f2fd',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem component={RouterLink} to="/admin/accounts/account-receivables/sales">Outstanding Payments</MenuItem>
                  {/* Add other menu items if needed */}
                </Menu>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default AccountPage;
