import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  CircularProgress,
  Typography,
  Card,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert
} from '@mui/material';
import { format } from 'date-fns';

const statusStyles = {
  Paid: { color: 'green', fontWeight: 'bold' },
  Unpaid: { color: 'red', fontWeight: 'bold' },
  Partial: { color: 'orangeRed', fontWeight: 'bold' },
  NotAvailable: { color: 'red', fontWeight: 'bold' },
};

const PurchaseDetail = () => {
  const { purchaseId } = useParams();
  const [purchase, setPurchase] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPurchaseDetail = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get(`/purchases/${purchaseId}`);
        setPurchase(response.data);
      } catch (error) {
        setError('Error fetching purchase details');
      } finally {
        setLoading(false);
      }
    };

    fetchPurchaseDetail();
  }, [purchaseId]);

  const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (loading) return <div className="text-center mt-5"><CircularProgress /></div>;
  if (error) return <div className="text-center mt-5"><Alert severity="error">{error}</Alert></div>;
  if (!purchase) return <div className="text-center mt-5"><Typography>No purchase found.</Typography></div>;

  // Check if all products are deleted
  const allProductsDeleted = purchase.products.every(item => item.product.deleted);
  // Check if some products are deleted
  const someProductsDeleted = purchase.products.some(item => item.product.deleted);

  // Determine status to display
  const displayStatus = allProductsDeleted ? 'Not Available' : purchase.paymentStatus;
  const displayStatusStyle = allProductsDeleted ? statusStyles.NotAvailable : statusStyles[purchase.paymentStatus];

  // Calculate total purchase price, excluding deleted products
  const totalPurchasePrice = purchase.products.reduce((total, item) => item.product.deleted ? total : total + item.totalPrice, 0);

  return (
    <div style={{ padding: '16px' }}>
      <Card style={{ marginBottom: '16px' }}>
        <CardContent>
          <Typography variant="h5" color="primary">Purchase Detail</Typography>
          <Button variant="contained" color="primary" sx={{ mt: 2,}}onClick={() => window.history.back()}>
            Back
          </Button>
        </CardContent>
      </Card>

      {allProductsDeleted && (
        <Alert severity="error" style={{ marginBottom: '16px' }}>
          All purchased products have been disposed of from the inventory.
        </Alert>
      )}

      {someProductsDeleted && !allProductsDeleted && (
        <Alert severity="warning" style={{ marginBottom: '16px' }}>
          Some purchased products have been disposed of from the inventory.
        </Alert>
      )}

<Card style={{ marginBottom: '16px' }}>
  <CardContent>
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {purchase.supplier?.name === 'Import' ? (
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="subtitle1">These are imported products</Typography>
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">Supplier :</Typography>
              </TableCell>
              <TableCell>{purchase.supplier?.name || 'Supplier not found'}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">Purchased By :</Typography>
            </TableCell>
            <TableCell>{purchase.purchasedBy?.username || 'User not found'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">Purchase Date :</Typography>
            </TableCell>
            <TableCell>{format(new Date(purchase.purchaseDate), 'MM/dd/yyyy')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">Total Purchase Price :</Typography>
            </TableCell>
            <TableCell>{formatNumberWithCommas(totalPurchasePrice.toFixed(2))} Br.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">Total Paid Amount :</Typography>
            </TableCell>
            <TableCell>{formatNumberWithCommas(purchase.totalPaidAmount.toFixed(2))} Br.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1">Payment Status :</Typography>
            </TableCell>
            <TableCell style={displayStatusStyle}>
              {displayStatus}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </CardContent>
</Card>


      <Card>
        <CardContent>
          <Typography variant="h6" color="primary" gutterBottom>Products</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit Price</TableCell>
                  <TableCell>Total Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchase.products.map((item) => (
                  <TableRow
                    key={item.product._id}
                    style={{ color: item.product.deleted ? 'red' : 'inherit', fontStyle: item.product.deleted ? 'italic' : 'normal' }}
                  >
                    <TableCell>{item.product.deleted ? 'Not Available' : item.product.name}</TableCell>
                    <TableCell>{item.product.deleted ? 'Not Available' : item.product.description}</TableCell>
                    <TableCell>{item.product.deleted ? '-' : formatNumberWithCommas(item.quantity)}</TableCell>
                    <TableCell>{item.product.deleted ? '-' : formatNumberWithCommas(item.unitPrice.toFixed(2))} Br.</TableCell>
                    <TableCell>{item.product.deleted ? '-' : formatNumberWithCommas(item.totalPrice.toFixed(2))} Br.</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
};

export default PurchaseDetail;
