import React from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon} from '@mui/icons-material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import useCategories from '../hooks/useCategories';

const CategoryList = () => {
  const {
    categories,
    editingId,
    newCategory,
    formErrors,
    loading,
    error,
    openConfirmDialog,
    categoryToDelete,
    handleNewCategoryChange,
    handleAddCategory,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    handleInputChange,
    handleDeleteClick,
    handleConfirmDelete,
    handleCancelDelete,
  } = useCategories();

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" color="primary">Category List</Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit">Categories</Typography>
          <Typography color="textPrimary">Category List</Typography>
        </Breadcrumbs>
      </Box>
      <Box sx={{ mb: 3 }}>
        <TextField
          label="New Category Name"
          variant="outlined"
          name="name"
          value={newCategory.name}
          onChange={handleNewCategoryChange}
          fullWidth
          sx={{ mb: 2 }}
          error={Boolean(formErrors.name)}
          helperText={formErrors.name}
        />
        <TextField
          label="Description"
          variant="outlined"
          name="description"
          value={newCategory.description}
          onChange={handleNewCategoryChange}
          fullWidth
          sx={{ mb: 2 }}
          error={Boolean(formErrors.description)}
          helperText={formErrors.description}
        />
        <Button
          onClick={handleAddCategory}
          variant="contained"
          color="primary"
          disabled={!newCategory.name.trim() || !newCategory.description.trim()}
        >
          Add Category
        </Button>
      </Box>

      
        <Box sx={{ overflowX: 'auto' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              { loading ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <CircularProgress />
                  <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
                </TableCell>
              </TableRow>
            ) :
            categories.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography>No categories available</Typography>
                </TableCell>
              </TableRow>
            ) : (
                categories.map((category) => (
                  <TableRow key={category._id}>
                    <TableCell>
                      {editingId === category._id ? (
                        <TextField
                          variant="outlined"
                          name="name"
                          value={category.name}
                          onChange={(e) => handleInputChange(e, category._id)}
                          fullWidth
                          error={Boolean(formErrors.name)}
                          helperText={formErrors.name}
                        />
                      ) : (
                        category.name
                      )}
                    </TableCell>
                    <TableCell>
                      {editingId === category._id ? (
                        <TextField
                          variant="outlined"
                          name="description"
                          value={category.description}
                          onChange={(e) => handleInputChange(e, category._id)}
                          fullWidth
                          error={Boolean(formErrors.description)}
                          helperText={formErrors.description}
                        />
                      ) : (
                        category.description
                      )}
                    </TableCell>
                    <TableCell>
                      {editingId === category._id ? (
                        <>
                          <Button
                            onClick={() => handleSaveClick(category._id)}
                            variant="contained"
                            color="primary"
                            sx={{ mr: 1 }}
                          >
                            Save
                          </Button>
                          <Button
                            onClick={handleCancelClick}
                            color="error"
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={() => handleEditClick(category._id)}
                            color="primary"
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => handleDeleteClick(category._id)}
                            color="error"
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

      {/* Confirm Delete Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={handleCancelDelete}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="text-danger">Confirm Delete</DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'center' }}>
            {categoryToDelete ? (
              <>
                <Typography variant="body1" sx={{ mb: 2 }}>
                Please confirm if you wish to delete the following category{' '}
                  <strong>{categoryToDelete.name}</strong>.
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 2 }}>
                  Description: {categoryToDelete.description}
                </Typography>
              </>
            ) : (
              <Typography>Loading category details...</Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCancelDelete}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CategoryList;
