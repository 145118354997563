import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow,
  Card, CardContent, Pagination, IconButton, Tooltip
} from '@mui/material';
import { axiosInstance } from '../../Axios/AxiosInstance';
import InfoIcon from '@mui/icons-material/Info';

const ShopReturns = () => {
  const { shopId } = useParams();
  const navigate = useNavigate();
  const [returns, setReturns] = useState([]);
  const [shopName, setShopName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalReturns, setTotalReturns] = useState(0);
  const [limit] = useState(10);

  useEffect(() => {
    const fetchShopDetailsAndReturns = async () => {
      try {
        const shopResponse = await axiosInstance.get(`/shops/${shopId}`);
        setShopName(shopResponse.data.name);

        const returnsResponse = await axiosInstance.get(`/sales/returns/shop/${shopId}`, {
          params: { page, limit },
        });
        setReturns(returnsResponse.data.returns);
        setTotalReturns(returnsResponse.data.total);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchShopDetailsAndReturns();
  }, [shopId, page, limit]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleViewReturnDetails = (invoiceId) => {
    navigate(`/admin/shop/${shopId}/returns/details/${invoiceId}`);
  };

  const groupedReturns = returns.reduce((acc, returnItem) => {
    const existing = acc.find(item => item.invoiceId === returnItem.invoiceId);
    if (existing) {
      existing.numberOfProductsReturned += returnItem.numberOfProductsReturned;
    } else {
      acc.push({ ...returnItem });
    }
    return acc;
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        <span style={{ color: '#367588' }}>Returns for Shop:</span> {shopName}
      </Typography>

      <Card variant="outlined" sx={{ borderRadius: 2 }}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Invoice No</TableCell>
                <TableCell>Number of Products Returned</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <CircularProgress />
                    <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                groupedReturns.length > 0 ? (
                  groupedReturns.map((returnItem) => (
                    <TableRow key={returnItem._id}>
                      <TableCell>{returnItem.invoiceId}</TableCell>
                      <TableCell>{returnItem.numberOfProductsReturned}</TableCell>
                      <TableCell>{returnItem.customerName}</TableCell>
                      <TableCell>{new Date(returnItem.returnDate).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <Tooltip title="View Details">
                          <IconButton onClick={() => handleViewReturnDetails(returnItem.invoiceId)} color="primary">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No returns available for this shop.
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
          {!loading && groupedReturns.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Pagination
                count={Math.ceil(totalReturns / limit)}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ShopReturns;
