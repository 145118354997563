import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Alert,
  Snackbar,
  CircularProgress,
  Switch,
} from '@mui/material';
import axios from 'axios';
import { Check } from '@mui/icons-material';
import Header from '../header/Header'; // Adjust import path as needed
import logo from '../../assets/images/Inventory1.svg'; // Adjust import path as needed

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Added to track severity
  const [isLightOn, setIsLightOn] = useState(false); // State for light switch

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    setLoading(true);

    try {
      // Replace with your API endpoint
      const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/forgot-password`, { email });
      setMessage(response.data.message);
      setSnackbarSeverity('success');
      setEmail('');
    } catch (err) {
      setError(err.response.data.message || 'Something went wrong. Please try again.');
      setSnackbarSeverity('error'); // Set severity to error
      setEmail('');
    } finally {
      setLoading(false);
      setSnackbarOpen(true); // Open snackbar after API response
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Handle toggle switch change
  const handleLightSwitch = (event) => {
    setIsLightOn(event.target.checked);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #29323c, #485563)',
        color: '#fff',
        padding: 0,
      }}
    >
      <Header
        links={[
          { label: 'Home', to: '/' },
          { label: 'Signin', to: '/auth/signin' },
        ]}
      />

      <Box
        sx={{
          flexGrow: 1, // Allow main content to take remaining space
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3,
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              boxShadow: 12,
              borderRadius: 3,
              overflow: 'hidden',
              background: isLightOn
                ? 'linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0) 50%), rgba(255, 255, 255, 0.9)'
                : 'rgba(0, 0, 0, 0)',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6} sx={{ p: 5 }}>
                {error && (
                  <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                      {error}
                    </Alert>
                  </Snackbar>
                )}
                {message && (
                  <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                      {message}
                    </Alert>
                  </Snackbar>
                )}
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                  <img src={logo} alt="Logo" style={{ maxWidth: '150px', margin: '0 auto' }} />
                </Box>
                <Typography variant="h5" component="h3" gutterBottom sx={{ color: '#367588' }}>
                  Forgot Password
                </Typography>
                <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Email Address"
                  type="email"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  error={Boolean(error)}
                  helperText={error}
                  autoComplete="email"
                  InputProps={{
                    style: {
                      color: isLightOn ? '#000' : '#fff', // Black text when light is on, white when light is off
                    },
                  }}
                />

                  <Box sx={{ mt: 3 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      sx={{ backgroundColor: '#367588', color: '#fff', '&:hover': { backgroundColor: '#5f9ea0' } }}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Send Reset Link'}
                    </Button>
                  </Box>
                </form>
              </Grid>
              <Grid item xs={12} md={6} sx={{ p: 5, backgroundColor: '#2b556a', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h4" component="h2" gutterBottom>
                  Forgot Your Password?
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Don’t worry, we’ve got you covered. Just enter your email address and we’ll send you a link to reset your password.
                </Typography>
                <List
                  sx={{
                    listStyleType: 'disc',
                    paddingLeft: 3,
                    '& .MuiListItem-root': {
                      color: 'white',
                    },
                  }}
                >
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <Check sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary="Easy and quick password reset" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <Check sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary="Secure link sent to your email" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <Check sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary="Follow simple instructions" />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
        </Container>
        {/* Toggle Switch for Light */}
        <Box sx={{ position: 'fixed', bottom: 40, right: 20 }}>
          <Typography variant="h6" sx={{ color: '#fff' }}>Toggle Light</Typography>
          <Switch checked={isLightOn} onChange={handleLightSwitch} color="default" />
        </Box>
      </Box>
      <Box
  component="footer"
  sx={{
    width: '100%',
    py: 2,
    textAlign: 'center',
  }}
>
  <Box
    sx={{
      width: '40%',          // Reduce the width
      borderTop: '2px solid #333', // Matte black line
      margin: '0 auto',      // Center the line
    }}
  />
  <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
    © 2024 AddisLogix. All rights reserved.
  </Typography>
</Box>
    </Box>
  );
};

export default ForgotPassword;
