import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Snackbar, Alert, Button,  IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAuth } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

// Format number with commas
const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const TodaySale = () => {
  const { username } = useAuth();
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchSales = async () => {
      if (username) {
        try {
          const response = await axiosInstance.get('/sales/non-voided-sales', {
            params: { createdBy: username }
          });

          // Filter sales for today
          const today = new Date();
          const startOfDay = new Date(today.setHours(0, 0, 0, 0));
          const endOfDay = new Date(today.setHours(23, 59, 59, 999));
          const todaySales = response.data.filter(sale => {
            const saleDate = new Date(sale.saleDate);
            return saleDate >= startOfDay && saleDate <= endOfDay;
          });

          // Aggregate sales data by invoice
          const invoiceData = todaySales.reduce((acc, sale) => {
            if (!acc[sale.invoiceId]) {
              acc[sale.invoiceId] = {
                count: 0,
                totalPrice: 0,
                paymentMethod: sale.paymentMethod,
                paymentStatus: sale.paymentStatus,
                customerName: sale.customerName,
                saleDate: sale.saleDate,
              };
            }
            acc[sale.invoiceId].count += 1;
            acc[sale.invoiceId].totalPrice += sale.totalPrice;
            return acc;
          }, {});

          setSales(Object.entries(invoiceData).map(([invoiceId, data]) => ({
            invoiceId,
            ...data
          })));
        } catch (error) {
          console.error('Error fetching sales:', error);
          setError('Failed to fetch sales data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSales();
  }, [username]);

  const handleCopy = (invoiceId) => {
    navigator.clipboard.writeText(invoiceId);
    setSnackbarOpen(true); // Show Snackbar after copying
  };

  if (loading) return <CircularProgress />;
  if (error) return <Snackbar open={true} autoHideDuration={6000}><Alert severity="error">{error}</Alert></Snackbar>;

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" color="primary" gutterBottom>
        Today's Sales
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice ID</TableCell>
              <TableCell>Number of Products</TableCell>
              <TableCell>Total Price</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell>Sale Date</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {sales.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} sx={{ textAlign: 'center', padding: 2 }}>
                  <Typography variant="h6" color="textSecondary">
                    No sales available today
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
            sales.map(({ invoiceId, count, totalPrice, paymentMethod, paymentStatus, saleDate, customerName }) => (
              <TableRow key={invoiceId}>
                <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {invoiceId}
                      <Tooltip title="Copy Invoice ID">
                        <IconButton onClick={() => handleCopy(invoiceId)} sx={{ marginLeft: 1 }}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                <TableCell>{count}</TableCell>
                <TableCell>{formatNumberWithCommas(totalPrice.toFixed(2))} Br.</TableCell>
                <TableCell>{paymentMethod}</TableCell>
                <TableCell>{new Date(saleDate).toLocaleString()}</TableCell>
                <TableCell>{customerName || 'N/A'}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => navigate(`/admin/sales/sale-detail/${invoiceId}`)} // Navigate to the detail page
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))
          )}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
  open={snackbarOpen}
  autoHideDuration={3000}
  onClose={() => setSnackbarOpen(false)}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}  // Set Snackbar to top center
>
  <Alert onClose={() => setSnackbarOpen(false)} severity="success">
    Invoice ID copied to clipboard!
  </Alert>
</Snackbar>
    </Box>
  );
};

export default TodaySale;
