import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { Box, Typography, CircularProgress, Snackbar, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { useParams } from 'react-router-dom'; // Import useParams for route parameters
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const ReturnDetail = () => {
  const { invoiceId } = useParams(); // Get invoiceId from route parameters
  const [returnDetails, setReturnDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (invoiceId) {
      fetchReturnDetails();
    }
  }, [invoiceId]);

  const fetchReturnDetails = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/sales/return-details', {
        params: { invoiceId } // Pass invoiceId to request parameters
      });
      setReturnDetails(response.data);
      setError('');
    } catch (error) {
      console.error('Error fetching return details:', error);
      setError('Failed to fetch return details');
    } finally {
      setLoading(false);
    }
  };

  // Group and summarize the return details
  const groupedReturnDetails = returnDetails.reduce((acc, item) => {
    const key = `${item.productName}|${item.returnReason}`;
    if (!acc[key]) {
      acc[key] = {
        productName: item.productName,
        returnReason: item.returnReason,
        quantity: 0,
        profit: 0,
        returnDate: item.returnDate // Assuming the date is the same for grouped items
      };
    }
    acc[key].quantity += item.quantity;
    acc[key].profit += item.profit || 0;
    return acc;
  }, {});

  const summarizedReturnDetails = Object.values(groupedReturnDetails);

  if (loading) return <CircularProgress />;
  if (error) return <Snackbar open={true} autoHideDuration={6000}><Alert severity="error">{error}</Alert></Snackbar>;

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
      <span style={{ color: '#367588' }} >Return Details for Invoice:</span> {invoiceId}
      </Typography>
      <Button
        variant="contained"
        sx={{ marginTop: 2 }}
        onClick={() => navigate('/admin/returns')} // Navigate back to the return list
      >
        Back to Return List
      </Button>
      {summarizedReturnDetails.length > 0 ? (
        <>
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Customer Name: {returnDetails[0].customerName || 'N/A'}
          </Typography>
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Return Reason</TableCell>
                  <TableCell>Return Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summarizedReturnDetails.map((returnItem, index) => (
                  <TableRow key={index}>  {/* Ensure unique key */}
                    <TableCell>{returnItem.productName || 'N/A'}</TableCell>
                    <TableCell>{returnItem.quantity}</TableCell>
                    <TableCell>{returnItem.returnReason || 'N/A'}</TableCell>
                    <TableCell>{new Date(returnItem.returnDate).toLocaleDateString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography>No return details found for this invoice</Typography>
      )}
    </Box>
  );
};

export default ReturnDetail;
