import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const ProtectedRoute = ({ element, roles }) => {
  const { isAuthenticated, role, loading} = useAuth();

  if (loading) return <div>Loading...</div>;

  if (!isAuthenticated) {
    return <Navigate to="/auth/signin" />;
  }

  if (roles && !roles.includes(role)) {
    return <Navigate to="/auth/access-denied" />;
  }

  return element;
};

export default ProtectedRoute;
