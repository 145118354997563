import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the styles

// Import components
import Signin from './components/auth/Signin';
import VerifySecurityKey from './components/auth/VerifySecurityKey';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import AdminPage from './pages/AdminPage';
import AccessDenied from './components/auth/AccessDenied';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Presentation from './pages/Presentation';
import { AuthProvider } from './components/auth/AuthContext';
import Loading from './pages/Loading';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        {/* ToastContainer provides the notification container */}
        <ToastContainer 
          position="top-right" 
          autoClose={5000} 
          hideProgressBar={false} 
          newestOnTop={false} 
          closeOnClick 
          rtl={false} 
          pauseOnFocusLoss 
          draggable 
          pauseOnHover 
        />
        
        <Routes>
          <Route path="/auth/signin" element={<Signin />} />
          <Route path="/auth/verify-security-key" element={<VerifySecurityKey />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route path="/auth/reset-password/:token" element={<ResetPassword />} />
          <Route path="/admin/*" element={<AdminPage />} />
          <Route path="/auth/access-denied" element={<AccessDenied />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/loading" element={<Loading />} />
          <Route path="/" element={<Presentation />} />
          <Route path="*" element={<Navigate to="/" />} /> {/* Handles unknown routes */}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
