import React, { useState, useEffect, useMemo } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Button,
  TablePagination
} from '@mui/material';

const ProductSummary = () => {
  const [products, setProducts] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [grandTotalValue, setGrandTotalValue] = useState(0);
  const [includeDistributions, setIncludeDistributions] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const [productsResponse, purchasesResponse, distributionsResponse] = await Promise.all([
        axiosInstance.get('/products'),
        axiosInstance.get('/purchases'),
        axiosInstance.get('/distributions')
      ]);


      const distributionsData = Array.isArray(distributionsResponse.data.data) ? distributionsResponse.data.data : [];
      
      setProducts(productsResponse.data);
      setPurchases(purchasesResponse.data);
      setDistributions(distributionsData);
    } catch (error) {
      setError('Failed to load data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const calculateProductDetails = useMemo(() => {
    let grandTotal = 0;

    const detailedProducts = products.map(product => {
      const filteredPurchases = purchases.filter(purchase => 
        purchase.product === product._id
      );

      const filteredDistributions = includeDistributions
        ? distributions.filter(dist => dist.product._id === product._id && dist.amountLeft > 0)
        : [];

      const totalShopQuantity = filteredDistributions.reduce(
        (total, dist) => total + (dist.amountLeft || 0),
        0
      );

      const totalStock = product.stock + totalShopQuantity;
      const unitPrice = product.price;

      const totalValue = totalStock * unitPrice;
      grandTotal += totalValue;

      return {
        ...product,
        totalStock,
        totalValue
      };
    });

    setGrandTotalValue(grandTotal);

    return detailedProducts;
  }, [products, purchases, distributions, includeDistributions]);

  const handleIncludeDistributionsToggle = () => setIncludeDistributions(prev => !prev);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat().format(number);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4" component="h1" color="primary">Product Summary</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleIncludeDistributionsToggle} sx={{ ml: 2 }}>
            {includeDistributions ? 'Exclude Distributions' : 'Include Distributions'}
          </Button>
        </Box>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : error ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" color="error">{error}</Typography>
        </Box>
      ) : (
        <>
          <TableContainer sx={{ maxHeight: 600, overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Price (Br.)</TableCell>
                  <TableCell>Total Stock</TableCell>
                  <TableCell>Reorder Level</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Total Value (Br.)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calculateProductDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
                  <TableRow key={product._id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.description}</TableCell>
                    <TableCell>{formatNumber(product.price.toFixed(2))}</TableCell>
                    <TableCell>{product.totalStock}</TableCell>
                    <TableCell>{product.reorderLevel}</TableCell>
                    <TableCell>{product.unit ? product.unit.name : 'N/A'}</TableCell>
                    <TableCell>{product.category ? product.category.name : 'N/A'}</TableCell>
                    <TableCell>{formatNumber(product.totalValue.toFixed(2))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="td"
                    count={calculateProductDetails.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
                <TableRow sx={{ 
                  display: 'flex', 
                  width: '100%', 
                  position: 'sticky', 
                  bottom: 0, 
                  backgroundColor: '#f5f5f5', 
                  borderTop: '2px solid #ddd',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: '8px'
                }}>
                  <TableCell 
                    colSpan={7} 
                    align="right" 
                    sx={{ 
                      fontWeight: 'bold', 
                      fontSize: '1rem', 
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: '#367588'
                    }}
                  >
                    Grand Total Value:
                  </TableCell>
                  <TableCell 
                    sx={{ 
                      fontWeight: 'bold', 
                      fontSize: '1rem', 
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                      display: 'inline-block',
                      maxWidth: '150px' // Adjust maxWidth as needed
                    }}
                  >
                    {formatNumber(grandTotalValue.toFixed(2))} Br.
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default ProductSummary;
