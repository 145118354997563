import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

const AccountReceivableList = () => {
  const [accountReceivables, setAccountReceivables] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [datePickerType, setDatePickerType] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false); // Track if search has been performed
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccountReceivables = async () => {
      setLoading(true);
      setSnackbarMessage('');
      setSnackbarSeverity('success');

      try {
        const response = await axiosInstance.get('/account-receivables', {
          params: {
            search,
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
          },
        });
        setAccountReceivables(response.data);
        setSearchPerformed(true); // Mark that search has been performed
      } catch (error) {
        setSnackbarMessage('Error fetching account receivables');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountReceivables();
  }, [search, startDate, endDate]);

  const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleEdit = (invoiceId) => {
    navigate(`/admin/accounts/account-receivables/sale/edit/${invoiceId}`);
  };

  const handleView = (invoiceId) => {
    navigate(`/admin/accounts/account-receivables/sale/view/${invoiceId}`);
  };

  const handleDeleteDialogOpen = (invoiceId) => {
    setDeleteId(invoiceId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setDeleteId(null);
  };

  const handleDelete = async () => {
    if (!deleteId) return;

    setLoading(true);
    setSnackbarMessage('');
    setSnackbarSeverity('success');

    try {
      await axiosInstance.delete(`/account-receivables/${deleteId}`);
      setSnackbarMessage('Account Receivable deleted successfully');
      setSnackbarSeverity('success');
      setAccountReceivables((prev) => prev.filter((ar) => ar.invoiceId !== deleteId));
    } catch (error) {
      console.error('Error deleting account receivable:', error);
      setSnackbarMessage('Failed to delete account receivable');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      handleDeleteDialogClose();
      setSnackbarOpen(true);
    }
  };

  const handleDatePickerOpen = (type) => {
    setDatePickerType(type);
    setDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setDatePickerOpen(false);
  };

  const handleDateChange = (date) => {
    if (datePickerType === 'startDate') {
      setStartDate(date);
    } else if (datePickerType === 'endDate') {
      setEndDate(date);
    }
    handleDatePickerClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" color="primary" gutterBottom>
          Account Receivable List - For Sales Account
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Manage and review your account receivables efficiently
        </Typography>

        {/* Search and Date Picker Inputs */}
        <Box sx={{ mb: 4 }}>
          <TextField
            label="Search by Customer Name"
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button
              variant="outlined"
              onClick={() => handleDatePickerOpen('startDate')}
            >
              Start Date: {startDate ? format(startDate, 'MM/dd/yyyy') : 'Select date'}
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleDatePickerOpen('endDate')}
            >
              End Date: {endDate ? format(endDate, 'MM/dd/yyyy') : 'Select date'}
            </Button>
          </Box>
        </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card sx={{ mt: 4 }}>
            <CardContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Invoice ID</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Customer Name</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Total Paid</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Total Sale Price</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Payment Status</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Created At</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Actions</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {accountReceivables.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                          <Typography variant="body1">
                            {searchPerformed 
                              ? 'No data available. Please adjust your search criteria or add new account receivables.' 
                              : 'No data available. Add new account receivables.'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      accountReceivables.map((ar, index) => (
                        <TableRow key={`${ar.invoiceId}-${index}`} sx={{ '&:nth-of-type(even)': { backgroundColor: '#f9f9f9' } }}>
                          <TableCell>{ar.invoiceId}</TableCell>
                          <TableCell>{ar.customerName}</TableCell>
                          <TableCell>{ar.totalPaidAmount != null ? formatNumberWithCommas(ar.totalPaidAmount.toFixed(2)) : '0.00'} Br.</TableCell>
                          <TableCell>{ar.totalSalePrice != null ? formatNumberWithCommas(ar.totalSalePrice.toFixed(2)) : '0.00'} Br.</TableCell>
                          <TableCell>{ar.paymentStatus}</TableCell>
                          <TableCell>{new Date(ar.createdAt).toLocaleDateString()}</TableCell>
                          <TableCell>
                            {ar.paymentStatus === 'Paid' ? (
                              <>
                                <IconButton onClick={() => handleView(ar.invoiceId)} color="primary" aria-label="view">
                                  <VisibilityIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDeleteDialogOpen(ar.invoiceId)} color="secondary" aria-label="delete" sx={{ color: 'error.main' }}>
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            ) : (
                              <IconButton onClick={() => handleEdit(ar.invoiceId)} color="primary" aria-label="edit">
                                <EditIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        )}

        <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to delete this account receivable?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={datePickerOpen} onClose={handleDatePickerClose}>
          <DialogTitle>Select Date</DialogTitle>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={datePickerType === 'startDate' ? 'Start Date' : 'End Date'}
                value={datePickerType === 'startDate' ? startDate : endDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDatePickerClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </Box>
    </Container>
  );
};

export default AccountReceivableList;
