import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Divider
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Dashboard = () => {
  const [totalProducts, setTotalProducts] = useState(0);
  const [todaysSale, setTodaysSale] = useState(0);
  const [todaysProfit, setTodaysProfit] = useState(0);
  const [outOfStock, setOutOfStock] = useState(0);
  const [productsBelowReorderLevel, setProductsBelowReorderLevel] = useState(0);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [recentSales, setRecentSales] = useState([]);
  const [todaysSaleCount, setTodaysSaleCount] = useState(0);
  const [isProfitVisible, setIsProfitVisible] = useState(false);
  const [isSaleVisible, setIsSaleVisible] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsResponse = await axiosInstance.get('/products');
        const products = Array.isArray(productsResponse.data) ? productsResponse.data : [];
        setTotalProducts(products.length);

        const outOfStockCount = products.filter(product => product.stock === 0).length;
        setOutOfStock(outOfStockCount);

        const belowReorderLevelCount = products.filter(product => product.stock <= product.reorderLevel).length;
        setProductsBelowReorderLevel(belowReorderLevelCount);

        const todaysSalesAndProfitResponse = await axiosInstance.get('/sales/todays-sales-and-profit');
        const { totalSales, totalProfit } = todaysSalesAndProfitResponse.data;
        setTodaysSale(totalSales);
        setTodaysProfit(totalProfit);

        const salesCountResponse = await axiosInstance.get('/sales/countToday');
        setTodaysSaleCount(salesCountResponse.data.count);

        const topSellingResponse = await axiosInstance.get('/sales/top-selling-products');
        setTopSellingProducts(topSellingResponse.data);

        const recentSalesResponse = await axiosInstance.get('/sales/recent-sales');
        setRecentSales(recentSalesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
      }
    };

    fetchData();
  }, []);

  const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const cardStyles = {
    borderRadius: 2,
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',  // Slightly deeper shadow
    backgroundColor: '#FFFFFF',  
    padding: '20px',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',  // Lift effect on hover
    },
  };

  return (
    <Box sx={{ p: 4, backgroundColor: '#F9FAFB', minHeight: '100vh' }}>  
      <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', mb: 4 }}>
        Dashboard
      </Typography>
      
      <Grid container spacing={3}>
        {/* Total Items */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardContent>
              <Typography variant="subtitle1" color="primary">Total Items</Typography>
              <Typography variant="h4" sx={{ fontWeight: '500', color: '#0077B6' }}>
                {formatNumberWithCommas(totalProducts)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Today's Sale */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardContent>
              <Typography variant="subtitle1" color="primary">Today's Sale</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: '500', color: '#FB8500' }}>
                  {isSaleVisible ? formatNumberWithCommas(Number(todaysSale).toFixed(2)) + ' Br.' : '*****'}
                </Typography>
                <IconButton onClick={() => setIsSaleVisible(!isSaleVisible)} sx={{ ml: 1 }}>
                  {isSaleVisible ? <VisibilityOff sx={{ color: '#FB8500' }} /> : <Visibility sx={{ color: '#FB8500' }} />}
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Today's Profit */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardContent>
              <Typography variant="subtitle1" color="primary">Today's Profit</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h4" sx={{ fontWeight: '500', color: '#38B000' }}>
                  {isProfitVisible ? formatNumberWithCommas(Number(todaysProfit).toFixed(2)) + ' Br.' : '*****'}
                </Typography>
                <IconButton onClick={() => setIsProfitVisible(!isProfitVisible)} sx={{ ml: 1 }}>
                  {isProfitVisible ? <VisibilityOff sx={{ color: '#38B000' }} /> : <Visibility sx={{ color: '#38B000' }} />}
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Sales Made Today */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardContent>
              <Typography variant="subtitle1" color="primary">Sales Made Today</Typography>
              <Typography variant="h4" sx={{ fontWeight: '500', color: '#5A67D8' }}>
                {todaysSaleCount}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Products Below Reorder Level */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardContent>
              <Typography variant="subtitle1" color="primary">Products Below Reorder Level</Typography>
              <Typography variant="h4" sx={{ fontWeight: '500', color: '#D61C4E' }}>
                {productsBelowReorderLevel}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Out of Stock */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles}>
            <CardContent>
              <Typography variant="subtitle1" color="primary">Out of Stock</Typography>
              <Typography variant="h4" sx={{ fontWeight: '500', color: '#FF1744' }}>
                {outOfStock}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Top-Selling Products */}
      <Box sx={{ mt: 5 }}>
        <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', mb: 3 }}>
          Top-Selling Products
        </Typography>
        {topSellingProducts.length > 0 ? (
          <TableContainer component={Paper} sx={{ boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)' }}>
            <Table>
              <TableHead sx={{ backgroundColor: '#0077B6' }}>
                <TableRow>
                  <TableCell sx={{ color: '#fff' }}>Name</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Description</TableCell>
                  <TableCell align="right" sx={{ color: '#fff' }}>Sales</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topSellingProducts.map((product, index) => (
                  <TableRow key={index}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.description}</TableCell>
                    <TableCell align="right">{product.sales}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center" color="primary">No top-selling products available.</Typography>
        )}
      </Box>

      {/* Recent Sales */}
      <Box sx={{ mt: 5 }}>
        <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', mb: 3 }}>
          Recent Sales
        </Typography>
        {recentSales.length > 0 ? (
          <TableContainer component={Paper} sx={{ boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)' }}>
            <Table>
              <TableHead sx={{ backgroundColor: '#38B000' }}>
                <TableRow>
                  <TableCell sx={{ color: '#fff' }}>Invoice ID</TableCell>
                  <TableCell align="right" sx={{ color: '#fff' }}>Number of Products Sold</TableCell>
                  <TableCell align="right" sx={{ color: '#fff' }}>Total Quantity</TableCell>
                  <TableCell align="right" sx={{ color: '#fff' }}>Total Price</TableCell>
                  <TableCell align="right" sx={{ color: '#fff' }}>Total Profit</TableCell>
                  <TableCell align="right" sx={{ color: '#fff' }}>Sale Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentSales.map((invoice) => {
                  const totalQuantity = invoice.sales.reduce((sum, sale) => sum + sale.quantity, 0);
                  const totalPrice = invoice.sales.reduce((sum, sale) => sum + sale.totalPrice, 0);
                  const totalProfit = invoice.sales.reduce((sum, sale) => sum + sale.profit, 0);

                  return (
                    <TableRow key={invoice._id}>
                      <TableCell>{invoice._id}</TableCell>
                      <TableCell align="right">{invoice.sales.length}</TableCell>
                      <TableCell align="right">{formatNumberWithCommas(totalQuantity)}</TableCell>
                      <TableCell align="right">{formatNumberWithCommas(totalPrice.toFixed(2))} Br.</TableCell>
                      <TableCell align="right">{formatNumberWithCommas(totalProfit.toFixed(2))} Br.</TableCell>
                      <TableCell align="right">{new Date(invoice.sales[0]?.saleDate).toLocaleDateString()}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography align="center" color="primary">No recent sales data available.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
