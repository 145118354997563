import { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';

export const useCustomers = () => {
  const [customers, setCustomers] = useState([]);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  return { customers, fetchCustomers };
};
