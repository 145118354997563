import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

const AccountPayableList = () => {
  const [accountPayables, setAccountPayables] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [datePickerType, setDatePickerType] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccountPayables = async () => {
      setLoading(true);
      setError('');
      setSuccess('');

      try {
        const response = await axiosInstance.get('/account-payables', {
          params: {
            search,
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
          },
        });
        setAccountPayables(response.data);
      } catch (error) {
        setError('Error fetching account payables');
      } finally {
        setLoading(false);
      }
    };

    fetchAccountPayables();
  }, [search, startDate, endDate]);

  const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleEdit = (id) => {
    navigate(`/admin/accounts/account-payables/purchase/edit/${id}`);
  };

  const handleView = (id) => {
    navigate(`/admin/accounts/account-payables/purchase/view/${id}`);
  };

  const handleDelete = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await axiosInstance.delete(`/account-payables/${selectedId}`);
      setSuccess('Account payable deleted successfully');
      const response = await axiosInstance.get('/account-payables', {
        params: {
          search,
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null,
        },
      });
      setAccountPayables(response.data);
    } catch (error) {
      setError('Error deleting account payable: ' + (error.response?.data?.message || 'Unknown error'));
    } finally {
      setLoading(false);
      setOpenConfirmDialog(false);
    }
  };

  const handleConfirmDelete = (id) => {
    setSelectedId(id);
    setOpenConfirmDialog(true);
  };

  const handleDatePickerOpen = (type) => {
    setDatePickerType(type);
    setDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setDatePickerOpen(false);
  };

  const handleDateChange = (date) => {
    if (datePickerType === 'startDate') {
      setStartDate(date);
    } else if (datePickerType === 'endDate') {
      setEndDate(date);
    }
    handleDatePickerClose();
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" color="primary" gutterBottom>
          Account Payable List - For Purchase
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Manage and review your account payables efficiently
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card sx={{ mt: 4 }}>
            <CardContent>
              <Box sx={{ mb: 3 }}>
                <TextField
                  label="Search by Supplier Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleDatePickerOpen('startDate')}
                  >
                    Start Date: {startDate ? format(startDate, 'MM/dd/yyyy') : 'Select date'}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleDatePickerOpen('endDate')}
                  >
                    End Date: {endDate ? format(endDate, 'MM/dd/yyyy') : 'Select date'}
                  </Button>
                </Box>
              </Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Supplier Name</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Total Purchase Price</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Due Date</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Outstanding Balance</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Created At</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Actions</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {accountPayables.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} sx={{ textAlign: 'center' }}>
                          <Typography variant="body1">No data available. Please adjust your search criteria or add new account payables.</Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      accountPayables.map((ap) => (
                        <TableRow key={ap._id} sx={{ '&:nth-of-type(even)': { backgroundColor: '#f9f9f9' } }}>
                          <TableCell>{ap.purchase && ap.purchase.supplier ? ap.purchase.supplier.name : 'N/A'}</TableCell>
                          <TableCell>{formatNumberWithCommas(ap.amount.toFixed(2))} Br.</TableCell>
                          <TableCell>{new Date(ap.dueDate).toLocaleDateString()}</TableCell>
                          <TableCell>{formatNumberWithCommas(ap.remainingAmount.toFixed(2))} Br.</TableCell>
                          <TableCell>{new Date(ap.createdAt).toLocaleDateString()}</TableCell>
                          <TableCell>
                            {ap.remainingAmount === 0 ? (
                              <>
                                <IconButton onClick={() => handleView(ap._id)} color="primary" aria-label="view">
                                  <VisibilityIcon />
                                </IconButton>
                                <IconButton onClick={() => handleConfirmDelete(ap._id)} color="secondary" aria-label="delete" sx={{ color: 'error.main' }}>
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            ) : (
                              <IconButton onClick={() => handleEdit(ap._id)} color="primary" aria-label="edit">
                                <EditIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        )}

        <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to delete this account payable?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={datePickerOpen} onClose={handleDatePickerClose}>
          <DialogTitle>Select Date</DialogTitle>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={datePickerType === 'startDate' ? 'Start Date' : 'End Date'}
                value={datePickerType === 'startDate' ? startDate : endDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDatePickerClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default AccountPayableList;
