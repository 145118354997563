import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, 
    TableRow, Paper, Typography, Box, CircularProgress, 
    Card, CardContent, TablePagination, Checkbox, Button
} from '@mui/material';

const NotificationDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [notification, setNotification] = useState(null);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]); // Visible products
    const [allProducts, setAllProducts] = useState([]); // All products
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [totalProducts, setTotalProducts] = useState(0);
    const [error, setError] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(new Set()); // Using Set for easier management

    useEffect(() => {
        const fetchNotification = async () => {
            try {
                const response = await axiosInstance.get(`/notifications/${id}`);
                setNotification(response.data);
                setAllProducts(response.data.products); // Set all products
                setTotalProducts(response.data.products.length);
                setProducts(response.data.products.slice(page * rowsPerPage, (page + 1) * rowsPerPage));
            } catch (error) {
                const errorMessage = error.response?.status === 404 ? 'Notification not found.' : 'Failed to load notification data.';
                setError(errorMessage);
            } finally {
                setLoading(false);
            }
        };
    
        fetchNotification();
    }, [id, page, rowsPerPage]);

    const extractMessage = (message) => {
        const phrase = "The following products are below reorder level";
        return message?.includes(phrase) ? phrase : message;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setProducts(allProducts.slice(newPage * rowsPerPage, (newPage + 1) * rowsPerPage)); // Use all products for slicing
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset page to 0 when rows per page changes
        setProducts(allProducts.slice(0, newRowsPerPage)); // Use all products for slicing
    };

    // Select or deselect all products
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            allProducts.forEach(product => selectedProducts.add(product.productId));
        } else {
            selectedProducts.clear();
        }
        setSelectedProducts(new Set(selectedProducts)); // Convert Set to state
    };

    // Handle individual checkbox click for product selection
    const handleCheckboxClick = (event, productId) => {
        if (event.target.checked) {
            selectedProducts.add(productId);
        } else {
            selectedProducts.delete(productId);
        }
        setSelectedProducts(new Set(selectedProducts)); // Convert Set to state
    };

    // Submit selected products
    const handleSubmitSelected = () => {
        // Prepare selected products' details
        const selectedProductDetails = allProducts.filter(product => selectedProducts.has(product.productId));
        
        // Navigate to the QuotePage and pass selected products via the route's state
        navigate('/admin/quote/create', { state: { selectedProducts: selectedProductDetails } });
    };

    // Check if a product is selected
    const isSelected = (productId) => selectedProducts.has(productId);

    const formatDate = (timestamp) => {
        return new Intl.DateTimeFormat('en-ET', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(timestamp));
    };

    return (
        <Box p={3}>
            <Card>
            <CardContent>
                <Typography variant="h4" color="primary" gutterBottom>
                    {notification ? notification.title : 'Loading...'}
                </Typography>
                <Typography variant="body1" paragraph>
                    {notification ? extractMessage(notification.message) : 'Loading...'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Date: {notification ? formatDate(notification.timestamp) : 'Loading...'}
                </Typography>
            </CardContent>

            </Card>
            {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : error ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" color="error">{error}</Typography>
        </Box>
      ) : !notification ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography variant="h6">No notification found</Typography>
            </Box>
           ) : products.length > 0 && (
                <Box mt={3}>
                    <Typography variant="h6" color="primary" gutterBottom>
                        Total Products: {totalProducts}
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleSubmitSelected}
                        disabled={selectedProducts.size === 0}
                        sx={{ mb: 3, mt: 2}}
                    >
                        Send To Quote
                    </Button>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={selectedProducts.size > 0 && selectedProducts.size < allProducts.length}
                                            checked={allProducts.length > 0 && selectedProducts.size === allProducts.length}
                                            onChange={handleSelectAllClick}
                                        />
                                    </TableCell>
                                    <TableCell>#</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Stock</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Unit</TableCell>
                                    <TableCell>Reorder Level</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products.map((product, index) => (
                                    <TableRow key={product.productId}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected(product.productId)}
                                                onChange={(event) => handleCheckboxClick(event, product.productId)}
                                            />
                                        </TableCell>
                                        <TableCell>{(page * rowsPerPage) + index + 1}</TableCell>
                                        <TableCell>{product.name}</TableCell>
                                        <TableCell>{product.description}</TableCell>
                                        <TableCell>{product.stock}</TableCell>
                                        <TableCell>{product.category}</TableCell>
                                        <TableCell>{product.unit}</TableCell>
                                        <TableCell>{product.reorderLevel}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100, 200]}
                        component="div"
                        count={totalProducts}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                   
                </Box>
            )}
        </Box>
    );
};

export default NotificationDetail;
