import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
  Snackbar
} from '@mui/material';

const EditAccountPayable = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [accountPayable, setAccountPayable] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [loading, setLoading] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [purchaseDetails, setPurchaseDetails] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // success or error


  useEffect(() => {
    const fetchAccountPayable = async () => {
      try {
        const response = await axiosInstance.get(`/account-payables/${id}`);
        setAccountPayable(response.data);
      } catch (error) {
        showSnackbar('Error fetching account payable details');
      } finally {
        setLoading(false);
      }
    };

    fetchAccountPayable();
  }, [id]);

  const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputChange = (e) => {
    const rawValue = e.target.value.replace(/,/g, '');
    const formattedValue = formatNumberWithCommas(rawValue);
    setPaymentAmount(formattedValue);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  

  const handleSave = async () => {
    const rawAmount = paymentAmount.replace(/,/g, '');
    const amount = parseFloat(rawAmount);
  
    if (isNaN(amount) || amount <= 0) {
      showSnackbar('Payment amount must be greater than zero', 'error');
      return;
    }
  
    if (amount > accountPayable.remainingAmount) {
      showSnackbar('Payment amount exceeds outstanding balance', 'error');
      return;
    }
  
    setLoading(true);
    try {
      await axiosInstance.post(`/account-payables/${id}/payments`, { amount });
      showSnackbar('Payment recorded successfully', 'success');
      const response = await axiosInstance.get(`/account-payables/${id}`);
      setAccountPayable(response.data);
      setPaymentAmount('');
    } catch (error) {
      showSnackbar('Error recording payment: ' + (error.response?.data?.message || 'Unknown error'), 'error');
    } finally {
      setLoading(false);
    }
  };
  

  const validateAndOpenConfirmDialog = () => {
    const amount = paymentAmount.trim();

    if (amount === '') {
      showSnackbar('Please enter a payment amount', 'error');
      return;
    }

    const numericAmount = parseFloat(amount);

    if (numericAmount <= 0) {
      showSnackbar('Payment amount must be greater than zero', 'error');
      return;
    }

    if (numericAmount > accountPayable.remainingAmount) {
      showSnackbar('Payment amount exceeds outstanding balance', 'error');
      return;
    }

    setOpenConfirmDialog(true);
  };

  const handleConfirm = () => {
    setOpenConfirmDialog(false);
    handleSave();
  };

  const handleViewPurchaseDetail = async () => {
    try {
      const response = await axiosInstance.get(`/purchases/${accountPayable.purchase._id}`);
      setPurchaseDetails(response.data);
      setOpenDetailDialog(true);
    } catch (error) {
      showSnackbar('Error fetching purchase details');
    }
  };

  const paidAmount = accountPayable ? accountPayable.amount - accountPayable.remainingAmount : 0;

  // Calculate total purchase price
  const totalPurchasePrice = purchaseDetails?.products.reduce((total, item) => total + item.totalPrice, 0) || 0;

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" color="primary" gutterBottom>
          Account Payable - For Purchase
        </Typography>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card sx={{ p: 4 }}>
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Payables Details
              </Typography>
              <Box sx={{ mb: 3 }}>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1">
                    <Button onClick={handleViewPurchaseDetail} variant="outlined" color="primary">View Purchase Details</Button>
                  </Typography>
                </Box>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Total Purchase Amount:</strong> {accountPayable?.amount != null ? formatNumberWithCommas(accountPayable.amount.toFixed(2)) : '0.00'} Br.</Typography>
                </Box>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Amount Paid:</strong> {formatNumberWithCommas(paidAmount.toFixed(2))} Br.</Typography>
                </Box>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Outstanding Balance:</strong> {accountPayable?.remainingAmount != null ? formatNumberWithCommas(accountPayable.remainingAmount.toFixed(2)) : '0.00'} Br.</Typography>
                </Box>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Due Date:</strong> {new Date(accountPayable?.dueDate).toLocaleDateString()}</Typography>
                </Box>
              </Box>

              {accountPayable?.remainingAmount === 0 ? (
                <Typography variant="h6" color="success.main" gutterBottom>
                  Payment Settled
                </Typography>
              ) : (
                <Box sx={{ mb: 4 }}>
                  <Typography variant="h6" color="primary" gutterBottom>
                    Record Payment
                  </Typography>
                  <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                    <TextField
                      type="text"
                      label="Payment Amount"
                      value={paymentAmount}
                      onChange={handleInputChange}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Box>
                  <Button onClick={validateAndOpenConfirmDialog} variant="contained" color="primary">
                    Record Payment
                  </Button>
                </Box>
              )}

              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Payment History
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><Typography variant="subtitle1" fontWeight="bold">Payment Amount</Typography></TableCell>
                        <TableCell><Typography variant="subtitle1" fontWeight="bold">Payment Date</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {accountPayable?.paymentHistory.map((payment, index) => (
                        <TableRow key={index}>
                          <TableCell>{formatNumberWithCommas(payment.amount.toFixed(2))} Br.</TableCell>
                          <TableCell>{new Date(payment.date).toLocaleDateString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </CardContent>
          </Card>
        )}

        <Dialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
        >
          <DialogTitle>Confirm Payment</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to record this payment of {paymentAmount} Br.?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
  open={openDetailDialog}
  onClose={() => setOpenDetailDialog(false)}
  maxWidth="md"
  fullWidth
>
  <DialogTitle>Purchase Details</DialogTitle>
  <DialogContent>
    <Box sx={{ mb: 2 }}>
      <Typography variant="body1"><strong>Purchase Date:</strong> {new Date(purchaseDetails?.purchaseDate).toLocaleDateString()}</Typography>
    </Box>
    <Box sx={{ mb: 2 }}>
      <Typography variant="body1"><strong>Supplier Name:</strong> {purchaseDetails?.supplier?.name || 'N/A'}</Typography>
    </Box>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Unit Price</TableCell>
            <TableCell>Total Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {purchaseDetails?.products.map((item) => (
            <TableRow
              key={item.product._id}
              style={{ color: item.product.deleted ? 'red' : 'inherit', fontStyle: item.product.deleted ? 'italic' : 'normal' }}
            >
              <TableCell>{item.product.deleted ? 'Product not available' : item.product.name}</TableCell>
              <TableCell>{item.product.deleted ? 'Product not available' : item.product.description}</TableCell>
              <TableCell>{item.product.deleted ? '-' : item.quantity}</TableCell>
              <TableCell>{item.product.deleted ? '-' : formatNumberWithCommas(item.unitPrice.toFixed(2))} Br.</TableCell>
              <TableCell>{item.product.deleted ? '-' : formatNumberWithCommas(item.totalPrice.toFixed(2))} Br.</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={4} align="right"><strong>Total Purchase Price:</strong></TableCell>
            <TableCell>{formatNumberWithCommas(totalPurchasePrice.toFixed(2))} Br.</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenDetailDialog(false)} color="error">
      Close
    </Button>
  </DialogActions>
</Dialog>
<Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

      </Box>
    </Container>
  );
};

export default EditAccountPayable;
