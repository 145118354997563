import { axiosInstance } from '../../Axios/AxiosInstance';

export const getNotifications = async (userId) => {
    try {

        const response = await axiosInstance.get('/notifications', {
            params: { userId } // Pass the user ID as a query parameter
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching notifications:', error);
        throw error;
    }
};



export const deleteNotifications = async (notificationIds) => {
    try {
        const response = await axiosInstance.post('/notifications/delete', { ids: notificationIds });
        return response.data;
    } catch (error) {
        console.error('Error deleting notifications:', error);
        throw error;
    }
};

export const restoreNotification = async (notificationId) => {
    try {
        const response = await axiosInstance.post('/notifications/restore', { id: notificationId });
        return response.data;
    } catch (error) {
        console.error('Error restoring notifications:', error);
        throw error;
    }
    
};
