import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const formatNumberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const SalesSummary = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [endDate, setEndDate] = useState(new Date());
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandProfit, setGrandProfit] = useState(0);

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchSales();
  }, [startDate, endDate]);

  const fetchSales = async () => {
    try {
      const response = await axiosInstance.get('/sales');
      const salesData = response.data;

      const startOfDay = new Date(startDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(endDate);
      endOfDay.setHours(23, 59, 59, 999);

      const filteredSales = salesData.filter(sale => {
        const saleDate = new Date(sale.saleDate);
        return saleDate >= startOfDay && saleDate <= endOfDay && !sale.voided && sale.quantity > 0;
      });

      const groupedSales = filteredSales.reduce((acc, sale) => {
        const { invoiceId, product, quantity, unitPrice, totalPrice, profit, customerName, saleDate, paymentMethod, cashAmount, mobileBankingAmount, createdBy } = sale;

        if (!acc[invoiceId]) {
          acc[invoiceId] = {
            invoiceId,
            customerName,
            saleDate,
            products: [],
            totalQuantity: 0,
            totalUnitPrice: 0,
            totalSalePrice: 0,
            totalProfit: 0,
            paymentMethod,
            cashAmount: 0,
            mobileBankingAmount: 0,
            createdBy
          };
        }

        const productName = product?.name || 'N/A';
        if (!acc[invoiceId].products.includes(productName)) {
          acc[invoiceId].products.push(productName);
        }
        acc[invoiceId].totalQuantity += quantity;
        acc[invoiceId].totalUnitPrice += unitPrice;
        acc[invoiceId].totalSalePrice += totalPrice;
        acc[invoiceId].totalProfit += profit;
        acc[invoiceId].cashAmount += cashAmount;
        acc[invoiceId].mobileBankingAmount += mobileBankingAmount;

        return acc;
      }, {});

      const aggregatedSales = Object.values(groupedSales);

      setSales(aggregatedSales);

      const total = aggregatedSales.reduce((acc, sale) => acc + sale.totalSalePrice, 0);
      const profit = aggregatedSales.reduce((acc, sale) => acc + sale.totalProfit, 0);

      setGrandTotal(total.toFixed(2));
      setGrandProfit(profit.toFixed(2));

      setLoading(false);
    } catch (error) {
      console.error('Error fetching sales:', error);
      setLoading(false);
    }
  };

  // Handle pagination change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4" color="primary" component="h1">Sales Summary</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(date || new Date())}
              renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date || new Date())}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
        </Box>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice ID</TableCell>
                  <TableCell>Products</TableCell>
                  <TableCell>Total Quantity</TableCell>
                  <TableCell>Total Unit Price (Br.)</TableCell>
                  <TableCell>Total Sale Price (Br.)</TableCell>
                  <TableCell>Total Profit (Br.)</TableCell>
                  <TableCell>Payment Method</TableCell>
                  <TableCell>Cash Amount (Br.)</TableCell>
                  <TableCell>Mobile Banking Amount (Br.)</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Sale Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sales
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((sale) => (
                    <TableRow key={sale.invoiceId}>
                      <TableCell>{sale.invoiceId}</TableCell>
                      <TableCell>
                        {sale.products?.length || 0} {/* Display the number of products */}
                      </TableCell>
                      <TableCell>{formatNumberWithCommas(sale.totalQuantity)}</TableCell>
                      <TableCell>{formatNumberWithCommas((sale.totalUnitPrice || 0).toFixed(2))}</TableCell>
                      <TableCell>{formatNumberWithCommas((sale.totalSalePrice || 0).toFixed(2))}</TableCell>
                      <TableCell>{formatNumberWithCommas((sale.totalProfit || 0).toFixed(2))}</TableCell>
                      <TableCell>{sale.paymentMethod || 'N/A'}</TableCell>
                      <TableCell>{formatNumberWithCommas((sale.cashAmount || 0).toFixed(2))}</TableCell>
                      <TableCell>{formatNumberWithCommas((sale.mobileBankingAmount || 0).toFixed(2))}</TableCell>
                      <TableCell>{sale.customerName || 'N/A'}</TableCell>
                      <TableCell>{sale.createdBy || 'N/A'}</TableCell>
                      <TableCell>{sale.saleDate ? new Date(sale.saleDate).toLocaleDateString() : 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell colSpan={6} align="right"><strong>Grand Total:</strong></TableCell>
                  <TableCell colSpan={5}><strong>{formatNumberWithCommas(grandTotal)} Br.</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={6} align="right"><strong>Grand Profit:</strong></TableCell>
                  <TableCell colSpan={5}><strong>{formatNumberWithCommas(grandProfit)} Br.</strong></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={sales.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      )}
    </Box>
  );
};

export default SalesSummary;
