import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';

const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };


const SaleCard = ({ sale }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const displayedProducts = expanded ? sale.products : sale.products.slice(0, 3); // Show only first 3 products if not expanded

  return (
    <Card variant="outlined" sx={{ borderRadius: 3, boxShadow: 3 }}>
      <CardContent sx={{ padding: 3 }}>
        <Box sx={{ borderBottom: '2px solid #ddd', paddingBottom: 2, marginBottom: 3 }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#333' }}>
            Invoice ID: {sale.invoiceId}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Date: {new Date().toLocaleDateString()}
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="body1" fontWeight="bold" color="primary">Products:</Typography>
          {displayedProducts.map((product, index) => (
            <Box key={index} mb={2} sx={{ borderBottom: '1px dashed #eee', paddingBottom: 1 }}>
              <Typography variant="subtitle2" sx={{ color: '#555' }}>Product: {product.product}</Typography>
              <Typography variant="body2">Quantity: {product.quantity}</Typography>
              <Typography variant="body2">Unit Price: {formatNumberWithCommas(product.unitPrice)} Br.</Typography>
              <Typography variant="body2">Total Price: {formatNumberWithCommas(product.totalPrice)} Br.</Typography>
            </Box>
          ))}

          {sale.products.length > 3 && (
            <Button size="small" onClick={toggleExpanded}>
              {expanded ? 'Show less' : `Show more (${sale.products.length - 3} more products)`}
            </Button>
          )}
        </Box>

        <Box mb={3}>
          <Typography variant="body1" fontWeight="bold" color="primary">Total:</Typography>
          <Typography variant="body2">Total Quantity: {sale.totalQuantity}</Typography>
          <Typography variant="body2">Total Amount: {formatNumberWithCommas(sale.totalAmount)} Br.</Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="body1" fontWeight="bold" color="primary">Payment Details:</Typography>
          <Typography variant="body2">Payment Method: {sale.paymentMethod}</Typography>
          {sale.paymentMethod === 'split_payment' && (
            <Box mt={1}>
              <Typography variant="body2">Cash Amount: {formatNumberWithCommas(sale.cashAmount)} Br.</Typography>
              <Typography variant="body2">Mobile Banking Amount: {formatNumberWithCommas(sale.mobileBankingAmount)} Br.</Typography>
            </Box>
          )}
          {sale.paymentMethod !== 'split_payment' && (
            <Typography variant="body2">Payment Status: {sale.paymentStatus}</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default SaleCard;
