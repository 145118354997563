import React, { useState, useEffect } from 'react';
import {
    Card,
    Typography,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Box,
    TablePagination,
    CircularProgress,
    Button,
    Toolbar,
} from '@mui/material';
import { formatTimeAgo } from '../../utility/timeUtils';
import { getNotifications, deleteNotifications } from '../hooks/notificationService';
import { Circle, CheckCircle, Delete, DeleteForever } from '@mui/icons-material';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { useNavigate, useLocation } from 'react-router-dom';

const NotificationsPage = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selected, setSelected] = useState([]);
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [hoveredRow, setHoveredRow] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');

    useEffect(() => {
        let isMounted = true;
    
        const fetchNotifications = async () => {
            if (!userId) return;
    
            setLoading(true);
            setError(null);
            try {
                const data = await getNotifications(userId);
                if (isMounted) setNotifications(data.notifications || []);
                setUnreadCount(data.count);
            } catch (error) {
                console.error('Error fetching notifications:', error);
                if (isMounted) setError('Failed to load notifications. Please try again later.');
            } finally {
                if (isMounted) setLoading(false);
            }
        };
    
        fetchNotifications();
    
        return () => {
            isMounted = false;
        };
    }, [userId, location.search]);

    const extractMessage = (message) => {
        const phrase = "The following products are below reorder level";
        if (message && message.includes(phrase)) {
            return phrase;
        }
        return message;
    };

    const handleRowClick = async (notificationId) => {
        try {
            await axiosInstance.put(`/notifications/${notificationId}/read`);
            setUnreadMessages((prevMessages) =>
                prevMessages.map((notification) =>
                    notification._id === notificationId
                        ? { ...notification, read: true }
                        : notification
                )
            );
            setUnreadCount((prevCount) => Math.max(prevCount - 1, 0));
            navigate(`/admin/notifications/detail/${notificationId}`);
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = notifications.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, notificationId) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(notificationId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, notificationId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const isSelected = (notificationId) => selected.indexOf(notificationId) !== -1;

    const handleDelete = async (notificationId) => {
        try {
            await deleteNotifications([notificationId]);
            setNotifications(notifications.filter((n) => n._id !== notificationId));
        } catch (error) {
            console.error('Error deleting notification:', error);
            setError('Failed to delete notification. Please try again.');
        }
    };

    const handleDeleteSelected = async () => {
        try {
            await deleteNotifications(selected);
            setNotifications(notifications.filter((n) => !selected.includes(n._id)));
            setSelected([]);
        } catch (error) {
            console.error('Error deleting notifications:', error);
            setError('Failed to delete notifications. Please try again.');
        }
    };

    const handleMarkAllAsRead = async () => {
        try {
            await axiosInstance.put('/notifications/mark-all-read', { userId }); // Call the new endpoint
            setNotifications((prev) =>
                prev.map((notification) => ({ ...notification, read: true }))
            );
            setUnreadCount(0); // Reset unread count
        } catch (error) {
            console.error('Error marking all notifications as read:', error);
            setError('Failed to mark notifications as read.');
        }
    };

    return (
        <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: '5px' }}>
            <Typography variant="h4" gutterBottom color="primary" sx={{ textAlign: 'center', fontWeight: 'bold', color: '#367588' }}>
                Notifications
            </Typography>

            <Toolbar
    sx={{
        display: 'flex',
        justifyContent: selected.length > 0 ? 'space-between' : 'flex-end',
    }}
>
    {selected.length > 1 && (
        <Button
            variant="contained"
            color="secondary"
            startIcon={<Delete />}
            onClick={handleDeleteSelected}
        >
            Delete {selected.length} {selected.length > 1 ? 'Notifications' : 'Notification'}
        </Button>
    )}
    {selected.length > 0 && unreadCount > 0 && ( // Updated condition here
        <Button
            variant="contained"
            color="primary"
            onClick={handleMarkAllAsRead} // Button for marking all as read
        >
            Mark All as Read
        </Button>
    )}
</Toolbar>

{loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : error ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" color="error">{error}</Typography>
        </Box>
      ) : (
                <Card>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={selected.length > 0 && selected.length < notifications.length}
                                            checked={notifications.length > 0 && selected.length === notifications.length}
                                            onChange={handleSelectAllClick}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ color: '#367588'}}>Notification</TableCell>
                                    <TableCell align="right" sx={{ color: '#367588'}}>Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {notifications.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                                            <Typography variant="body1" color="textSecondary">
                                                No notifications available.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    notifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((notification) => {
                                        const isItemSelected = isSelected(notification._id);
                                        return (
                                            <TableRow
                                                key={notification._id}
                                                hover
                                                onClick={() => handleRowClick(notification._id)}
                                                selected={isItemSelected}
                                                onMouseEnter={() => setHoveredRow(notification._id)}
                                                onMouseLeave={() => setHoveredRow(null)}
                                                sx={{
                                                    cursor: 'pointer',
                                                    backgroundColor: notification.read ? '#fff' : '#e3f2fd',
                                                    '&:hover': { backgroundColor: '#f1f1f1' },
                                                }}
                                            >
                                                <TableCell padding="checkbox" onClick={(event) => event.stopPropagation()}>
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        onChange={(event) => handleClick(event, notification._id)}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography variant="body1" sx={{ fontWeight: notification.read ? 'normal' : 'bold' }}>
                                                        {notification.title}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {extractMessage(notification.message)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography variant="body2" color="textSecondary">
                                                        {formatTimeAgo(notification.timestamp)}
                                                    </Typography>
                                                    {notification.read ? (
                                                        <CheckCircle sx={{ color: '#367588', fontSize: 20 }} />
                                                    ) : (
                                                        <Circle sx={{ color: '#00cc00', fontSize: 20 }} />
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {hoveredRow === notification._id && (
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                handleDelete(notification._id);
                                                            }}
                                                            color="error"
                                                        >
                                                            <DeleteForever />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={notifications.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        sx={{ marginTop: 2 }}
                    />
                </Card>
            )}
        </Box>
    );
};

export default NotificationsPage;
