import React from 'react';
import { Breadcrumbs, Typography, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import InvoiceDetail from './InvoiceDetail';

const InvoicePage = () => {
  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4, p: 1  }}>
        <Breadcrumbs aria-label="breadcrumb">
          <RouterLink to="/admin/sales" style={{ textDecoration: 'none', color: 'inherit' }}>
            Sale List
          </RouterLink>
          <Typography color="textPrimary">Invoice Detail</Typography>
        </Breadcrumbs>
      </Box>
      <InvoiceDetail />
    </div>
  );
};

export default InvoicePage;
