import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow,
  Paper, Grid, Pagination, List, ListItem, ListItemAvatar, Avatar, ListItemText, Card, CardContent, Button, Badge,
  Tabs, Tab
} from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { axiosInstance, axiosAuthInstance } from '../../Axios/AxiosInstance';
import ExcelJS from 'exceljs';

const ShopDetail = () => {
  const { shopId } = useParams();
  const navigate = useNavigate();
  const [shopDetails, setShopDetails] = useState(null);
  const [products, setProducts] = useState([]);
  const [staff, setStaff] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [loadingShopDetails, setLoadingShopDetails] = useState(true);
  const [loadingStaff, setLoadingStaff] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [activeTab, setActiveTab] = useState(0);  // State for active tab

  useEffect(() => {
    const fetchShopDetails = async () => {
      try {
        const shopResponse = await axiosInstance.get(`/shops/${shopId}`);
        setShopDetails(shopResponse.data);
        setLoadingShopDetails(false);
      } catch (err) {
        setError(err.message);
        setLoadingShopDetails(false);
      }
    };

    const fetchProductsAndStaff = async () => {
      try {
        const productsResponse = await axiosInstance.get(`/distributions/shop/${shopId}/products`, {
          params: { page, limit },
        });

        const productsData = productsResponse.data.products;

        const mergedProducts = productsData.reduce((acc, product) => {
          const existingProduct = acc.find(p => p.name === product.name);
          if (existingProduct) {
            existingProduct.availableQuantity += product.availableQuantity;
          } else {
            acc.push({ ...product });
          }
          return acc;
        }, []);

        setProducts(mergedProducts);

        const updatedStaff = await Promise.all(productsResponse.data.staff.map(async (user) => {
          try {
            const profilePictureResponse = await axiosAuthInstance.get(`/auth/user/${user._id}/profile-picture`);
            const onlineStatusResponse = await axiosAuthInstance.get(`/auth/user/${user._id}/online-status`);
            return { ...user, profilePicture: profilePictureResponse.data, isOnline: onlineStatusResponse.data.isOnline };
          } catch (err) {
            console.error('Error fetching profile picture or online status:', err);
            return user;
          }
        }));

        setStaff(updatedStaff);
        setLoadingStaff(false);
        setLoadingProducts(false);
      } catch (err) {
        setError(err.message);
        setLoadingStaff(false);
        setLoadingProducts(false);
      }
    };

    fetchShopDetails();
    fetchProductsAndStaff();
  }, [shopId, page, limit]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleViewSales = () => {
    navigate(`/admin/shop/${shopId}/sales`);
  };

  const handleViewReturns = () => {
    navigate(`/admin/shop/${shopId}/returns`);
  };

  const handleExportToExcel = () => {
    if (!shopDetails) {
      console.error('Shop details not available');
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Products');
    worksheet.mergeCells('A1:C1');
    const titleRow = worksheet.getCell('A1');
    titleRow.value = `Products Available in ${shopDetails.name} on ${new Date().toLocaleDateString()}`;
    titleRow.font = { size: 18, bold: true };
    titleRow.alignment = { horizontal: 'center' };

    worksheet.mergeCells('A2:C2');
    const locationRow = worksheet.getCell('A2');
    locationRow.value = `Location: ${shopDetails.location}`;
    locationRow.font = { italic: true, size: 12 };
    locationRow.alignment = { horizontal: 'center' };

    worksheet.addRow([]);
    const headers = ['#', 'Name', 'Description', 'Quantity'];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true, size: 14, color: { argb: 'FFFFFFFF' } };
      cell.alignment = { horizontal: 'center' };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '367588' },
      };
    });

    let sequentialNumber = 1;
    products.forEach((product) => {
      worksheet.addRow([
        sequentialNumber++,
        product.name,
        product.description,
        product.availableQuantity
      ]);
    });

    worksheet.columns = [
      { width: 10 },
      { width: 30 },
      { width: 50 },
      { width: 20 },
    ];

    const formattedDate = new Date().toISOString().split('T')[0];
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${shopDetails.name}_ProductList_${formattedDate}.xlsx`;
      link.click();
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="primary" gutterBottom>Shop Details</Typography>

      <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
                <Tab 
                  label="Details" 
                  sx={{ fontWeight: activeTab === 0 ? 'bold' : 'normal' }} 
                />
                <Tab 
                  label="Products" 
                  sx={{ fontWeight: activeTab === 1 ? 'bold' : 'normal' }} 
                />
                <Tab 
                  label="Staffs" 
                  sx={{ fontWeight: activeTab === 2 ? 'bold' : 'normal' }} 
                />
              </Tabs>

      {activeTab === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ borderRadius: 2, mb: 4 }}>
              <CardContent>
                <Typography variant="h6" color="primary" gutterBottom>Name:</Typography>
                <Typography variant="body1">{shopDetails ? shopDetails.name : 'No Name Available'}</Typography>

                <Typography variant="h6" color="primary" gutterBottom>Location:</Typography>
                <Typography variant="body1">{shopDetails ? shopDetails.location : 'No Location Available'}</Typography>

                <Button variant="contained" color="primary" onClick={handleViewSales} sx={{ mt: 2 }}>
                  View All Sales
                </Button>
                <Button variant="contained" color="secondary" onClick={handleViewReturns} sx={{ mt: 2, ml: 2 }}>
                  View Returns
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {activeTab === 1 && (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 3 }}>
        <CardContent sx={{ padding: 4 }}>
          
          {loadingProducts ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '300px',
                p: 2,
              }}
            >
              <CircularProgress size={60} sx={{ mb: 2 }} />
              <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                Loading...
              </Typography>
            </Box>
          ) : error ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '300px',
                p: 2,
              }}
            >
              <Typography variant="h6" color="error">
                {error}
              </Typography>
            </Box>
          ) : products.length > 0 ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleExportToExcel}
                sx={{ mb: 2, ml: 117, padding: '8px 16px' }}
              >
                Export to Excel
              </Button>
              <Table sx={{ mt: 2, borderCollapse: 'separate', borderSpacing: '0 8px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: 16 }}>Product Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: 16 }}>Description</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: 16 }}>Available Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product) => (
                    <TableRow
                      key={product._id}
                      sx={{
                        backgroundColor: '#f9f9f9',
                        '&:hover': { backgroundColor: '#f1f1f1' },
                        transition: 'background-color 0.3s',
                      }}
                    >
                      <TableCell sx={{ padding: '12px' }}>{product.name}</TableCell>
                      <TableCell sx={{ padding: '12px' }}>{product.description}</TableCell>
                      <TableCell sx={{ padding: '12px' }}>{product.availableQuantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Pagination
                  count={Math.ceil(products.length / limit)}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  size="large"
                  sx={{ button: { fontSize: 16 } }}
                />
              </Box>
            </>
          ) : (
            <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
              No product is currently available at this shop.
            </Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
  </Grid>
)}


{activeTab === 2 && (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Card variant="outlined" sx={{ borderRadius: 2, boxShadow: 3 }}>
        <CardContent sx={{ padding: 4 }}>
          <Typography variant="h5" color="primary" gutterBottom sx={{ mb: 3 }}>
            Staff Members
          </Typography>

          {loadingStaff ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '200px',
              }}
            >
              <CircularProgress size={40} />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Loading...
              </Typography>
            </Box>
          ) : error ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '200px',
              }}
            >
              <Typography variant="h6" color="error">
                {error}
              </Typography>
            </Box>
          ) : staff.length > 0 ? (
            <List>
              {staff.map((user) => (
                <ListItem
                  key={user._id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '16px',
                    borderRadius: 2,
                    mb: 2,
                    boxShadow: 1,
                    backgroundColor: '#f9f9f9',
                  }}
                >
                  <Box sx={{ position: 'relative', mr: 3 }}>
                    <Avatar
                      alt={user.username}
                      src={
                        user.profilePicture
                          ? `data:image/jpeg;base64,${user.profilePicture}`
                          : '/default-avatar.png'
                      }
                      sx={{ width: 60, height: 60 }}
                    >
                      {!user.profilePicture && user.username.charAt(0).toUpperCase()}
                    </Avatar>
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 5,
                        width: 14,
                        height: 14,
                        borderRadius: '50%',
                        padding: '2px',
                        backgroundColor: user.isOnline ? '#39e600' : '#f44336',
                        border: '3px solid white',
                      }}
                    />
                  </Box>

                  <ListItemText
                    primary={
                      <Typography variant="h6" >
                        {user.username}
                      </Typography>
                    }
                    secondary={user.email}
                    sx={{ flexGrow: 1 }}
                  />

                  <Typography
                    variant="body2"
                    sx={{ color: user.isOnline ? 'green' : 'red', fontWeight: 'bold' }}
                  >
                    {user.isOnline ? 'Online' : 'Offline'}
                  </Typography>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="h6" sx={{ textAlign: 'center', color: 'text.secondary' }}>
              No staff is associated with this shop.
            </Typography>
          )}
        </CardContent>
      </Card>
    </Grid>
  </Grid>
)}

    </Box>
  );
};

export default ShopDetail;
