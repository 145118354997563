import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Pagination,
} from '@mui/material';
import { Container } from '@mui/system';

const DistributionDetail = () => {
  const { batchId } = useParams();
  const [distributions, setDistributions] = useState([]);
  const [shopName, setShopName] = useState('');
  const [distributionDate, setDistributionDate] = useState('');
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDistributions = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('/distributions/filtered', {
          params: {
            batchId,
            page,
            limit,
          },
        });

        const distributionsData = Array.isArray(response.data) ? response.data : [];
        setDistributions(distributionsData);
        setTotal(distributionsData.length || 0);

        // Assuming shopName and date are common across all entries in the batch
        if (distributionsData.length > 0) {
          const { shop, date } = distributionsData[0];
          
          // Extract shop ID correctly
          const shopId = typeof shop === 'object' ? shop._id : shop;

          if (shopId) {
            // Fetch the shop name using the shop ID
            const shopResponse = await axiosInstance.get(`/shops/${shopId}`);
            setShopName(shopResponse.data.name || 'Unknown Shop');
          } else {
            setShopName('Unknown Shop');
          }

          // Format and set the distribution date
          setDistributionDate(date ? new Date(date).toLocaleDateString() : 'Unknown Date');
        }
      } catch (error) {
        console.error("Error fetching distributions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDistributions();
  }, [batchId, page, limit]);


  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Container>
      <Box sx={{ p: 4 }}>
        <Typography variant="h5" color="primary" gutterBottom>
          Distribution Detail For {`Shop: ${shopName}`} 
        </Typography>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6">{`Date: ${distributionDate}`}</Typography>
        </Box>

        {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : (
          <>
            <TableContainer sx={{ mb: 4, border: '1px solid #ddd', borderRadius: '8px', overflow: 'hidden' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Amount Distributed</TableCell>
                    <TableCell>Currently Available</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(distributions) && distributions.length > 0 ? (
                    distributions.map((distribution, index) => (
                      <TableRow key={index}>
                        <TableCell>{distribution.product?.name || 'N/A'}</TableCell>
                        <TableCell>{distribution.product?.description || 'N/A'}</TableCell>
                        <TableCell>{distribution.amountDistributed || 0}</TableCell>
                        <TableCell>{distribution.amountLeft || 0}</TableCell>
                        <TableCell>{distribution.date ? new Date(distribution.date).toLocaleDateString() : 'N/A'}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Pagination
                count={Math.ceil(total / limit)}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </Box>
          </>
        )}
      </Box>
    </Container>
  );
};

export default DistributionDetail;
