// useFormValidation.js
import { useState } from 'react';

export const useFormValidation = (products) => {
  const [errors, setErrors] = useState({});

  const validateForm = (formData, customers, showSnackbar) => {
    const transactionIdPattern = /^FT[a-zA-Z0-9]{10}$/;

    // Initialize an empty errors object
    let validationErrors = {};

    // Validate items
    if (
      formData.items.length === 0 ||
      formData.items.some((item) => !item.product || !item.quantity || !item.unitPrice)
    ) {
      validationErrors.items = 'Please add at least one item with product, quantity, and unit price.';
    }

    // Validate unit prices
    for (const [index, item] of formData.items.entries()) {
      const product = products.find((p) => p._id === item.product);
      if (product) {
        const unitPrice = parseFloat(item.unitPrice);
        if (unitPrice < product.minPrice) {
          validationErrors[`unitPrice_${index}`] = `Unit price for "${product.name}" must be at least ${product.minPrice.toFixed(2)} Br.`;
        }
      }
    }

    // Validate customer
    if (!formData.customer) {
      validationErrors.customer = 'Please select a customer.';
    }

    // Validate payment method
    if (!formData.paymentMethod) {
      validationErrors.paymentMethod = 'Please select a payment method.';
    }

    // Validate transaction ID
    if (
      formData.paymentMethod === 'mobile_banking' &&
      !transactionIdPattern.test(formData.transactionId)
    ) {
      validationErrors.transactionId =
        'Transaction ID must start with "FT" followed by exactly 10 alphanumeric characters.';
    }

    // Validate split payment
    if (formData.paymentMethod === 'split_payment') {
      const cashAmount = parseFloat(formData.cashAmount) || 0;
      const mobileBankingAmount = parseFloat(formData.mobileBankingAmount) || 0;
      const totalAmount = cashAmount + mobileBankingAmount;

      if (!formData.cashAmount || !formData.mobileBankingAmount) {
        validationErrors.splitPayment =
          'Please provide both the cash amount and the mobile banking amount for split payment.';
      }

      if (totalAmount !== formData.totalPrice) {
        validationErrors.splitPaymentAmount = `The total payment amount (${totalAmount.toFixed(
          2
        )}) does not match the total price (${formData.totalPrice.toFixed(2)}).`;
      }
    }

    // Validate partial credit
    if (
      formData.paymentMethod === 'credit' &&
      formData.creditPaymentType === 'partial_credit'
    ) {
      const partialCreditAmount = parseFloat(formData.partialCreditAmount) || 0;
      const upfrontPayment = formData.totalPrice - partialCreditAmount;

      if (!formData.partialCreditAmount) {
        validationErrors.partialCreditAmount = 'Please enter the amount for partial credit.';
      }

      if (upfrontPayment < 0) {
        validationErrors.partialCreditAmount =
          'The partial credit amount exceeds the total price.';
      }
    }

    // Update errors state
    setErrors(validationErrors);

    // Show the first error via snackbar
    if (Object.keys(validationErrors).length > 0) {
      const firstErrorKey = Object.keys(validationErrors)[0];
      showSnackbar(validationErrors[firstErrorKey], 'error');
      return false;
    }

    return true;
  };

  return { errors, validateForm };
};
