import { useState, useEffect, useCallback } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';

const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [newCategory, setNewCategory] = useState({ name: '', description: '' });
  const [formErrors, setFormErrors] = useState({ name: '', description: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmDeleteId, setConfirmDeleteId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null); // Store the category to delete

  const fetchCategories = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/categories');
      setCategories(response.data);
      setError(null);
    } catch (err) {
      setError('Error fetching categories');
      console.error('Error fetching categories:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleNewCategoryChange = (e) => {
    const { name, value } = e.target;
    setNewCategory({ ...newCategory, [name]: value });
  };

  const handleAddCategory = async () => {
    const { name, description } = newCategory;
    if (!name) {
      setFormErrors({ ...formErrors, name: 'Category name is required' });
      return;
    }
    if (!description) {
      setFormErrors({ ...formErrors, description: 'Description is required' });
      return;
    }

    try {
      const response = await axiosInstance.post('/categories', newCategory);
      setCategories((prevCategories) => [...prevCategories, response.data]);
      setNewCategory({ name: '', description: '' });
      setFormErrors({ name: '', description: '' });
    } catch (err) {
      setError('Error adding category.');
      console.error('Error adding category:', err);
    }
  };

  const handleEditClick = (id) => {
    setEditingId(id);
  };

  const handleSaveClick = async (id) => {
    const updatedCategory = categories.find(cat => cat._id === id);
    try {
      await axiosInstance.put(`/categories/${id}`, updatedCategory);
      setEditingId(null);
    } catch (err) {
      setError('Error updating category.');
      console.error('Error updating category:', err);
    }
  };

  const handleCancelClick = () => {
    setEditingId(null);
  };

  const handleInputChange = (e, id) => {
    const { name, value } = e.target;
    setCategories(categories.map(cat =>
      cat._id === id ? { ...cat, [name]: value } : cat
    ));
  };

  const handleDeleteClick = (id) => {
    const category = categories.find(cat => cat._id === id);
    setCategoryToDelete(category); // Set the category to be deleted
    setConfirmDeleteId(id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`/categories/${confirmDeleteId}`);
      setCategories(categories.filter(cat => cat._id !== confirmDeleteId));
      setCategoryToDelete(null);
      setConfirmDeleteId(null);
      setOpenConfirmDialog(false);
    } catch (err) {
      setError('Error deleting category.');
      console.error('Error deleting category:', err);
    }
  };

  const handleCancelDelete = () => {
    setCategoryToDelete(null);
    setConfirmDeleteId(null);
    setOpenConfirmDialog(false);
  };

  return {
    categories,
    editingId,
    newCategory,
    formErrors,
    loading,
    error,
    openConfirmDialog,
    categoryToDelete,
    handleNewCategoryChange,
    handleAddCategory,
    handleEditClick,
    handleSaveClick,
    handleCancelClick,
    handleInputChange,
    handleDeleteClick,
    handleConfirmDelete,
    handleCancelDelete,
  };
};

export default useCategories;
