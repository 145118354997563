import React from 'react';

const TextContent = () => {
  return (
    <section
      style={{
        maxWidth: '600px',
        padding: '30px 20px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h2
        style={{
          fontSize: '2.5em',
          fontWeight: '700',
          marginBottom: '20px',
          color: '#f3f4f7',
          textAlign: 'center',
        }}
      >
        Advanced Inventory & Stock Management Solution
      </h2>
      <p
        style={{
          fontSize: '1.2em',
          lineHeight: '1.6em',
          marginBottom: '30px',
          color: '#d1d9e3',
          textAlign: 'center',
        }}
      >
        Optimize your inventory processes with{' '}
        <span
          style={{
            color: '#3b9ca8',
          }}
        >
          AddisLogix
        </span>
        . Our platform offers a comprehensive solution tailored to enhance
        efficiency, reduce costs, and empower your team with the tools needed
        for effective decision-making.
      </p>
      <ul
        style={{
          fontSize: '1.1em',
          lineHeight: '1.7em',
          color: '#d1d9e3',
          listStyle: 'none',
          padding: '0',
          textAlign: 'left',
          width: '100%',
        }}
      >
        {[
          'Real-time Inventory Tracking',
          'Automated Reordering Notifications',
          'Detailed Analytics and Reporting',
          'Multi-Location Inventory Management',
        ].map((item, index) => (
          <li
            key={index}
            style={{
              marginBottom: '15px',
              position: 'relative',
              paddingLeft: '30px',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              transition: 'transform 0.2s',
            }}
          >
            <span
              style={{
                width: '12px',
                height: '12px',
                border: '2px solid #3b9ca8', // Outer circle border color
                borderRadius: '50%',
                backgroundColor: '#f3f4f7', // Inner circle color (white or any light color)
                marginRight: '10px',
                display: 'inline-block',
              }}
            ></span>
            {item}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default TextContent;
