import { useState, useCallback, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';

const useShops = () => {
  const [shops, setShops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchShops = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/shops');
      setShops(Array.isArray(response.data) ? response.data : []);
      setError(null);
    } catch (err) {
      setError('There was an error fetching the shops!');
    } finally {
      setLoading(false);
    }
  }, []);

  const createShop = async (name, location) => {
    try {
      const response = await axiosInstance.post('/shops', { name, location });
      setShops(prevShops => [...prevShops, response.data]);
    } catch (err) {
      throw new Error('Error creating shop');
    }
  };

  const updateShop = async (id, name, location) => {
    try {
      const response = await axiosInstance.put(`/shops/${id}`, { name, location });
      setShops(prevShops => prevShops.map(shop => (shop._id === id ? response.data : shop)));
    } catch (err) {
      throw new Error('Error updating shop');
    }
  };

  const deleteShop = async (id) => {
    try {
      await axiosInstance.delete(`/shops/${id}`);
      setShops(prevShops => prevShops.filter(shop => shop._id !== id));
    } catch (err) {
      throw new Error('Error deleting shop');
    }
  };

  const getShopDetails = async (id) => {
    try {
      const response = await axiosInstance.get(`/shops/${id}`);
      return response.data;
    } catch (err) {
      throw new Error('Error fetching shop details');
    }
  };

  useEffect(() => {
    fetchShops();
  }, [fetchShops]);

  return { shops, loading, error, createShop, updateShop, deleteShop, getShopDetails };
};

export default useShops;
