import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Typography, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow,
  Card, CardContent
} from '@mui/material';
import { axiosInstance, axiosAuthInstance } from '../../Axios/AxiosInstance';

const ShopReturnDetails = () => {
  const { invoiceId } = useParams();  // Fetch invoiceId from URL parameters
  const [returnDetails, setReturnDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReturnDetails = async () => {
      try {
        if (!invoiceId) {
          setError('Invoice ID is required');
          setLoading(false);
          return;
        }
    
        console.log(`Fetching return details for invoiceId: ${invoiceId}`);
        const response = await axiosInstance.get('/sales/return-details', {
          params: { invoiceId }
        });
    
        if (response.data) {
          const returnData = response.data;
    
          const usernames = await Promise.all(returnData.map(async (returnItem) => {
            try {
              const userResponse = await axiosAuthInstance.get(`/auth/username/${returnItem.userId}`);
              return {
                ...returnItem,
                username: userResponse.data.username
              };
            } catch (userError) {
              console.warn(`User not found for ID: ${returnItem.userId}. Error: ${userError.message}`);
              return {
                ...returnItem,
                username: 'Unknown'  // Fallback username
              };
            }
          }));
    
          // Group and aggregate return details
          const groupedReturns = usernames.reduce((acc, returnItem) => {
            const key = `${returnItem.productName}-${returnItem.returnReason}`;
            if (!acc[key]) {
              acc[key] = {
                productName: returnItem.productName,
                quantity: 0,
                customerName: returnItem.customerName,
                returnReason: returnItem.returnReason,
                profit: 0,
                returnDate: returnItem.returnDate,
                username: returnItem.username,
              };
            }
            acc[key].quantity += returnItem.quantity;
            acc[key].profit += returnItem.profit || 0;
            return acc;
          }, {});
    
          setReturnDetails(Object.values(groupedReturns));
        } else {
          setError('No data found for this invoice ID');
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    

    fetchReturnDetails();
  }, [invoiceId]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
      <CircularProgress size={40} />
      <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
    </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" color="error">{error}</Typography>
        </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
      <span style={{ color: '#367588' }} >Return Details for Invoice</span> {invoiceId}
      </Typography>

      <Card variant="outlined" sx={{ borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Return Details
          </Typography>
          {returnDetails.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Return Reason</TableCell>
                  <TableCell>Returned Profit</TableCell>
                  <TableCell>Return Date</TableCell>
                  <TableCell>Returned By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {returnDetails.map((returnItem, index) => (
                  <TableRow key={index}>
                    <TableCell>{returnItem.productName}</TableCell>
                    <TableCell>{returnItem.quantity}</TableCell>
                    <TableCell>{returnItem.customerName}</TableCell>
                    <TableCell>{returnItem.returnReason}</TableCell>
                    <TableCell>{returnItem.profit.toFixed(2)}</TableCell>
                    <TableCell>{new Date(returnItem.returnDate).toLocaleDateString()}</TableCell>
                    <TableCell>{returnItem.username}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography>No return details available for this invoice.</Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ShopReturnDetails;
