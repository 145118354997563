import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Box, Typography, FormHelperText } from '@mui/material';

const PaymentComponent = ({ formData, handleChange, totalPrice }) => {
  const [error, setError] = useState('');

  useEffect(() => {
    if (formData.paymentMethod === 'split_payment') {
      const cashAmount = parseFloat(formData.cashAmount) || 0;
      const mobileBankingAmount = parseFloat(formData.mobileBankingAmount) || 0;
      const totalAmount = cashAmount + mobileBankingAmount;

      if (totalAmount > totalPrice) {
        setError('The total payment amount exceeds the total price.');
      } else if (totalAmount < totalPrice) {
        setError('The total payment amount is less than the total price.');
      } else {
        setError('');
      }
    } else if (formData.paymentMethod === 'credit' && formData.creditPaymentType === 'partial_credit') {
      const partialCreditAmount = parseFloat(formData.partialCreditAmount) || 0;
      const upfrontPayment = totalPrice - partialCreditAmount;

      if (upfrontPayment < 0) {
        setError('The partial credit amount exceeds the total price.');
      } else {
        setError('');
      }
    } else {
      setError('');
    }
  }, [
    formData.cashAmount, 
    formData.mobileBankingAmount, 
    formData.paymentMethod, 
    formData.partialCreditAmount, 
    formData.creditPaymentType, 
    totalPrice
  ]);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Payment Method
      </Typography>

      {/* Payment Method Selector */}
      <TextField
        select
        label="Select Payment Method"
        value={formData.paymentMethod}
        onChange={(e) => handleChange('paymentMethod', e.target.value)}
        fullWidth
        margin="normal"
      >
        <MenuItem value="">Select Payment Method</MenuItem>
        <MenuItem value="cash">Cash</MenuItem>
        <MenuItem value="mobile_banking">Mobile Banking</MenuItem>
        <MenuItem value="split_payment">Split Payment</MenuItem>
        <MenuItem value="credit">Credit</MenuItem>
      </TextField>

      {/* Split Payment Fields */}
      {formData.paymentMethod === 'split_payment' && (
        <>
          <TextField
            label="Cash Amount"
            type="number"
            value={formData.cashAmount}
            onChange={(e) => handleChange('cashAmount', e.target.value)}
            placeholder="Enter cash amount"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Mobile Banking Amount"
            type="number"
            value={formData.mobileBankingAmount}
            onChange={(e) => handleChange('mobileBankingAmount', e.target.value)}
            placeholder="Enter mobile banking amount"
            fullWidth
            margin="normal"
          />

          {error && <FormHelperText error>{error}</FormHelperText>}
        </>
      )}

      {/* Mobile Banking Fields */}
      {formData.paymentMethod === 'mobile_banking' && (
        <TextField
          label="Transaction ID or Txn ID"
          value={formData.transactionId}
          onChange={(e) => {
            const uppercasedValue = e.target.value.toUpperCase();
            if (uppercasedValue.length <= 12) {
              handleChange('transactionId', uppercasedValue);
            }
          }}
          placeholder="Enter Transaction ID (e.g., FTXXXXXXXXXX)"
          fullWidth
          margin="normal"
        />
      )}

      {/* Payment Status (For methods other than split and credit) */}
      {formData.paymentMethod !== 'split_payment' && formData.paymentMethod !== 'credit' && (
        <TextField
          select
          label="Payment Status"
          value={formData.paymentStatus}
          onChange={(e) => handleChange('paymentStatus', e.target.value)}
          fullWidth
          margin="normal"
        >
          <MenuItem value="">Select Payment Status</MenuItem>
          <MenuItem value="full_paid">Full Paid</MenuItem>
        </TextField>
      )}

      {/* Credit Payment Fields */}
      {formData.paymentMethod === 'credit' && (
        <>
          <TextField
            select
            label="Credit Payment"
            value={formData.creditPaymentType}
            onChange={(e) => handleChange('creditPaymentType', e.target.value)}
            fullWidth
            margin="normal"
          >
            <MenuItem value="">Select Credit Option</MenuItem>
            <MenuItem value="full_credit">Full Credit</MenuItem>
            <MenuItem value="partial_credit">Partial Credit</MenuItem>
          </TextField>

          {formData.creditPaymentType === 'partial_credit' && (
            <TextField
              label="Partial Credit Amount"
              type="number"
              value={formData.partialCreditAmount}
              onChange={(e) => handleChange('partialCreditAmount', e.target.value)}
              placeholder="Enter partial credit amount"
              fullWidth
              margin="normal"
            />
          )}

          {error && <FormHelperText error>{error}</FormHelperText>}
        </>
      )}
    </Box>
  );
};

export default PaymentComponent;
