import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../dashboard/Dashboard';
import ProductList from '../product/ProductList';
import AddProduct from '../product/AddProduct';
import EditProduct from '../product/EditProduct';
import ProductDetail from '../product/ProductDetail';
import ProductSummary from '../product/ProductSummary';
import UnitManagement from '../units/UnitList';
import ShopManagement from '../shops/ShopList';
import PurchaseList from '../purchases/PurchaseList';
import AddPurchase from '../purchases/AddPurchase';
import PurchaseDetail from '../purchases/PurchaseDetail';
import PurchaseSummary from '../purchases/PurchaseSummary';
import AddDistribution from '../distribution/AddDistribution';
import DistributionDetail from '../distribution/DistributionDetails';
import DistributionSummary from '../distribution/DistributionSummary';
import ManageSales from '../sale/SaleList';
import InvoicePage from '../sale/InvoicePage';
import CustomerList from '../customers/CustomerList';
import CustomerDetail from '../customers/CustomerDetail';
import SupplierList from '../suppliers/SupplierList';
import AddSupplier from '../suppliers/AddSupplier';
import CategoryList from '../categories/CategoryList';
import UserList from '../users/UserList';
import AddUser from '../users/AddUser';
import { AuthContext } from '../auth/AuthContext';
import ProtectedRoute from '../auth/ProtectedRoute';
import SalesSummary from '../sale/SalesSummary';
import ProfilePage from '../profile/ProfilePage';
import ShopDetail from '../shops/ShopDetail';
import ShopSales from '../shops/ShopSales';
import ShopReturns from '../shops/ShopReturns';
import ShopReturnDetails from '../shops/ShopReturnDetails';
import ShopSaleDetails from '../shops/ShopSaleDetails';
import TodaySale from '../sales/TodaySale';
import VoidedSales from '../sales/VoidedSale';
import CreateInvoice from '../sales/CreateInvoice';
import AllSale from '../sales/AllSale';
import SalesDetail from '../sales/SalesDetail';
import MakeReturn from '../returns/MakeReturn';
import ReturnList from '../returns/ReturnList';
import ReturnDetail from '../returns/ReturnDetail';
import Expenses from '../expense/ExpenseList';
import AccountPage from '../account/AccountsPage';
import AccountPayableList from '../account/AccountPayables';
import EditAccountPayable from '../account/EditAccountPayable';
import AccountReceivableList from '../account/AccountRecievables';
import EditAccountReceivable from '../account/EditAccountReceivable';
import NotificationsPage from '../notifications/NotificationPage';
import NotificationDetail from '../notifications/DetailNotification';
import CreateQuote from '../quote/CreateQuote';
import QuotesPage from '../quote/QuotesPage';
import QuoteDetailPage from '../quote/QuoteDetail';
import DraftsPage from '../quote/DraftsPage';
import DraftDetail from '../quote/DraftDetail';


const AdminContent = () => {
  const { userId } = useContext(AuthContext);

  return (
    <Routes>
      {/* Common routes for both admin and store-manager */}
      <Route path="products" element={<ProductList />} />
      <Route path="products/add" element={<AddProduct />} />
      <Route path="products/edit/:id" element={<EditProduct />} />
      <Route path="products/detail/:productId" element={<ProductDetail />} />
      <Route path="products/summary" element={<ProductSummary />} />
      <Route path="purchases" element={<PurchaseList />} />
      <Route path="purchases/add" element={<AddPurchase />} />
      <Route path="purchases/detail/:purchaseId" element={<PurchaseDetail />} />
      <Route path="purchases/summary" element={<PurchaseSummary />} />
      <Route path="profile" element={<ProtectedRoute element={<ProfilePage />} roles={['Admin', 'Store-Manager', 'Staff']} userId={userId} />} />
      <Route path="notifications" element={<ProtectedRoute element={<NotificationsPage />} roles={['Admin', 'Store-Manager', 'Staff']} userId={userId} />} />
      <Route path="notifications/detail/:id" element={<ProtectedRoute element={<NotificationDetail />} roles={['Admin', 'Store-Manager', 'Staff']} userId={userId} />} />

      {/* Admin-specific routes */}
      <Route path="dashboard" element={<ProtectedRoute element={<Dashboard />} roles={['Admin']} />} />
      <Route path="units" element={<ProtectedRoute element={<UnitManagement />} roles={['Admin']} />} />
      <Route path="shops" element={<ProtectedRoute element={<ShopManagement />} roles={['Admin']} />} />
      <Route path="shop/detail/:shopId" element={<ProtectedRoute element={<ShopDetail />} roles={['Admin']} />} />
      <Route path="shop/:shopId/sales" element={<ProtectedRoute element={<ShopSales />} roles={['Admin']} />} />
      <Route path="shop/:shopId/returns" element={<ProtectedRoute element={<ShopReturns />} roles={['Admin']} />} />
      <Route path="shop/:shopId/returns/details/:invoiceId" element={<ProtectedRoute element={<ShopReturnDetails />} roles={['Admin']} />} />
      <Route path="shop/:shopId/sales/details/:invoiceId" element={<ProtectedRoute element={<ShopSaleDetails />} roles={['Admin']} />} />
      <Route path="categories" element={<ProtectedRoute element={<CategoryList />} roles={['Admin']} />} />
      <Route path="users" element={<ProtectedRoute element={<UserList />} roles={['Admin']} />} />
      <Route path="users/add" element={<ProtectedRoute element={<AddUser />} roles={['Admin']} />} />
      <Route path="distribution" element={<ProtectedRoute element={<AddDistribution />} roles={['Admin']} />} />
      <Route path="distribution/details/:batchId" element={<ProtectedRoute element={<DistributionDetail />} roles={['Admin']} />} />
      <Route path="distribution/summary" element={<ProtectedRoute element={<DistributionSummary />} roles={['Admin']} />} />
      <Route path="sales" element={<ProtectedRoute element={<ManageSales />} roles={['Admin']} />} />
      <Route path="sales/detail/:invoiceId" element={<ProtectedRoute element={<InvoicePage />} roles={['Admin']} />} />
      <Route path="sales/summary" element={<ProtectedRoute element={<SalesSummary />} roles={['Admin']} />} />
      <Route path="customers" element={<ProtectedRoute element={<CustomerList />} roles={['Admin']} />} />
      <Route path="customers/detail/:name" element={<ProtectedRoute element={<CustomerDetail />} roles={['Admin']} />} />
      <Route path="suppliers" element={<ProtectedRoute element={<SupplierList />} roles={['Admin']} />} />
      <Route path="suppliers/add" element={<ProtectedRoute element={<AddSupplier />} roles={['Admin']} />} />

      <Route path="expenses" element={<ProtectedRoute element={<Expenses />} roles={['Admin']} />} />

      <Route path="accounts" element={<ProtectedRoute element={<AccountPage />} roles={['Admin']} />} />
      <Route path="accounts/account-payables/purchases" element={<ProtectedRoute element={<AccountPayableList />} roles={['Admin']} />} />
      <Route path="accounts/account-payables/purchase/edit/:id" element={<ProtectedRoute element={<EditAccountPayable />} roles={['Admin']} />} />
      <Route path="accounts/account-payables/purchase/view/:id" element={<ProtectedRoute element={<EditAccountPayable />} roles={['Admin']} />} />

      <Route path="accounts/account-receivables/sales" element={<ProtectedRoute element={<AccountReceivableList />} roles={['Admin']} />} />
      <Route path="accounts/account-receivables/sale/edit/:invoiceId" element={<ProtectedRoute element={<EditAccountReceivable />} roles={['Admin']} />} />
      <Route path="accounts/account-receivables/sale/view/:invoiceId" element={<ProtectedRoute element={<EditAccountReceivable />} roles={['Admin']} />} />

      <Route path="quote/create" element={<ProtectedRoute element={<CreateQuote />} roles={['Admin']} userId={userId} />} />
      <Route path="quote" element={<ProtectedRoute element={<QuotesPage />} roles={['Admin']} userId={userId} />} />
      <Route path="quote/detail/:quoteId" element={<ProtectedRoute element={<QuoteDetailPage />} roles={['Admin']} userId={userId} />} />

      <Route path="drafts" element={<ProtectedRoute element={<DraftsPage />} roles={['Admin']} userId={userId} />} />
      <Route path="drafts/detail/:quoteId" element={<ProtectedRoute element={<DraftDetail />} roles={['Admin']} userId={userId} />} />

      <Route path="Make-return" element={<ProtectedRoute element={<MakeReturn />} roles={['Staff']} />} />
      <Route path="returns" element={<ProtectedRoute element={<ReturnList />} roles={['Staff']} />} />
      <Route path="returns/detail/:invoiceId" element={<ProtectedRoute element={<ReturnDetail />} roles={['Staff']} />} />
      
      <Route path="pos" element={<ProtectedRoute element={<CreateInvoice />} roles={['Staff']} />} />
      <Route path="sales/today-Sale" element={<ProtectedRoute element={<TodaySale />} roles={['Staff']} />} />
      <Route path="sales/sale-detail/:invoiceId" element={<ProtectedRoute element={<SalesDetail />} roles={['Staff']} />} />
      <Route path="sales/all-sales" element={<ProtectedRoute element={<AllSale />} roles={['Staff']} />} />
      <Route path="sales/voided" element={<ProtectedRoute element={<VoidedSales />} roles={['Staff']} />} />
    </Routes>
  );
};

export default AdminContent;
