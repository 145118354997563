import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { axiosInstance, axiosAuthInstance } from '../Axios/AxiosInstance';

const Loading = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userRole, userId } = location.state;

  // State for loading and error handling
  const [loadingError, setLoadingError] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      if (userRole === 'Admin') {
        const apiCalls = [
          axiosInstance.get('/products'), // Replace with your actual endpoint
          axiosAuthInstance.get('/auth/users'), // Add more API calls as needed
        ];

        try {
          // Wait for all API calls to complete
          await Promise.all(apiCalls);
          // After data is fetched, navigate to the admin dashboard
          navigate('/admin/dashboard');
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingError('Failed to load data. Please try again.'); // Set error state
        }
      } else {
        // For non-admin roles, delay for 10 seconds before redirecting
        setTimeout(() => {
          if (userRole === 'Store-Manager') {
            navigate(`/admin/notifications?userId=${userId}`);
          } else if (userRole === 'Staff') {
            navigate('/admin/pos'); // Navigating to POS for Staff
          }
        }, 30000); // 10 seconds delay
      }
    };

    fetchData();
  }, [navigate, userRole, userId]);

  return (
    <Box sx={styles.container}>
      <ClipLoader color="#367588" size={150} />
      <Typography variant="h4" sx={styles.text}>
        Loading, please wait...
      </Typography>
      {loadingError ? (
        <Typography variant="body1" sx={styles.errorText}>
          {loadingError}
        </Typography>
      ) : (
        <Typography variant="body1" sx={styles.subText}>
          We are fetching your data and preparing your dashboard.
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f9fafb',
    textAlign: 'center',
    padding: '20px',
    transition: 'background-color 0.5s ease-in-out', // Subtle background transition
  },
  text: {
    marginTop: '20px',
    color: '#367588',
    fontWeight: '600',
    fontSize: '24px',
    animation: 'fadeIn 2s', // Text fade-in effect
  },
  subText: {
    marginTop: '10px',
    color: '#7A7A7A',
    fontSize: '18px',
    animation: 'fadeIn 3s', // Delayed text appearance
  },
  errorText: {
    marginTop: '10px',
    color: 'red',
    fontSize: '18px',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
};

export default Loading;
