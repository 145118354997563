import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const PurchaseSummary = () => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [endDate, setEndDate] = useState(new Date());
  const [grandTotal, setGrandTotal] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dateType, setDateType] = useState(null);

  useEffect(() => {
    fetchPurchases();
  }, [startDate, endDate]);

  const fetchPurchases = async () => {
    try {
      const response = await axiosInstance.get('/purchases', {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }
      });
      const purchasesData = response.data;

      const total = purchasesData.reduce((acc, purchase) => acc + purchase.totalPrice, 0);
      setPurchases(purchasesData);
      setGrandTotal(formatNumberWithCommas(total.toFixed(2)));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching purchases:', error);
      setLoading(false);
    }
  };

  const handleDatePickerOpen = (type) => {
    setDateType(type);
    setDialogOpen(true);
  };

  const handleDatePickerClose = () => {
    setDialogOpen(false);
  };

  const handleDateChange = (date) => {
    if (dateType === 'startDate') {
      setStartDate(date);
    } else if (dateType === 'endDate') {
      setEndDate(date);
    }
    handleDatePickerClose();
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4" component="h1" color="primary">Purchase Summary</Typography>

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => handleDatePickerOpen('startDate')}
          >
            Start Date: {startDate ? format(startDate, 'MM/dd/yyyy') : 'Select date'}
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleDatePickerOpen('endDate')}
          >
            End Date: {endDate ? format(endDate, 'MM/dd/yyyy') : 'Select date'}
          </Button>
        </Box>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit Price (Br.)</TableCell>
                <TableCell>Total Price (Br.)</TableCell>
                <TableCell>Purchase Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchases.flatMap(purchase =>
                purchase.products.map((product, index) => (
                  <TableRow key={`${purchase._id}-${index}`}>
                    <TableCell>{product.product?.name || 'N/A'}</TableCell>
                    <TableCell>{product.product?.description || 'N/A'}</TableCell>
                    <TableCell>{purchase.supplier?.name || 'N/A'}</TableCell>
                    <TableCell>{formatNumberWithCommas(product.quantity)}</TableCell>
                    <TableCell>{formatNumberWithCommas(product.unitPrice.toFixed(2))}</TableCell>
                    <TableCell>{formatNumberWithCommas(product.totalPrice.toFixed(2))}</TableCell>
                    <TableCell>{new Date(purchase.purchaseDate).toLocaleDateString()}</TableCell>
                  </TableRow>
                ))
              )}
              <TableRow>
                <TableCell colSpan={5} align="right" sx={{ color: '#367588' }}><strong>Grand Total:</strong></TableCell>
                <TableCell colSpan={2}><strong>{grandTotal} Br.</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={dialogOpen} onClose={handleDatePickerClose}>
        <DialogTitle>Select Date</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={dateType === 'startDate' ? 'Start Date' : 'End Date'}
              value={dateType === 'startDate' ? startDate : endDate}
              onChange={handleDateChange}
              components={{
                TextField: (props) => <TextField {...props} />
              }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDatePickerClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PurchaseSummary;
