import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Pagination,
  Checkbox,
  Button,
  FormControlLabel,
  Container,
  Grid,
  Paper,
  Breadcrumbs,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate } from 'react-router-dom';

// Format numbers with commas
const formatNumberWithCommas = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const ManageSales = () => {
  const [sales, setSales] = useState([]);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [endDate, setEndDate] = useState(new Date());
  const [timeRange, setTimeRange] = useState('today');
  const [voided, setVoided] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams({
      searchQuery,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      timeRange,
      voided,
    }).toString();
    
    navigate(`/admin/sales?${queryParams}`, { replace: true });
  }, [searchQuery, startDate, endDate, timeRange, voided, navigate]);

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await axiosInstance.get('/sales/filtered', {
          params: {
            searchQuery,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            timeRange,
            voided,
          },
        });
        setSales(response.data);
      } catch (error) {
        setError('Error fetching sales data.');
      }
    };

    fetchSales();
  }, [searchQuery, startDate, endDate, timeRange, voided]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const paginatedSales = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sales.slice(startIndex, endIndex);
  };

  const handleViewDetails = (invoiceId) => {
    navigate(`/admin/sales/detail/${invoiceId}`);
  };

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(sales.length / itemsPerPage);

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" color="primary" >Manage Sales</Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit">Manage Sales</Typography>
          <Typography color="textPrimary">Sale List</Typography>
        </Breadcrumbs>
      </Box>

      {error && <Box sx={{ mb: 4, p: 2, bgcolor: 'error.main', color: 'white' }}>{error}</Box>}

      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth>
            <InputLabel>Time Range</InputLabel>
            <Select
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
            >
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="this_week">This Week</MenuItem>
              <MenuItem value="this_month">This Month</MenuItem>
              <MenuItem value="this_year">This Year</MenuItem>
              <MenuItem value="all_time">All Time</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Date Range Filter */}
        <Grid item xs={12} md={6} lg={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  slots={{ textField: (params) => <TextField {...params} fullWidth /> }} // Updated for MUI v6
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  slots={{ textField: (params) => <TextField {...params} fullWidth /> }} // Updated for MUI v6
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>

        {/* Search Box */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Search by invoice number or product name"
            value={searchQuery}
            onChange={handleSearch}
          />
        </Grid>

        {/* Voided Sales Toggle */}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={voided}
                onChange={(e) => setVoided(e.target.checked)}
              />
            }
            label={
              <Typography style={{ color: '#367588' }}>
                Show Voided Sales
              </Typography>
            }
          />
        </Grid>

      </Grid>

      {sales.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Invoice ID</TableCell>
                <TableCell>Number of Products</TableCell>
                <TableCell>Total Quantity</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Sale Date</TableCell>
                <TableCell>Payment Method</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedSales().map((sale) => (
                <TableRow key={sale.invoiceId}>
                  <TableCell>{sale.invoiceId}</TableCell>
                  <TableCell>{sale.products.length}</TableCell>
                  <TableCell>{formatNumberWithCommas(sale.totalQuantity)}</TableCell>
                  <TableCell>{formatNumberWithCommas(sale.totalPrice.toFixed(2))}</TableCell>
                  <TableCell>{new Date(sale.saleDate).toLocaleDateString()}</TableCell>
                  <TableCell>{sale.paymentMethod}</TableCell>
                  <TableCell>{sale.paymentStatus}</TableCell>
                  <TableCell>{sale.createdBy}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleViewDetails(sale.invoiceId)}
                    >
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            sx={{ mt: 2, mb: 2 }}
          />
        </TableContainer>
      ) : (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h6">No sales found</Typography>
        </Box>
      )}
    </Container>
  );
};

export default ManageSales;
