import { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';

const formatPhoneNumber = (phone) => {
  phone = phone.replace(/\D/g, '');

  if (phone.length === 12 && phone.startsWith('251') && (phone[3] === '9' || phone[3] === '7')) {
    return `+251${phone.slice(3)}`;
  }
  if (phone.length === 10 && (phone.startsWith('09') || phone.startsWith('07'))) {
    return `+251${phone.slice(1)}`;
  }
  if (phone.length === 9 && (phone.startsWith('9') || phone.startsWith('7'))) {
    return `+251${phone}`;
  }

  if (phone.length === 13 && phone.startsWith('86') && phone[2] === '1') {
    return `+86${phone.slice(2)}`;
  }
  if (phone.length === 11 && phone.startsWith('1')) {
    return `+86${phone}`;
  }

  return null;
};

const useSuppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteSupplierId, setDeleteSupplierId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [submissionSuccess, setSubmissionSuccess] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [editName, setEditName] = useState('');
  const [editPhones, setEditPhones] = useState(['']);
  const [editEmail, setEditEmail] = useState('');
  const [editAddress, setEditAddress] = useState('');

  const [loading, setLoading] = useState(false);  // Loading state
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const fetchSuppliers = async () => {
    setLoading(true);  // Start loading
    setFetchError(null); 

    try {
      const response = await axiosInstance.get('/suppliers');
      const fetchedSuppliers = Array.isArray(response.data) ? response.data : [];
      setSuppliers(fetchedSuppliers);
      setFilteredSuppliers(fetchedSuppliers);
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    } finally {
      setLoading(false);  // Stop loading
    }
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    filterSuppliers(searchTerm);
    setSubmissionError(null);
    setSubmissionSuccess(null);
  };

  const filterSuppliers = (searchTerm) => {
    const filtered = suppliers.filter(supplier =>
      supplier.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      supplier.phones.some(phone => phone.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredSuppliers(filtered);
  };

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const handleDeleteSupplier = async () => {
    try {
      await axiosInstance.delete(`/suppliers/${deleteSupplierId}`);
      setSuppliers(suppliers.filter(supplier => supplier._id !== deleteSupplierId));
      setFilteredSuppliers(filteredSuppliers.filter(supplier => supplier._id !== deleteSupplierId));
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Error deleting supplier:', error);
    }
  };

  const handleOpenDeleteDialog = (supplierId) => {
    setDeleteSupplierId(supplierId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteSupplierId(null);
    setOpenDeleteDialog(false);
  };

  const handleEditClick = (supplier) => {
    setEditMode(supplier._id);
    setEditName(supplier.name);
    setEditPhones(supplier.phones.length > 0 ? supplier.phones : ['']);
    setEditEmail(supplier.email);
    setEditAddress(supplier.address);
  };

  const handleSaveEdit = async () => {
    const validPhones = editPhones.map(phone => formatPhoneNumber(phone.trim())).filter(phone => phone !== null);

    if (validPhones.length === 0) {
      setSubmissionError('At least one phone number must be valid.');
      return;
    }

    try {
      const response = await axiosInstance.put(`/suppliers/${editMode}`, {
        name: editName,
        phones: validPhones,
        email: editEmail,
        address: editAddress
      });

      setSuppliers(suppliers.map(supplier =>
        supplier._id === editMode ? { ...supplier, name: editName, phones: validPhones, email: editEmail, address: editAddress } : supplier
      ));
      setFilteredSuppliers(filteredSuppliers.map(supplier =>
        supplier._id === editMode ? { ...supplier, name: editName, phones: validPhones, email: editEmail, address: editAddress } : supplier
      ));
      setEditMode(null);
      setSubmissionSuccess('Supplier updated successfully');
      setSubmissionError(null);
    } catch (error) {
      console.error('Error updating supplier:', error.response ? error.response.data : error.message);
      if (error.response && error.response.data.message) {
        setSubmissionError(error.response.data.message);
      } else {
        setSubmissionError('Error updating supplier.');
      }
    }
  };

  const handleCancelEdit = () => {
    setEditMode(null);
    setEditName('');
    setEditPhones(['']);
    setEditEmail('');
    setEditAddress('');
  };

  const handleEditPhoneChange = (index, value) => {
    const updatedPhones = [...editPhones];
    updatedPhones[index] = value;
    setEditPhones(updatedPhones);
  };

  const handleAddPhone = () => {
    setEditPhones([...editPhones, '']);
  };

  return {
    suppliers,
    filteredSuppliers,
    searchTerm,
    deleteSupplierId,
    openDeleteDialog,
    isSearchFocused,
    submissionError,
    submissionSuccess,
    editMode,
    editName,
    editPhones,
    editEmail,
    editAddress,
    loading,
    fetchError,
    fetchSuppliers,
    handleSearchChange,
    handleSearchFocus,
    handleSearchBlur,
    handleDeleteSupplier,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleEditClick,
    handleSaveEdit,
    handleCancelEdit,
    handleEditPhoneChange,
    handleAddPhone,
    setEditName,
    setEditEmail,
    setEditAddress,
  };
};

export default useSuppliers;
