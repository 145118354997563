import { useState, useCallback } from 'react';
import { axiosAuthInstance } from '../../Axios/AxiosInstance';

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosAuthInstance.get('/auth/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users.');
    } finally {
      setLoading(false);
    }
  }, []);

  const editUser = useCallback(async (userId, userData) => {
    try {
      await axiosAuthInstance.put(`/auth/users/${userId}`, userData);
      setUsers(users.map(user => (user._id === userId ? { ...user, ...userData } : user)));
    } catch (error) {
      console.error('Error updating user:', error);
      throw new Error('Error updating user.');
    }
  }, [users]);

  const deleteUser = useCallback(async (userId) => {
    try {
      await axiosAuthInstance.delete(`/auth/users/${userId}`);
      setUsers(users.filter(user => user._id !== userId));
    } catch (error) {
      console.error('Error deleting user:', error);
      throw new Error('Error deleting user.');
    }
  }, [users]);

  const lockUser = useCallback(async (userId) => {
    try {
      await axiosAuthInstance.post(`/auth/lock-user/${userId}`);
      setUsers(users.map(user => (user._id === userId ? { ...user, locked: true } : user)));
    } catch (error) {
      console.error('Error locking user:', error);
      throw new Error('Error locking user.');
    }
  }, [users]);

  const unlockUser = useCallback(async (userId) => {
    try {
      await axiosAuthInstance.post(`/auth/unlock-user/${userId}`);
      setUsers(users.map(user => (user._id === userId ? { ...user, locked: false } : user)));
    } catch (error) {
      console.error('Error unlocking user:', error);
      throw new Error('Error unlocking user.');
    }
  }, [users]);

  return { users, fetchUsers, editUser, deleteUser, lockUser, unlockUser, loading, error };
};
