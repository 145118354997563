import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert,
    Pagination
} from '@mui/material';
import { axiosInstance } from '../../Axios/AxiosInstance';

const DraftDetail = () => {
    const { quoteId } = useParams();
    const [draft, setDraft] = useState(null);
    const [updatedQuantity, setUpdatedQuantity] = useState({});
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [selectedUpdateProduct, setSelectedUpdateProduct] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [currentPage, setCurrentPage] = useState(1); // Current page state
    const [itemsPerPage] = useState(5); // Items per page
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDraft = async () => {
            try {
                const response = await axiosInstance.get(`/quotes/${quoteId}`);
                setDraft(response.data);
                const initialQuantities = {};
                response.data.products.forEach(product => {
                    initialQuantities[product.productId._id] = product.quantity;
                });
                setUpdatedQuantity(initialQuantities);
            } catch (error) {
                console.error('Error fetching draft details:', error);
            }
        };

        fetchDraft();
    }, [quoteId]);

    const handleQuantityChange = (productId, value) => {
        setUpdatedQuantity({ ...updatedQuantity, [productId]: value });
    };

    const confirmUpdateQuantity = async () => {
        if (!selectedUpdateProduct) return;
    
        const productId = selectedUpdateProduct.productId._id;
        const newQuantity = updatedQuantity[productId];
        const originalQuantity = draft.products.find(product => product.productId._id === productId).quantity;
    
        if (newQuantity <= 0) {
            setSnackbarSeverity('error');
            setSnackbarMessage('Invalid quantity');
            setSnackbarOpen(true);
            return;
        }
    
        if (newQuantity === originalQuantity) {
            setSnackbarSeverity('warning');
            setSnackbarMessage('You have not made a change');
            setSnackbarOpen(true);
            return;
        }
    
        try {
            await axiosInstance.put(`/quotes/${quoteId}/product/${productId}/quantity`, {
                newQuantity,
            });
            setSnackbarMessage('Quantity updated');
            setSnackbarOpen(true);
            setDraft((prevDraft) => ({
                ...prevDraft,
                products: prevDraft.products.map((product) =>
                    product.productId._id === productId
                        ? { ...product, quantity: newQuantity }
                        : product
                ),
            }));
        } catch (error) {
            console.error('Error updating quantity:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage('Error updating quantity');
            setSnackbarOpen(true);
        } finally {
            handleCloseUpdateDialog(); // Close dialog after handling
        }
    };

    const handleRemoveProductClick = (product) => {
        setSelectedProduct(product);
        setOpenDialog(true);
    };

    const removeProduct = async () => {
        if (!selectedProduct) return;

        const { _id: productId } = selectedProduct.productId;
        
        try {
            await axiosInstance.delete(`/quotes/${quoteId}/product/${productId}`);
            setSnackbarMessage('Product removed');
            setSnackbarOpen(true);
            setDraft((prevDraft) => ({
                ...prevDraft,
                products: prevDraft.products.filter((product) => product.productId._id !== productId),
            }));
        } catch (error) {
            console.error('Error removing product:', error);
            setSnackbarSeverity('error');
            setSnackbarMessage('Error removing product');
            setSnackbarOpen(true);
        } finally {
            setOpenDialog(false);
            setSelectedProduct(null);
        }
    };

    const toggleEditMode = () => {
        setIsEditable(!isEditable);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedProduct(null);
    };

    const handleOpenSubmitDialog = () => {
        if (isEditable) {
            // Set the message indicating they are still editing
            setSnackbarMessage("Do you wish to finish editing before submitting, or do you want to submit now?");
        }
        setOpenSubmitDialog(true);
    };
    
    const handleCloseSubmitDialog = () => {
        setOpenSubmitDialog(false);
    };
    
    const handleOpenUpdateDialog = (product) => {
        setSelectedUpdateProduct(product);
        setOpenUpdateDialog(true);
    };
    
    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
        setSelectedUpdateProduct(null);
    };
    

    const handleSubmitQuote = async () => {
        const anyQuantityInvalid = Object.values(updatedQuantity).some(q => q < 1);
        if (anyQuantityInvalid) {
            setSnackbarMessage('All quantities must be filled and greater than or equal to 1.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }
    
        const quoteData = {
            isDraft: false,
            products: draft.products.map(product => ({
                productId: product.productId._id,
                quantity: updatedQuantity[product.productId._id],
            })),
        };
    
        try {
            // Submit the quote
            await axiosInstance.post('/quotes/batch', quoteData); // Adjust endpoint for saving the quote
            setSnackbarMessage('Quote submitted successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            
            // Delete the draft from the server
            await axiosInstance.delete(`/quotes/${quoteId}`);
            
            // Remove the draft from local storage
            localStorage.removeItem(`draft_${quoteId}`);
    
            navigate('/admin/quote'); // Redirect to quotes page after submission
        } catch (error) {
            console.error('Error submitting quote:', error);
            setSnackbarMessage('Failed to submit quote.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };
    
    // Pagination Logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentProducts = draft?.products.slice(indexOfFirstItem, indexOfLastItem) || [];

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    if (!draft) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Box p={3}>
            <Typography variant="h4" color="primary" gutterBottom sx={{ mb: 3 }}>
                Draft Details
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <Button 
                    onClick={() => navigate(-1)} 
                    variant="outlined" 
                    color="primary" 
                    sx={{ mr: 2 }}
                >
                    Back to Drafts
                </Button>
                <Button
                    onClick={toggleEditMode}
                    variant="outlined"
                    color={isEditable ? "error" : "primary"}
                    sx={{ mr: 2 }}
                >
                    {isEditable ? 'Stop Editing' : 'Enable Editing'}
                </Button>
                <Button
                    onClick={handleOpenSubmitDialog}
                    variant="contained"
                    color="primary"
                >
                    Finalize Quote
                </Button>
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentProducts.map((product) => (
                            <TableRow key={product.productId._id}>
                                <TableCell>{product.productId.name}</TableCell>
                                <TableCell>{product.productId.description}</TableCell>
                                <TableCell>
                                    <TextField
                                        value={updatedQuantity[product.productId._id] || product.quantity}
                                        onChange={(e) =>
                                            handleQuantityChange(product.productId._id, e.target.value)
                                        }
                                        disabled={!isEditable}
                                    />
                                </TableCell>
                                <TableCell>
                                    {isEditable && (
                                    <Button 
                                        variant='outlined'
                                        color="primary"
                                        onClick={() => handleOpenUpdateDialog(product)}
                                        disabled={updatedQuantity[product.productId._id] === product.quantity} 
                                    >
                                        Update
                                    </Button>
                                    )}
                                    <Button
                                        variant='outlined'
                                        color="error"
                                        onClick={() => handleRemoveProductClick(product)}
                                        sx={{ marginLeft: 2}}
                                    >
                                        Remove
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Pagination Controls */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Pagination
                    count={Math.ceil(draft.products.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Confirm Removal</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove the following product?
                    </DialogContentText>
                    {selectedProduct && (
                        <>
                            <Typography variant="subtitle1">
                                {selectedProduct.productId.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {selectedProduct.productId.description}
                            </Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={removeProduct} variant='contained' color="error">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openSubmitDialog} onClose={handleCloseSubmitDialog}>
                <DialogTitle>Confirm Submission</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {isEditable 
                            ? "Do you wish to finish editing before submitting, or do you want to submit now?" 
                            : "Are you sure you want to submit the quote? This action cannot be undone."
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSubmitDialog} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => {
                            if (isEditable) {
                                // Optionally, you could close the dialog and finish editing
                                setIsEditable(false); // Finish editing if they choose to
                            }
                            handleSubmitQuote(); // Proceed to submit the quote
                            handleCloseSubmitDialog(); // Close the dialog
                        }} 
                        variant='contained' 
                        color="primary"
                    >
                        {isEditable ? "Submit Now" : "Confirm Submission"}
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openUpdateDialog} onClose={handleCloseUpdateDialog}>
                <DialogTitle>Confirm Quantity Update</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to update the quantity for the following product?
                    </DialogContentText>
                    {selectedUpdateProduct && (
                        <>
                            <Typography variant="subtitle1">
                                {selectedUpdateProduct.productId.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Current Quantity: {selectedUpdateProduct.quantity}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                New Quantity: {updatedQuantity[selectedUpdateProduct.productId._id] || selectedUpdateProduct.quantity}
                            </Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUpdateDialog} variant='outlined' color="error">
                        Cancel
                    </Button>
                    <Button 
                        onClick={confirmUpdateQuantity} 
                        variant='contained' 
                        color="primary"
                    >
                        Confirm Update
                    </Button>
                </DialogActions>
            </Dialog>


            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default DraftDetail;
