import React from 'react';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Breadcrumbs,
  Alert,
  CircularProgress,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import useCustomers from '../hooks/useCustomers';

const CustomerList = () => {
  const {
    filteredCustomers,
    searchTerm,
    deleteCustomerId,
    openDeleteDialog,
    newCustomerName,
    newCustomerPhone,
    submissionError,
    submissionSuccess,
    editMode,
    editName,
    editPhone,
    loading,
    fetchError,
    handleSearchChange,
    handleDeleteCustomer,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleAddCustomer,
    handleEditClick,
    handleSaveEdit,
    handleCancelEdit,
    setNewCustomerName,
    setNewCustomerPhone,
    setSubmissionError,
    setSubmissionSuccess,
    setEditName,
    setEditPhone
  } = useCustomers();

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" color="primary">Customers List</Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit">Customers</Typography>
          <Typography color="textPrimary">Customer List</Typography>
        </Breadcrumbs>
      </Box>

      <Box sx={{ mb: 3 }}>
        <TextField
          label="Search customers by name or phone"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
        />
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" color="primary" gutterBottom>
          Add New Customer
        </Typography>
        {submissionError && <Alert severity="error">{submissionError}</Alert>}
        {submissionSuccess && <Alert severity="success">{submissionSuccess}</Alert>}
        <Box component="form" noValidate autoComplete="off">
          <TextField
            label="Customer Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newCustomerName}
            onChange={(e) => {
              setNewCustomerName(e.target.value);
              setSubmissionError(null);
              setSubmissionSuccess(null);
            }}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newCustomerPhone}
            onChange={(e) => {
              setNewCustomerPhone(e.target.value);
              setSubmissionError(null);
              setSubmissionSuccess(null);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddCustomer}
            sx={{ mt: 2 }}
            disabled={!newCustomerName.trim() || !newCustomerPhone.trim()}
          >
            Add Customer
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : fetchError ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" color="error">{fetchError}</Typography>
        </Box>
      ) : filteredCustomers.length === 0 ? (
        <Typography variant="h6" color="textSecondary">
          No customers found
        </Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCustomers.map((customer) => (
                <TableRow key={customer._id}>
                  <TableCell>
                    {editMode === customer._id ? (
                      <TextField
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      customer.name
                    )}
                  </TableCell>
                  <TableCell>
                    {editMode === customer._id ? (
                      <TextField
                        value={editPhone}
                        onChange={(e) => setEditPhone(e.target.value)}
                        variant="outlined"
                        size="small"
                      />
                    ) : (
                      customer.phone
                    )}
                  </TableCell>
                  <TableCell>
                    {editMode === customer._id ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSaveEdit(customer._id)}
                          sx={{ mr: 1 }}
                        >
                          Save
                        </Button>
                        <Button
                          color="error"
                          onClick={handleCancelEdit}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          color="primary"
                          component={Link}
                          to={`/admin/customers/detail/${encodeURIComponent(customer.name)}`}
                          startIcon={<VisibilityIcon />}
                        >
                          View
                        </Button>

                        <Button
                          color="primary"
                          onClick={() => handleEditClick(customer)}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                        <Button
                          color="error"
                          onClick={() => handleOpenDeleteDialog(customer._id)}
                          startIcon={<DeleteIcon />}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-danger">Confirm Customer Deletion</DialogTitle>
        <DialogContent>
          {deleteCustomerId && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
              Please confirm if you wish to delete the following customer:
              </Typography>
              {filteredCustomers
                .filter(customer => customer._id === deleteCustomerId)
                .map(customer => (
                  <Box key={customer._id} sx={{ mb: 2 }}>
                    <Typography variant="body1"><strong>Name:</strong> {customer.name}</Typography>
                    <Typography variant="body1"><strong>Phone:</strong> {customer.phone}</Typography>
                  </Box>
                ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDeleteCustomer(deleteCustomerId)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CustomerList;
