import React, { useState, useCallback, useEffect } from 'react';
import { axiosAuthInstance } from '../../Axios/AxiosInstance';
import {
  TextField,
  Button,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const CustomButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const AddUser = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    role: '',
    shop: '',
    password: '',
    confirmPassword: '',
    profilePicture: null // Add profilePicture to the formData state
  });
  const [shops, setShops] = useState([]);
  const [submissionError, setSubmissionError] = useState(null);
  const [submissionSuccess, setSubmissionSuccess] = useState(null);
  const [securityKey, setSecurityKey] = useState(null); // State to store the security key
  
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // State variables for password requirements
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
    noWhitespace: false,
  });

  const fetchShops = useCallback(async () => {
    try {
      const response = await axiosAuthInstance.get('/api/shops');
      setShops(response.data);
    } catch (error) {
      console.error('Error fetching shops:', error);
    }
  }, []);

  useEffect(() => {
    fetchShops();
  }, [fetchShops]);

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]); // Extract Base64 string
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
    // Validate password requirements
    if (name === 'password') {
      const updatedRequirements = {
        length: value.length >= 8,
        uppercase: /[A-Z]/.test(value),
        lowercase: /[a-z]/.test(value),
        number: /[0-9]/.test(value),
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
        noWhitespace: !/\s/.test(value),
      };
      setPasswordRequirements(updatedRequirements);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const base64 = await toBase64(file);
      setFormData({ ...formData, profilePicture: base64 });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = formData;
  
    // Validate passwords
    if (password !== confirmPassword) {
      setSubmissionError('Passwords do not match. Please ensure both passwords are the same.');
      setSubmissionSuccess(null); // Clear any previous success message
      return;
    }
  
    try {
      // Prepare the data for submission
      const submissionData = { ...formData };
      // Remove any null or undefined fields (e.g., profilePicture if not provided)
      Object.keys(submissionData).forEach(key => {
        if (submissionData[key] === null || submissionData[key] === undefined) {
          delete submissionData[key];
        }
      });
  
      // Make the API call
      const response = await axiosAuthInstance.post('/auth/signup', submissionData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      // Handle successful response
      if (response.data.securityKey) {
        setSecurityKey(response.data.securityKey); // Set the security key if present
      }
  
      setSubmissionSuccess('User has been successfully added.');
      setSubmissionError(null);
      setFormData({
        username: '',
        email: '',
        role: '',
        shop: '',
        password: '',
        confirmPassword: '',
        profilePicture: null
      });
    } catch (error) {
      // Check if the error is a network error
      if (!error.response) {
        setSubmissionError('Network error: Unable to connect to the server. Please check your internet connection and try again.');
      } else {
        // Handle other types of errors
        const errorMessage = error.response?.data?.message || 'An unexpected error occurred while adding the user. Please try again later.';
        setSubmissionError(errorMessage);
      }
  
      setSubmissionSuccess(null); // Clear any previous success message
      setSecurityKey(null); // Clear any previously set security key
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="primary" sx={{ mb: 3 }}>Add User</Typography>

      {submissionError && <Alert severity="error" sx={{ mb: 3 }}>{submissionError}</Alert>}
      {submissionSuccess && <Alert severity="success" sx={{ mb: 3 }}>{submissionSuccess}</Alert>}
      {securityKey && <Alert severity="info" sx={{ mb: 3 }}>Security Key: {securityKey}</Alert>}

      <form onSubmit={handleFormSubmit}>
        <TextField
          label="Username"
          variant="outlined"
          name="username"
          value={formData.username}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
          required
          autoComplete="username" // Added autocomplete attribute
        />
        <TextField
          label="Email"
          variant="outlined"
          name="email"
          value={formData.email}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
          required
          autoComplete="email" // Added autocomplete attribute
        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          name="password"
          value={formData.password}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
          required
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(prev => !prev)}
                  edge="end"
                  color="primary"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* Password Requirements Checklist */}
        {formData.password && (
          <Box sx={{ mt: 2, backgroundColor: '#f5f5f5', p: 2, borderRadius: '4px' }}>
            <Typography variant="h6" gutterBottom>Password Requirements:</Typography>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={passwordRequirements.length} />} label="At least 8 characters" />
              <FormControlLabel control={<Checkbox checked={passwordRequirements.uppercase} />} label="At least one uppercase letter" />
              <FormControlLabel control={<Checkbox checked={passwordRequirements.lowercase} />} label="At least one lowercase letter" />
              <FormControlLabel control={<Checkbox checked={passwordRequirements.number} />} label="At least one number" />
              <FormControlLabel control={<Checkbox checked={passwordRequirements.specialChar} />} label="At least one special character" />
              <FormControlLabel control={<Checkbox checked={passwordRequirements.noWhitespace} />} label="No leading or trailing whitespace" />
            </FormGroup>
          </Box>
        )}
        <TextField
          label="Confirm Password"
          type={showConfirmPassword ? 'text' : 'password'}
          variant="outlined"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleFormChange}
          fullWidth
          margin="normal"
          required
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={() => setShowConfirmPassword(prev => !prev)}
                  edge="end"
                  color="primary"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel>Role</InputLabel>
          <Select
            name="role"
            value={formData.role}
            onChange={handleFormChange}
            autoComplete="off" // Optional attribute for Select
          >
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Store-Manager">Store Manager</MenuItem>
            <MenuItem value="Staff">Staff</MenuItem>
          </Select>
        </FormControl>
        {formData.role === 'Staff' && (
          <FormControl fullWidth margin="normal">
            <InputLabel>Shop</InputLabel>
            <Select
              name="shop"
              value={formData.shop}
              onChange={handleFormChange}
              autoComplete="off" // Optional attribute for Select
            >
              <MenuItem value="">None</MenuItem>
              {shops.map(shop => (
                <MenuItem key={shop._id} value={shop._id}>{shop.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <input
          accept="image/*"
          type="file"
          onChange={handleFileChange}
          style={{ display: 'block', margin: '20px 0' }}
        />
        <CustomButton 
          variant="contained" 
          color="primary" 
          type="submit"
          disabled={
            !formData.username.trim() ||
            !formData.email.trim() ||
            !formData.password.trim() ||
            !formData.confirmPassword.trim() ||
            !formData.role.trim() ||
            !Object.values(passwordRequirements).every(Boolean) // Ensure all requirements are met
          }
        >
          Add User
        </CustomButton>
      </form>
    </Box>
  );
};

export default AddUser;
