import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Grid, Alert, CircularProgress, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Snackbar } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Check from '@mui/icons-material/Check'; // Import Check icon
import logo from '../../assets/images/Inventory1.svg';
import axios from 'axios';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    setLoading(true);

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/reset-password/${token}`, { newPassword: password });
      setMessage(response.data.message);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);

      // Remove token from URL and navigate to signin page
      setTimeout(() => {
        navigate('/auth/signin', { replace: true });
      }, 5000); // 3000 milliseconds = 3 seconds
    } catch (err) {
      if (err.response && err.response.data) {
        if (err.response.data.message.includes('Invalid token') || err.response.data.message.includes('Token expired')) {
          setError('Your reset token is invalid or has expired. Please request a new one.');
        } else {
          setError(err.response.data.message || 'Something went wrong. Please try again.');
        }
      } else {
        setError('An error occurred. Please try again.');
      }
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #29323c, #485563)',
        color: '#fff',
        padding: 0,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3,
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              boxShadow: 12,
              borderRadius: 3,
              overflow: 'hidden',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6} sx={{ p: 5 }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                  <img src={logo} alt="Logo" style={{ maxWidth: '150px', margin: '0 auto' }} />
                </Box>
                <Typography variant="h5" component="h3" gutterBottom sx={{ color: '#367588', textAlign: 'center' }}>
                  Reset Password
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="New Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    error={Boolean(error)}
                    helperText={error}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onMouseDown={togglePasswordVisibility}
                            onMouseUp={togglePasswordVisibility}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Confirm Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    error={Boolean(error)}
                    helperText={error}
                  />
                  <Box sx={{ mt: 3 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      sx={{ backgroundColor: '#367588', color: '#fff', '&:hover': { backgroundColor: '#5f9ea0' } }}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Reset Password'}
                    </Button>
                  </Box>
                </form>
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {error || message}
                  </Alert>
                </Snackbar>
              </Grid>
              <Grid item xs={12} md={6} sx={{ p: 5, backgroundColor: '#2b556a', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h4" component="h2" gutterBottom>
                  Reset Your Password
                </Typography>
                <Typography variant="body1" gutterBottom>
                  To keep your account secure, we need you to update your password. Follow the instructions to set a new password.
                </Typography>
                <List
                  sx={{
                    listStyleType: 'disc',
                    paddingLeft: 3,
                    '& .MuiListItem-root': {
                      color: 'white',
                    },
                  }}
                >
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <Check sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary="Create a strong password" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <Check sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary="Use a mix of letters, numbers, and symbols" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <Check sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary="Avoid using easily guessed passwords" />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default ResetPassword;
