import React, { useState, useEffect } from 'react';
import { axiosAuthInstance } from '../../Axios/AxiosInstance';
import {
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Grid,
  Divider,
  InputAdornment,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import CircularProgress from '@mui/material/CircularProgress';

const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]); // Extract Base64 string
    reader.onerror = (error) => reject(error);
  });
};

const ProfilePage = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    currentPassword: '',
    password: '',
    confirmPassword: '',
    profilePictureBase64: ''
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [submissionSuccess, setSubmissionSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUserDetails(); // Fetch user details on component mount
  }, []);

  const fetchUserDetails = async () => {
    try {
      const response = await axiosAuthInstance.get('/auth/user');
      setUserDetails(response.data);
      setFormData({
        username: response.data.username,
        email: response.data.email,
        currentPassword: '',
        password: '',
        confirmPassword: '',
        profilePictureBase64: response.data.profilePicture,
      });
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const base64 = await toBase64(file);
        setFormData({ ...formData, profilePictureBase64: base64 });
      } catch (error) {
        console.error('Error converting file to base64:', error);
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { currentPassword, password, confirmPassword } = formData;
  
    // Validate passwords if they are provided
    if (password || confirmPassword) {
      if (password !== confirmPassword) {
        setSubmissionError('New passwords do not match');
        setTimeout(() => setSubmissionError(null), 5000);
        setIsLoading(false);
        return;
      }
  
      try {
        // Confirm current password
        const confirmResponse = await axiosAuthInstance.post('/auth/confirm-password', {
          currentPassword,
        });
  
        if (!confirmResponse.data.valid) {
          setSubmissionError('Current password is incorrect');
          setTimeout(() => setSubmissionError(null), 5000);
          setIsLoading(false);
          return;
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Error confirming current password';
        setSubmissionError(errorMessage);
        console.error('Error confirming current password:', error);
        setIsLoading(false);
        return;
      }
    }
  
    try {
      // Prepare submission data, excluding currentPassword
      const submissionData = { ...formData };
      delete submissionData.currentPassword;
  
      // Remove undefined or null values
      Object.keys(submissionData).forEach(key => {
        if (submissionData[key] === null || submissionData[key] === undefined) {
          delete submissionData[key];
        }
      });
  
      // Make the API call to update the user profile
      const response = await axiosAuthInstance.put(`/auth/users/${userDetails._id}`, submissionData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      setUserDetails(response.data);
      setSubmissionSuccess('Profile updated successfully');
      setSubmissionError(null);
      setTimeout(() => setSubmissionSuccess(null), 5000);
      
      // Fetch user details again to get the latest information
      await fetchUserDetails(); // Add this line
  
      setFormData(prevData => ({
        ...prevData,
        currentPassword: '',
        password: '',
        confirmPassword: ''
      }));
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error updating profile';
      setSubmissionError(errorMessage);
      console.error('Error updating profile:', error);
    } finally {
      setIsLoading(false); // Set loading to false after submission completes
    }
  };
  

  const handleOpenEditModal = () => setShowEditModal(true);
  const handleCloseEditModal = () => setShowEditModal(false);

  const formatDate = (timestamp) => {
    if (!timestamp) {
      console.warn('Timestamp is null or undefined, returning "N/A"');
      return 'N/A'; // Return 'N/A' if the timestamp is null or undefined
    }
  
    try {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) {
        console.error('Invalid date object created from timestamp:', timestamp);
        return 'Invalid Date'; // Handle cases where timestamp cannot be converted to a valid date
      }
  
      return new Intl.DateTimeFormat('en-ET', { dateStyle: 'medium', timeStyle: 'short' }).format(date);
    } catch (error) {
      console.error('Error formatting date:', error, 'Timestamp provided:', timestamp);
      return 'Invalid Date'; // Return fallback for invalid dates
    }
  };

  if (!userDetails) return <Typography>Loading...</Typography>;

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
      <Typography variant="h4" color="primary" gutterBottom align="center">
        Profile
      </Typography>

      <Card sx={{ mb: 3, boxShadow: 3 }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            {/* Profile Picture Container */}
            <Box sx={{ position: 'relative', mr: 2 }}>
              <Avatar
                src={`data:image/jpeg;base64,${formData.profilePictureBase64}` || undefined}
                sx={{ width: 120, height: 120, bgcolor: '#90cbd5' }}
              >
                {!formData.profilePictureBase64 && userDetails.username.charAt(0).toUpperCase()}
              </Avatar>
              
              {/* Status Indicator */}
              {userDetails.isOnline && (
                <Box sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 5,
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'background.paper',
                  borderRadius: '50%',
                  padding: '2px',
                  border: '3px solid #fff' // Optional: to give a subtle border around the status dot
                }}>
                  <Brightness1Icon style={{ color: '#39e600', fontSize: '18px' }} />
                </Box>
              )}
            </Box>

            {/* Username and Online Status */}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                {userDetails.username}
              </Typography>
            </Box>

            {/* Edit Button */}
            <IconButton onClick={handleOpenEditModal} color="primary" sx={{ ml: 'auto' }}>
              <EditIcon />
            </IconButton>
          </Box>

          <Divider />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h7" gutterBottom>
                <span style={{ color: '#367588' }}>Email:</span> {userDetails.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h7" gutterBottom>
                <span style={{ color: '#367588' }}>Role:</span> {userDetails.role || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            <span style={{ color: '#367588' }}>Last updated:</span> {formatDate(userDetails.updatedAt)}
          </Typography>
        </CardContent>
      </Card>

      <Dialog open={showEditModal} onClose={handleCloseEditModal}>
        <DialogTitle color="primary">Edit Profile</DialogTitle>
        <DialogContent>
          {submissionError && <Alert severity="error">{submissionError}</Alert>}
          {submissionSuccess && <Alert severity="success">{submissionSuccess}</Alert>}
          <form onSubmit={handleFormSubmit}>
            <TextField
              fullWidth
              margin="normal"
              name="username"
              label="Username"
              value={formData.username}
              onChange={handleFormChange}
              autoComplete="username"
            />
            <TextField
              fullWidth
              margin="normal"
              name="email"
              label="Email"
              type="email"
              value={formData.email}
              onChange={handleFormChange}
              autoComplete="email"
            />
            {formData.password || formData.confirmPassword ? (
              <TextField
                fullWidth
                margin="normal"
                name="currentPassword"
                label="Current Password"
                type="password"
                value={formData.currentPassword}
                onChange={handleFormChange}
                autoComplete="current-password"
              />
            ) : null}
            <TextField
              fullWidth
              margin="normal"
              name="password"
              label="New Password"
              type="password"
              value={formData.password}
              onChange={handleFormChange}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EditIcon style={{ color: '#367588' }} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              margin="normal"
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              value={formData.confirmPassword}
              onChange={handleFormChange}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EditIcon style={{ color: '#367588' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <label htmlFor="profilePicture">
                <input
                  id="profilePicture"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<PhotoCamera />}
                >
                  Upload Picture
                </Button>
              </label>
              {formData.profilePictureBase64 && (
                <Avatar
                  src={`data:image/jpeg;base64,${formData.profilePictureBase64}`}
                  sx={{ width: 60, height: 60, ml: 2 }}
                />
              )}
            </Box>
            <Box sx={{ mt: 2 }}>
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Save Changes'}
              </Button>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal} color="error" disabled={isLoading}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfilePage;
