import React, { useState } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
  Typography,
  Container,
  Breadcrumbs
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const formatPhoneNumber = (phone) => {
  phone = phone.replace(/\D/g, '');

  // Ethiopian phone number validation
  if (phone.length === 12 && phone.startsWith('251') && (phone[3] === '9' || phone[3] === '7')) {
    return `+251${phone.slice(3)}`;
  }
  if (phone.length === 10 && (phone.startsWith('09') || phone.startsWith('07'))) {
    return `+251${phone.slice(1)}`;
  }
  if (phone.length === 9 && (phone.startsWith('9') || phone.startsWith('7'))) {
    return `+251${phone}`;
  }

  // Chinese phone number validation
  if (phone.length === 13 && phone.startsWith('86') && phone[2] === '1' && phone[3] >= '3' && phone[3] <= '9') {
    return `+86${phone.slice(2)}`;
  }
  if (phone.length === 11 && phone.startsWith('1') && phone[1] >= '3' && phone[1] <= '9') {
    return `+86${phone}`;
  }

  return null; // Invalid phone number
};

const AddSupplier = () => {
  const [newSupplier, setNewSupplier] = useState({
    name: '',
    phones: [''],
    email: '',
    address: ''
  });
  const [loading, setLoading] = useState(false);
  
  // Snackbar states
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'error' for errors

  const handleAddPhoneField = () => {
    // Check if all existing phone numbers are valid
    const allPhonesValid = newSupplier.phones.every(phone => formatPhoneNumber(phone.trim()) !== null);

    if (!allPhonesValid) {
      setSnackbarMessage('Please ensure all existing phone numbers are valid before adding another.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    // Check if there is at least one non-empty phone number
    const hasValidPhone = newSupplier.phones.some(phone => phone.trim() !== '');

    if (hasValidPhone) {
      setNewSupplier({ ...newSupplier, phones: [...newSupplier.phones, ''] });
    } else {
      setSnackbarMessage('Please provide at least one phone number before adding another.');
      setSnackbarSeverity('error'); // Set severity to error
      setSnackbarOpen(true);
    }
  };

  const handleRemovePhoneField = (index) => {
    setNewSupplier({
      ...newSupplier,
      phones: newSupplier.phones.filter((_, i) => i !== index)
    });
  };

  const handlePhoneChange = (index, value) => {
    const updatedPhones = [...newSupplier.phones];
    updatedPhones[index] = value;
    setNewSupplier({ ...newSupplier, phones: updatedPhones });
  };

  const handleAddSupplier = async () => {
    const { name, phones, email, address } = newSupplier;
    const trimmedName = name.trim();

    if (trimmedName === '') {
      setSnackbarMessage('Supplier name is required.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    const uniquePhones = [...new Set(phones.map(phone => phone.trim()))].filter(Boolean);
    const formattedPhones = uniquePhones.map(phone => formatPhoneNumber(phone)).filter(Boolean);
    
    if (formattedPhones.length === 0 || formattedPhones.length !== uniquePhones.length) {
      setSnackbarMessage('Please provide valid and unique phone numbers.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    try {
      await axiosInstance.post('/suppliers', {
        name: trimmedName,
        phones: formattedPhones,
        email: email.trim() || 'Not Provided',
        address: address.trim() || 'Not Provided'
      });
      setNewSupplier({ name: '', phones: [''], email: '', address: '' });
      setSnackbarMessage('Supplier added successfully.');
      setSnackbarSeverity('success'); // Set severity to success
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error adding supplier:', error);
      setSnackbarMessage(error.response?.data?.message || 'Error adding supplier.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    const trimmedName = newSupplier.name.trim();
    const hasPhones = newSupplier.phones.some(phone => phone.trim() !== '');
    return trimmedName !== '' && hasPhones; // Ensure name is not empty and at least one phone is provided
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h4" component="h2" color="primary" gutterBottom>
            Add Supplier 
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <RouterLink to="/admin/suppliers" style={{ textDecoration: 'none', color: 'inherit' }}>
              Supplier List
            </RouterLink>
            <Typography color="textPrimary">Add Supplier</Typography>
          </Breadcrumbs>
        </Box>

        <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
          <TextField
            label="Supplier Name"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            value={newSupplier.name}
            onChange={(e) => setNewSupplier({ ...newSupplier, name: e.target.value })}
            sx={{ mb: 2 }}
          />
          {newSupplier.phones.map((phone, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <TextField
                label={`Phone ${index + 1}`}
                variant="outlined"
                fullWidth
                required
                margin="normal"
                value={phone}
                placeholder="E.g., +2519XXXXXXXX or +861XXXXXXXXXX" // Placeholder with accepted formats
                onChange={(e) => handlePhoneChange(index, e.target.value)}
              />
              {newSupplier.phones.length > 1 && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleRemovePhoneField(index)}
                  sx={{ ml: 2 }}
                >
                  Remove
                </Button>
              )}
            </Box>
          ))}
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddPhoneField}
            sx={{ mb: 2 }}
          >
            Add Phone Field
          </Button>

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newSupplier.email}
            onChange={(e) => setNewSupplier({ ...newSupplier, email: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newSupplier.address}
            onChange={(e) => setNewSupplier({ ...newSupplier, address: e.target.value })}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddSupplier}
            sx={{ mt: 2 }}
            disabled={loading || !isFormValid()}
          >
            {loading ? 'Adding...' : 'Add Supplier'}
          </Button>
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Adjust position as needed
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default AddSupplier;
