import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { 
  TextField, 
  Button, 
  Container, 
  Box, 
  Typography, 
  Alert, 
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Card, 
  CardContent 
} from '@mui/material';

import PaymentInputFields from './utils/PaymentInputFields'; // Import the new component


// Define valid payment methods
const VALID_PAYMENT_METHODS = ['inCash', 'mobileBanking', 'Split'];

const statusColors = {
  'Paid': 'success.main',
  'Partial': 'warning.main',
  'Unpaid': 'error.main',
};

const EditAccountReceivable = () => {
  const { invoiceId } = useParams();
  const [accountReceivable, setAccountReceivable] = useState({
    paymentHistory: [] // Initialize paymentHistory as an empty array
  });
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentAmount, setPaymentAmount] = useState('');
  const [cashAmount, setCashAmount] = useState('');
  const [mobileBankingAmount, setMobileBankingAmount] = useState('');
  const [error, setError] = useState('');
  const [inputErrors, setInputErrors] = useState({});
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dialogPaymentMethod, setDialogPaymentMethod] = useState('');
  const [dialogPaymentAmount, setDialogPaymentAmount] = useState('');
  const [saleDetails, setSaleDetails] = useState(null);
  const [openSaleDialog, setOpenSaleDialog] = useState(false);


  const totalSalePrice = accountReceivable.totalSalePrice || 0;
  const totalReturnedValue = accountReceivable.totalReturnedValue || 0;
  const totalPaymentsReceived = accountReceivable.amount || 0;
  const effectiveTotalSalePrice = totalSalePrice - totalReturnedValue;
  const outstandingBalance = effectiveTotalSalePrice - totalPaymentsReceived;

  const fetchSaleDetails = async () => {
    try {
      const saleResponse = await axiosInstance.get(`/sales/invoice/${invoiceId}`);
      const saleData = saleResponse.data;

      if (Array.isArray(saleData)) {
        const updatedSaleData = await Promise.all(saleData.map(async (sale) => {
          if (sale.shop) {
            try {
              const shopResponse = await axiosInstance.get(`/shops/${sale.shop}`);
              sale.shopName = shopResponse.data.name;
            } catch (error) {
              sale.shopName = 'N/A'; // Default value if there's an error fetching shop details
            }
          } else {
            sale.shopName = 'N/A'; // Default value if shop ID is not available
          }
          return sale;
        }));

        setSaleDetails(updatedSaleData);
      } else {
        setError('Error fetching sale details');
      }
    } catch (error) {
      setError('Error fetching sale details');
    }
  };

  useEffect(() => {
    if (!invoiceId) {
      setError('Invalid invoice ID');
      return;
    }
    
    const fetchAccountReceivable = async () => {
      try {
        const response = await axiosInstance.get(`/account-receivables/${invoiceId}`);
        const data = response.data;
        setAccountReceivable({
          ...data,
          paymentHistory: data.paymentHistory || [] // Default to empty array if undefined
        });
      } catch (error) {
        setError('Error fetching account receivable details');
      } finally {
        setLoading(false);
      }
    };

    fetchAccountReceivable();
  }, [invoiceId]);

  const formatNumberWithCommas = (value) => {
    if (value === '') return '';
    const [integerPart, decimalPart] = value.split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  };

  const calculateTotalSalePrice = () => {
    return saleDetails.reduce((total, sale) => total + (sale.totalPrice || 0), 0);
  };

  const removeCommas = (numberString) => numberString.replace(/,/g, '');

  const validateInputs = () => {
    const errors = {};
  
    // Validate payment method
    if (!VALID_PAYMENT_METHODS.includes(paymentMethod)) {
      errors.paymentMethod = 'Invalid payment method';
    }
  
    // Calculate payment amount based on method
    let amount;
    if (paymentMethod === 'Split') {
      amount = parseFloat(removeCommas(cashAmount)) + parseFloat(removeCommas(mobileBankingAmount));
    } else {
      amount = parseFloat(removeCommas(paymentAmount));
    }
  
    // Validate payment amount
    if (isNaN(amount) || amount <= 0) {
      errors.amount = 'Payment amount must be greater than zero';
    } else if (amount > effectiveTotalSalePrice) {
      errors.amount = 'Payment amount cannot exceed the effective sale price';
    }
  
    return errors;
  };
  
  

  const handleSave = async () => {
    const errors = validateInputs();
  
    if (Object.keys(errors).length > 0) {
      setInputErrors(errors);
      return; // Stop execution if there are validation errors
    }
  
    setInputErrors({});
    setLoading(true);
    setError('');
    setSuccess('');
  
    try {
      await axiosInstance.post(`/account-receivables/${invoiceId}/payments`, {
        amount: paymentMethod === 'Split' 
          ? parseFloat(removeCommas(cashAmount)) + parseFloat(removeCommas(mobileBankingAmount)) 
          : parseFloat(removeCommas(paymentAmount)),
        paymentMethod,
        cashAmount: paymentMethod === 'Split' ? parseFloat(removeCommas(cashAmount)) : null,
        mobileBankingAmount: paymentMethod === 'Split' ? parseFloat(removeCommas(mobileBankingAmount)) : null
      });
  
      setSuccess('Payment recorded successfully');
      // Fetch the updated account receivable data after payment is recorded
      const response = await axiosInstance.get(`/account-receivables/${invoiceId}`);
      setAccountReceivable({
        ...response.data,
        paymentHistory: response.data.paymentHistory || [] // Default to empty array if undefined
      });
  
      // Reset payment inputs
      setPaymentMethod('');
      setPaymentAmount('');
      setCashAmount('');
      setMobileBankingAmount('');
    } catch (error) {
      console.error('Error recording payment:', error);
      setError('Error recording payment: ' + (error.response?.data?.message || 'Unknown error'));
    } finally {
      setLoading(false);
    }
  };
  

  const handleConfirm = () => {
    setOpenConfirmDialog(false);
    handleSave();
  };

  useEffect(() => {
    if (error || success) {
      const timer = setTimeout(() => {
        setError('');
        setSuccess('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error, success]);

  const handleOpenConfirmDialog = () => {
    const errors = validateInputs();

    if (Object.keys(errors).length > 0) {
      setInputErrors(errors);
      return;
    }

    setInputErrors({});

    let amount;
    if (paymentMethod === 'Split') {
      amount = parseFloat(removeCommas(cashAmount)) + parseFloat(removeCommas(mobileBankingAmount));
    } else {
      amount = parseFloat(removeCommas(paymentAmount));
    }
    setDialogPaymentMethod(paymentMethod);
    setDialogPaymentAmount(formatNumberWithCommas(amount.toFixed(2)));
    setOpenConfirmDialog(true);
  };


  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" color="primary" gutterBottom>
          Account Receivable - For Sale
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Card sx={{ p: 4 }}>
            <CardContent>
              <Typography variant="h6" color="primary" gutterBottom>
                Receivable Details
              </Typography>
              
              <Box sx={{ mb: 3 }}>
                {/* Receivable details display */}
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Invoice ID:</strong> {accountReceivable?.invoiceId} <Button 
                    variant="outlined" color="primary" 
                    onClick={() => {
                        fetchSaleDetails();
                        setOpenSaleDialog(true);
                    }}
                    >
                    View Sale Details
                    </Button>
                    </Typography>
                </Box>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Customer Name: </strong> {accountReceivable?.customerName}</Typography>
                </Box>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Total Sale Price: </strong> {formatNumberWithCommas(totalSalePrice.toFixed(2))} Br.</Typography>
                </Box>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Total Returned Value: </strong> {formatNumberWithCommas(totalReturnedValue.toFixed(2))} Br.</Typography>
                </Box>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Effective Sale Price: </strong> {formatNumberWithCommas(effectiveTotalSalePrice.toFixed(2))} Br.</Typography>
                </Box>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Total Payments Received: </strong> {formatNumberWithCommas(totalPaymentsReceived.toFixed(2))} Br.</Typography>
                </Box>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Outstanding Balance: </strong> {formatNumberWithCommas(outstandingBalance.toFixed(2))} Br.</Typography>
                </Box>
                <Box sx={{ mb: 2, p: 2, border: '1px solid', borderColor: 'divider' }}>
                  <Typography variant="body1"><strong>Current Payment Status: </strong> 
                    <Typography 
                      component="span" 
                      sx={{ 
                        color: statusColors[accountReceivable?.paymentStatus] || 'text.primary', 
                        fontWeight: 'bold'
                      }}
                    >
                      {accountReceivable?.paymentStatus}
                    </Typography>
                  </Typography>
                </Box>
              </Box>

              <PaymentInputFields
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              paymentAmount={paymentAmount}
              setPaymentAmount={setPaymentAmount}
              cashAmount={cashAmount}
              setCashAmount={setCashAmount}
              mobileBankingAmount={mobileBankingAmount}
              setMobileBankingAmount={setMobileBankingAmount}
              inputErrors={inputErrors}
              setInputErrors={setInputErrors}
              handleOpenConfirmDialog={handleOpenConfirmDialog}
              loading={loading}
            />

              {/* Payment History Table */}
              <Typography variant="h6" color="primary" gutterBottom>
                Payment History
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Amount</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Payment Method</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Cash Amount</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Mobile Banking Amount</Typography></TableCell>
                      <TableCell><Typography variant="subtitle1" fontWeight="bold">Date</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(accountReceivable?.paymentHistory || []).map((payment, index) => (
                      <TableRow key={index}>
                        <TableCell>{payment.amount ? formatNumberWithCommas(payment.amount.toFixed(2)) + ' Br.' : 'N/A'}</TableCell>
                        <TableCell>{payment.paymentMethod}</TableCell>
                        <TableCell>{payment.cashAmount ? formatNumberWithCommas(payment.cashAmount.toFixed(2)) + ' Br.' : 'N/A'}</TableCell>
                        <TableCell>{payment.mobileBankingAmount ? formatNumberWithCommas(payment.mobileBankingAmount.toFixed(2)) + ' Br.' : 'N/A'}</TableCell>
                        <TableCell>{new Date(payment.date).toLocaleDateString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        )}
      </Box>

      <Dialog
      open={openSaleDialog}
      onClose={() => setOpenSaleDialog(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Sale Details</DialogTitle>
      <DialogContent>
        {saleDetails && saleDetails.length > 0 ? (
          <>
            {/* Display Shop Name and Sale Date at the Top */}
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              {`Shop: ${saleDetails.length > 0 ? saleDetails[0].shopName || 'N/A' : 'N/A'}`}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {`Customer Name: ${saleDetails.length > 0 ? saleDetails[0].customerName || 'N/A' : 'N/A'}`}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {`Created By: ${saleDetails.length > 0 ? saleDetails[0].createdBy || 'N/A' : 'N/A'}`}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {`Sale Date: ${saleDetails.length > 0 ? new Date(saleDetails[0].saleDate).toLocaleDateString() : 'N/A'}`}
            </Typography>

            {/* Sales Table */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant="subtitle1" fontWeight="bold">Product</Typography></TableCell>
                    <TableCell><Typography variant="subtitle1" fontWeight="bold">Description</Typography></TableCell>
                    <TableCell><Typography variant="subtitle1" fontWeight="bold">Quantity</Typography></TableCell>
                    <TableCell><Typography variant="subtitle1" fontWeight="bold">Unit Price</Typography></TableCell>
                    <TableCell><Typography variant="subtitle1" fontWeight="bold">Total Price</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {saleDetails.map((sale) => (
                    <TableRow key={sale._id}>
                      <TableCell>{sale.product?.name || 'N/A'}</TableCell>
                      <TableCell>{sale.product?.description || 'N/A'}</TableCell>
                      <TableCell>{sale.quantity !== undefined ? sale.quantity : 'N/A'}</TableCell>
                      <TableCell>{sale.unitPrice !== undefined ? formatNumberWithCommas(sale.unitPrice.toFixed(2)) + ' Br.' : 'N/A'}</TableCell>
                      <TableCell>{sale.totalPrice !== undefined ? formatNumberWithCommas(sale.totalPrice.toFixed(2)) + ' Br.' : 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Total Sale Price */}
            <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
              Total Sale Price: {formatNumberWithCommas(calculateTotalSalePrice().toFixed(2))} Br.
            </Typography>
          </>
        ) : (
          <Typography variant="body1">No sale details available.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenSaleDialog(false)} color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>









      {/* Confirm Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirm Payment</DialogTitle>
        <DialogContent>
          <Typography variant="body1"><strong>Payment Method:</strong> {dialogPaymentMethod}</Typography>
          <Typography variant="body1"><strong>Amount:</strong> {dialogPaymentAmount} Br.</Typography>
          <Typography variant="body1"><strong>Are you sure you want to record this payment?</strong></Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default EditAccountReceivable;
