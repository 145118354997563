import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TextField, Button, Alert } from '@mui/material';
import { axiosInstance } from '../../Axios/AxiosInstance';

const ShopSales = () => {
  const { shopId } = useParams();
  const navigate = useNavigate();
  const [sales, setSales] = useState([]);
  const [shopName, setShopName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  // Search states
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [createdBy, setCreatedBy] = useState('');

  useEffect(() => {
    const fetchSalesAndShopName = async () => {
      try {
        // Fetch sales data with pagination and search parameters
        const salesResponse = await axiosInstance.get(`/sales/shop/${shopId}`, {
          params: {
            page: page + 1, // API may expect 1-based page index
            limit: rowsPerPage,
            startDate,
            endDate,
            customerName,
            createdBy
          }
        });
        console.log('Sales data response:', salesResponse.data); // Debug log
        setSales(salesResponse.data.sales);
        setTotalRows(salesResponse.data.total);

        // Fetch shop details
        const shopResponse = await axiosInstance.get(`/shops/${shopId}`);
        console.log('Shop data response:', shopResponse.data); // Debug log
        setShopName(shopResponse.data.name);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching sales and shop details:', err);
        setError(err.response?.status === 404 ? 'Shop not found.' : 'An error occurred. Please try again.');
        setLoading(false);
      }
    };

    fetchSalesAndShopName();
  }, [shopId, page, rowsPerPage, startDate, endDate, customerName, createdBy]);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDetails = (invoiceId) => {
    navigate(`/admin/shop/${shopId}/sales/details/${invoiceId}`);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        <span style={{ color: '#367588' }}>Sales for Shop:</span> {shopName}
      </Typography>

      {/* Search Filters */}
      <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          sx={{ width: '150px' }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          sx={{ width: '150px' }}
        />
        <TextField
          label="Customer Name"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          sx={{ width: '200px' }}
        />
        <TextField
          label="Created By"
          value={createdBy}
          onChange={(e) => setCreatedBy(e.target.value)}
          sx={{ width: '200px' }}
        />
      </Box>

      {/* Sales Table */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Invoice ID</TableCell>
            <TableCell>Number of Products</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Total Price</TableCell>
            <TableCell>Payment Method</TableCell>
            <TableCell>Payment Status</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Sale Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <CircularProgress />
                <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
              </TableCell>
            </TableRow>
          ) : sales.length === 0 ? (
            <TableRow>
              <TableCell colSpan={10} align="center">
                <Typography>No sales found.</Typography>
              </TableCell>
            </TableRow>
          ) : (
            sales.map((sale) => (
              <TableRow key={sale._id}>
                <TableCell>{sale.invoiceId}</TableCell>
                <TableCell>{sale.numberOfProducts || 1}</TableCell>
                <TableCell>{sale.quantity}</TableCell>
                <TableCell>{sale.totalPrice}</TableCell>
                <TableCell>{sale.paymentMethod}</TableCell>
                <TableCell>{sale.paymentStatus}</TableCell>
                <TableCell>{sale.customerName}</TableCell>
                <TableCell>{sale.createdBy}</TableCell>
                <TableCell>{new Date(sale.saleDate).toLocaleDateString()}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleViewDetails(sale.invoiceId)}
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      {/* Pagination */}
      {totalRows > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default ShopSales;
