import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { Typography, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, Alert, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import '../../assets/styles/InvoiceDetail.css'; // Ensure you have styles for invoice detail

// Format number with commas
const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const SalesDetail = () => {
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const [saleDetails, setSaleDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSaleDetails = async () => {
      try {
        const response = await axiosInstance.get(`/sales/invoice/${invoiceId}`);
        setSaleDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sale details:', error);
        setError('Error fetching sale details.');
        setLoading(false);
      }
    };

    fetchSaleDetails();
  }, [invoiceId]);

  if (loading) return <Box className="invoice-container"><CircularProgress /></Box>;
  if (error) return <Box className="invoice-container"><Alert severity="error">{error}</Alert></Box>;
  if (saleDetails.length === 0) return <Box className="invoice-container"><Typography>No details available.</Typography></Box>;

  const invoiceInfo = saleDetails[0];
  const subtotal = saleDetails.reduce((total, sale) => total + sale.totalPrice, 0).toFixed(2);
  const grandTotal = parseFloat(subtotal);

  return (
    <Box sx={{ padding: '20px' }}>
      <Box className="invoice-container" sx={{ width: '80%', maxWidth: '1200px' }}>
        <Box className="invoice-header-container">
          <Typography variant="h4" className="invoice-title">INVOICE</Typography>
          {invoiceInfo.voided && <Typography color="error"><strong>VOIDED</strong></Typography>}
          <Box className="invoice-header-details">
            <Typography className="invoice-customer"><strong>Invoice To:</strong> {invoiceInfo.customerName}</Typography>
          </Box>
        </Box>

        <Box className="invoice-header" sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box className="invoice-header-left">
            <Typography><strong>Invoice ID:</strong> {invoiceInfo.invoiceId}</Typography>
            <Typography><strong>Created By:</strong> {invoiceInfo.createdBy}</Typography>
            <Typography><strong>Date:</strong> {new Date(invoiceInfo.saleDate).toLocaleDateString()}</Typography>
          </Box>
          <Box className="invoice-header-right">
            <Typography><strong>Payment Method:</strong> {invoiceInfo.paymentMethod}</Typography>
            {invoiceInfo.paymentMethod === 'mobile_banking' && (
              <Typography><strong>Transaction ID:</strong> {invoiceInfo.transactionId || 'N/A'}</Typography>
            )}
            {invoiceInfo.paymentMethod === 'split_payment' && (
              <>
                <Typography><strong>In Cash:</strong> {invoiceInfo.cashAmount ? formatNumberWithCommas(invoiceInfo.cashAmount.toFixed(2)) : 'N/A'} Br.</Typography>
                <Typography><strong>In Mobile Banking:</strong> {invoiceInfo.mobileBankingAmount ? formatNumberWithCommas(invoiceInfo.mobileBankingAmount.toFixed(2)) : 'N/A'} Br.</Typography>
              </>
            )}
            {invoiceInfo.paymentMethod === 'credit' && (
              <Typography><strong>Payment Status:</strong> {invoiceInfo.paymentStatus || 'N/A'}</Typography>
            )}
          </Box>
        </Box>

        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell>Total Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {saleDetails.map((sale) => (
                <TableRow key={sale._id}>
                  <TableCell>{sale.product.name}</TableCell>
                  <TableCell>{sale.product.description || 'N/A'}</TableCell>
                  <TableCell>{sale.quantity}</TableCell>
                  <TableCell>{sale.unitPrice ? formatNumberWithCommas(sale.unitPrice.toFixed(2)) : 'N/A'}</TableCell>
                  <TableCell>{sale.totalPrice ? formatNumberWithCommas(sale.totalPrice.toFixed(2)) : 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box className="invoice-footer" sx={{ mb: 3 }}>
          <Box className="invoice-footer-line" sx={{ borderBottom: '1px solid #000', mb: 2 }}></Box>
          <Box className="invoice-footer-details" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box className="invoice-footer-detail">
              <Typography className="subtotal"><strong>Subtotal:</strong> {formatNumberWithCommas(subtotal)} Br.</Typography>
              <Typography className="grand-total"><strong>Grand Total:</strong> {formatNumberWithCommas(grandTotal.toFixed(2))} Br.</Typography>
            </Box>
          </Box>
        </Box>

      </Box>
    </Box>
  );
};

export default SalesDetail;
