import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Snackbar
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { useAuth } from '../auth/AuthContext';
import { useUsers } from '../hooks/useUsers';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Skeleton from '@mui/material/Skeleton';
import { debounce } from 'lodash';

const CustomButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const Avatar = styled('div')(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 50,
  height: 50,
  borderRadius: '50%',
  backgroundColor: color,
  color: '#fff',
  fontSize: 20,
  fontWeight: 'bold',
}));

const getInitials = (name) => {
  if (!name) return '';
  const nameArray = name.split(' ');
  return nameArray.map(n => n[0]).join('');
};

const UserList = () => {
  const { users, fetchUsers, editUser, deleteUser, lockUser, unlockUser, loading, error } = useUsers();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({ username: '', email: '', role: '', shop: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [shops, setShops] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [openLockDialog, setOpenLockDialog] = useState(false);
  const [lockAction, setLockAction] = useState(null);  // 'lock' or 'unlock'
  const [userToLock, setUserToLock] = useState(null);
  const [lockLoading, setLockLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);


  const { role: signedInUserRole, userId: signedInUserId } = useAuth();

  const fetchShops = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/shops');
      setShops(response.data);
    } catch (error) {
      console.error('Error fetching shops:', error);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
    fetchShops();
  }, [fetchUsers, fetchShops]);

  const debouncedSearch = useCallback(
    debounce((term) => {
      setSearchTerm(term);
    }, 300), []
  );

  const memoizedFilteredUsers = useMemo(() => {
    const loggedInAdmin = users.find(user => user._id === signedInUserId); // Find the logged-in user
    const otherUsers = users
      .filter(user =>
        user._id !== signedInUserId && // Exclude the logged-in user
        (user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    
    return loggedInAdmin
      ? [loggedInAdmin, ...otherUsers] // Place the logged-in user at the top
      : otherUsers;
  }, [searchTerm, users, signedInUserId]);
  

  const handleEditClick = useCallback((user) => {
    setSelectedUser(user);
    setFormData({
      username: user.username,
      email: user.email,
      role: user.role,
      shop: user.shop ? user.shop._id : ''
    });
    setShowEditModal(true);
  }, []);

  const confirmLockAction = useCallback((action, userId) => {
    setLockAction(action);
    setUserToLock(userId);
    setOpenLockDialog(true);
  }, []);

  const handleConfirmLock = useCallback(async () => {
    setLockLoading(true); // Start loading
    try {
      if (lockAction === 'lock') {
        await lockUser(userToLock);
        setSnackbar({ open: true, message: 'User locked successfully', severity: 'success' });
      } else {
        await unlockUser(userToLock);
        setSnackbar({ open: true, message: 'User unlocked successfully', severity: 'success' });
      }
      fetchUsers();  // Refresh user list after locking/unlocking
    } catch (error) {
      const errorMsg = error.response?.data?.message || `Error ${lockAction === 'lock' ? 'locking' : 'unlocking'} user`;
      setSnackbar({ open: true, message: errorMsg, severity: 'error' });
    } finally {
      setLockLoading(false); // Stop loading
      setOpenLockDialog(false);
    }
  }, [lockAction, userToLock, lockUser, unlockUser, fetchUsers]);
  
  const handleDeleteClick = useCallback((userId) => {
    if (userId === signedInUserId) {
      alert('You cannot delete your own account.');
      return;
    }
    setDeleteUserId(userId);
    setOpenDeleteDialog(true);
  }, [signedInUserId]);

  const handleDeleteUser = useCallback(async () => {
    try {
      await deleteUser(deleteUserId);
      setSnackbar({ open: true, message: 'User deleted successfully', severity: 'success' });
      fetchUsers(); // Refresh user list after deletion
    } catch (error) {
      const errorMsg = error.response?.data?.message || 'Error deleting user';
      setSnackbar({ open: true, message: errorMsg, severity: 'error' });
    }
    handleCloseDeleteDialog();
  }, [deleteUserId, deleteUser, fetchUsers]);
  
  
  const handleSearchChange = useCallback((e) => {
    const value = e.target.value;
    debouncedSearch(value);
  }, [debouncedSearch]);

  const handleFormChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  }, []);

  const handleFormSubmit = useCallback(async (e) => {
    e.preventDefault();
    setEditLoading(true);

    try {
      if (selectedUser && selectedUser._id) {
        const updatedData = {
          ...formData,
          username: (selectedUser._id === signedInUserId) ? formData.username : selectedUser.username,
          email: (selectedUser._id === signedInUserId) ? formData.email : selectedUser.email,
          role: (selectedUser._id === signedInUserId) ? selectedUser.role : formData.role,
          shop: formData.shop // Ensure updated shop is included
        };
        await editUser(selectedUser._id, updatedData);
  
        // Instead of setUsers, refetch the users
        await fetchUsers(); // Fetch updated list from the backend
        setShowEditModal(false);
        setSnackbar({ open: true, message: 'User updated successfully', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'User not found', severity: 'error' });
      }
    } catch (error) {
      const errorMsg = error.response?.data?.message || 'Error updating user';
      setSnackbar({ open: true, message: errorMsg, severity: 'error' });
    } finally {
      setEditLoading(false); // Stop the loading state
    }
  }, [formData, selectedUser, editUser, signedInUserId, fetchUsers]);
  
  

  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteUserId(null);
    setOpenDeleteDialog(false);
  }, []);

  const handleSnackbarClose = useCallback(() => {
    setSnackbar({ ...snackbar, open: false });
  }, [snackbar]);


  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" color="primary">Users List</Typography>
        <CustomButton component={Link} to="/admin/users/add" variant="contained" color="primary">
          Add User
        </CustomButton>
      </Box>
  
      <Box sx={{ mb: 3 }}>
        <TextField
          label="Search by username or email"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
        />
      </Box>
  <TableContainer>
      <Table>
  <TableHead>
    <TableRow>
      <TableCell>Profile</TableCell>
      <TableCell>Username</TableCell>
      <TableCell>Email</TableCell>
      <TableCell>Role</TableCell>
      <TableCell>Shop</TableCell>
      <TableCell>Actions</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
  {loading ? (
    [...Array(5)].map((_, index) => (
      <TableRow key={index}>
        {[...Array(6)].map((_, cellIndex) => (
          <TableCell key={cellIndex}>
            <Skeleton variant="rectangular" height={40} />
          </TableCell>
        ))}
      </TableRow>
    ))
  ) : error ? (
    <TableRow>
      <TableCell colSpan={6}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px', // Adjust height as needed
          }}
        >
          <Typography color="error">{error}</Typography>
        </Box>
      </TableCell>
    </TableRow>
  ) : 
    memoizedFilteredUsers.map(user => (
      <TableRow key={user._id}>
        <TableCell>
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      {user.profilePicture ? (
        <img
          src={`data:image/jpeg;base64,${user.profilePicture}`}
          alt={`${user.username}'s profile`}
          style={{ width: 50, height: 50, borderRadius: '50%', objectFit: 'cover' }}
        />
      ) : (
        <Avatar sx={{ width: 50, height: 50, backgroundColor: '#367588' }}>
          {getInitials(user.username)}
        </Avatar>
      )}
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: 16,
          height: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'background.paper',
          borderRadius: '50%',
          border: '2px solid white',
        }}
      >
        <Brightness1Icon
          style={{
            color: user.isOnline ? '#39e600' : '#ff1a1a',
            fontSize: '12px',
          }}
        />
      </Box>
    </Box>
  </TableCell>
        <TableCell>{user.username}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.role}</TableCell>
        <TableCell>{user.shop ? user.shop.name : 'N/A'}</TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <CustomButton
              onClick={() => handleEditClick(user)}
              color="primary"
              disabled={signedInUserRole !== 'Admin' && signedInUserRole !== user.role}
            >
              <EditIcon /> Edit
            </CustomButton>
            {signedInUserRole === 'Admin' && user._id !== signedInUserId && (
              <>
                <CustomButton
                  onClick={() => confirmLockAction('lock', user._id)}
                  color="warning"
                  disabled={user.isDeactivated || user.locked || lockLoading}
                >
                  {lockLoading && lockAction === 'lock' && userToLock === user._id ? (
                    <CircularProgress size={20} />
                  ) : (
                    <>
                      <LockIcon /> Lock
                    </>
                  )}
                </CustomButton>
                <CustomButton
                  onClick={() => confirmLockAction('unlock', user._id)}
                  color="success"
                  disabled={!user.isDeactivated && !user.locked || lockLoading}
                >
                  {lockLoading && lockAction === 'unlock' && userToLock === user._id ? (
                    <CircularProgress size={20} />
                  ) : (
                    <>
                      <LockOpenIcon /> Unlock
                    </>
                  )}
                </CustomButton>
                <CustomButton
                  onClick={() => handleDeleteClick(user._id)}
                  color="error"
                >
                  <DeleteIcon /> Delete
                </CustomButton>
              </>
            )}
          </Box>
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>

      </TableContainer>
  
      <Dialog open={showEditModal} onClose={() => setShowEditModal(false)} fullWidth maxWidth="sm">
        <DialogTitle color={'#367588'}>Edit User</DialogTitle>
        <DialogContent>
          <form onSubmit={handleFormSubmit}>
            <TextField
              name="username"
              label="Username"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.username}
              onChange={handleFormChange}
              required
              disabled={selectedUser && selectedUser._id !== signedInUserId}
            />
            <TextField
              name="email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={formData.email}
              onChange={handleFormChange}
              required
              disabled={selectedUser && selectedUser._id !== signedInUserId}
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Role</InputLabel>
              <Select
                name="role"
                value={formData.role}
                onChange={handleFormChange}
                label="Role"
                disabled={selectedUser && selectedUser._id === signedInUserId}
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Store-Manager">Store Manager</MenuItem>
                <MenuItem value="Staff">Staff</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Shop</InputLabel>
              <Select
                name="shop"
                value={formData.shop}
                onChange={handleFormChange}
                label="Shop"
                // Disable shop selection if the role is Admin or Store Manager
                disabled={['Admin', 'Store-Manager'].includes(formData.role)}
              >
                {shops.map(shop => (
                  <MenuItem key={shop._id} value={shop._id}>
                    {shop.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogActions>
              <CustomButton onClick={() => setShowEditModal(false)} color="error">Cancel</CustomButton>
              <CustomButton type="submit" variant="contained" color="primary" disabled={editLoading} >
                  {editLoading ? <CircularProgress size={24} /> : 'Save'}
              </CustomButton>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
  
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle className="text-danger">Confirm User Deletion</DialogTitle>
        <DialogContent>
          {deleteUserId && (
            <>
              <Typography variant="body1">
                Please confirm if you wish to delete the following user:
              </Typography>
              {users
                .filter(user => user._id === deleteUserId)
                .map(user => (
                  <Box key={user._id} sx={{ mt: 2 }}>
                    <Typography><strong>Username:</strong> {user.username}</Typography>
                    <Typography><strong>Email:</strong> {user.email}</Typography>
                    <Typography><strong>Role:</strong> {user.role}</Typography>
                    <Typography><strong>Shop:</strong> {user.shop ? user.shop.name : 'N/A'}</Typography>
                  </Box>
                ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseDeleteDialog} color="primary" variant="outlined">Cancel</CustomButton>
          <CustomButton onClick={handleDeleteUser} variant="contained" color="error">Delete</CustomButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openLockDialog} onClose={() => setOpenLockDialog(false)}>
  <DialogTitle>
    Confirm {lockAction === 'lock' ? 'Lock' : 'Unlock'} User
  </DialogTitle>
  <DialogContent>
    <Typography>
      Are you sure you want to {lockAction === 'lock' ? 'lock' : 'unlock'} the following user?
    </Typography>
    
    {/* Check if `userToLock` and `users` are valid */}
    {userToLock && users.length > 0 && (
      <>
        {/* Filter the user by ID and display their details */}
        {users
          .filter(user => user._id === userToLock)
          .map(user => (
            <Box key={user._id} sx={{ mt: 2 }}>
              <Typography><strong>Username:</strong> {user.username}</Typography>
              <Typography><strong>Email:</strong> {user.email}</Typography>
              <Typography><strong>Role:</strong> {user.role}</Typography>
              <Typography><strong>Shop:</strong> {user.shop ? user.shop.name : 'N/A'}</Typography>
            </Box>
          ))}
      </>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenLockDialog(false)}>Cancel</Button>
    <Button onClick={handleConfirmLock} color={lockAction === 'lock' ? 'warning' : 'success'}>
      Confirm
    </Button>
  </DialogActions>
</Dialog>


{snackbar && (
  <Snackbar
    open={snackbar.open}
    autoHideDuration={6000}
    onClose={handleSnackbarClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  >
    <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
      {snackbar.message}
    </Alert>
  </Snackbar>
)}

    </Box>
  );
  
};

export default UserList;
