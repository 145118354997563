import React, { useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

const PaymentInputFields = ({
  paymentMethod,
  setPaymentMethod,
  paymentAmount,
  setPaymentAmount,
  cashAmount,
  setCashAmount,
  mobileBankingAmount,
  setMobileBankingAmount,
  inputErrors,
  setInputErrors,  // Assuming you have a state setter for errors
  handleOpenConfirmDialog,
  loading
}) => {

  const VALID_PAYMENT_METHODS = ['inCash', 'mobileBanking', 'Split'];

  // Calculate total payment for split payments
  const totalPayment = parseFloat(cashAmount || 0) + parseFloat(mobileBankingAmount || 0);

  // Clear errors when the user starts typing
  const handleInputChange = (setter, errorKey) => (event) => {
    const value = event.target.value.replace(/,/g, ''); // Remove commas for processing
    setter(value);

    // Clear specific error when user starts typing
    setInputErrors((prevErrors) => ({ ...prevErrors, [errorKey]: '' }));
  };

  const handlePaymentMethodChange = (event) => {
    const selectedPaymentMethod = event.target.value;
    setPaymentMethod(selectedPaymentMethod);
    
    // Clear payment method error on change
    setInputErrors((prevErrors) => ({ ...prevErrors, paymentMethod: '' }));
  };

  return (
    <Box>
      {/* Payment Method Selection */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom>Payment Method</Typography>
        <select
          value={paymentMethod}
          onChange={handlePaymentMethodChange}
          style={{ width: '100%', padding: '8px', borderRadius: '4px', borderColor: 'divider' }}
        >
          <option value="" disabled>Select Payment Method</option>
          <option value="inCash">Cash</option>
          <option value="mobileBanking">Mobile Banking</option>
          <option value="Split">Split</option>
        </select>
        {inputErrors.paymentMethod && <Typography color="error">{inputErrors.paymentMethod}</Typography>}
      </Box>

      {paymentMethod === 'Split' ? (
        <>
          <TextField
            type="text"
            label="Cash Amount"
            value={cashAmount}
            onChange={handleInputChange(setCashAmount, 'amount')}
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            fullWidth
            error={!!inputErrors.amount}
            helperText={inputErrors.amount}
          />
          <TextField
            type="text"
            label="Mobile Banking Amount"
            value={mobileBankingAmount}
            onChange={handleInputChange(setMobileBankingAmount, 'amount')}
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            fullWidth
            error={!!inputErrors.amount}
            helperText={inputErrors.amount}
          />

          {/* Display the total payment for Split method */}
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            Total Payment: {totalPayment.toFixed(2)} {/* Formats the total to 2 decimal places */}
          </Typography>
        </>
      ) : (
        <TextField
          type="text"
          label="Payment Amount"
          value={paymentAmount}
          onChange={handleInputChange(setPaymentAmount, 'amount')}
          variant="outlined"
          size="small"
          sx={{ mb: 2 }}
          fullWidth
          error={!!inputErrors.amount}
          helperText={inputErrors.amount}
        />
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenConfirmDialog}
        disabled={loading || !paymentMethod}
      >
        Record Payment
      </Button>
    </Box>
  );
};

export default PaymentInputFields;
