import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Use for navigation
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert
} from '@mui/material';
import { axiosInstance } from '../../Axios/AxiosInstance';

const QuotesPage = () => {
    const [quotes, setQuotes] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [openDialog, setOpenDialog] = useState(false);
    const [quoteToDelete, setQuoteToDelete] = useState(null);

    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        const fetchQuotes = async () => {
            try {
                const response = await axiosInstance.get('/quotes'); // Replace with your actual endpoint
                setQuotes(response.data);
            } catch (error) {
                console.error('Error fetching quotes:', error);
            }
        };

        fetchQuotes();
    }, []);

    // Navigate to drafts page
    const handleNavigateToDrafts = () => {
        navigate('/admin/drafts'); // Navigate to the drafts page
    };

    const handleViewDetails = (quoteId) => {
        navigate(`/admin/quote/detail/${quoteId}`); // Navigate to the quote detail page with quoteId as the parameter
    };

    // Handle open dialog for deletion
    const handleDeleteClick = (quote) => {
        setQuoteToDelete(quote);
        setOpenDialog(true);
    };

    // Delete quote from API
    const handleDeleteQuote = async () => {
        if (quoteToDelete) {
            try {
                await axiosInstance.delete(`/quotes/${quoteToDelete._id}`);
                setQuotes(quotes.filter((quote) => quote._id !== quoteToDelete._id)); // Remove from UI
                setSnackbarMessage('Quote deleted successfully');
                setSnackbarSeverity('success');
            } catch (error) {
                console.error('Error deleting quote:', error);
                setSnackbarMessage('Failed to delete quote');
                setSnackbarSeverity('error');
            } finally {
                setSnackbarOpen(true);
                setOpenDialog(false);
            }
        }
    };

    // Close Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    // Close Delete Dialog
    const handleDialogClose = () => {
        setOpenDialog(false);
        setQuoteToDelete(null);
    };

    // Filter out drafts (we no longer toggle showing drafts)
    const filteredQuotes = quotes.filter((quote) => !quote.isDraft);

    return (
        <Box p={3} sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            <Typography variant="h4" gutterBottom color="primary">
                All Quotes
            </Typography>

            {/* Button now navigates to the Drafts Page */}
            <Button variant="contained" color="primary" onClick={handleNavigateToDrafts}>
                Go To Drafts
            </Button>

            <TableContainer component={Paper} sx={{ borderRadius: 2, mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6">Number of Products</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">Created At</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">Actions</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredQuotes.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <Typography variant="body1" color="textSecondary">
                                        No quotes available
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            filteredQuotes.map((quote) => (
                                <TableRow key={quote._id}>
                                    <TableCell>{quote.products.length}</TableCell>
                                    <TableCell>{new Date(quote.createdAt).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleViewDetails(quote._id)}
                                        >
                                            View Details
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() => handleDeleteClick(quote)} // Delete button
                                            sx={{ ml: 2 }}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>Delete Quote</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this quote? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant='outlined' color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteQuote} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for notifications */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default QuotesPage;
