// src/components/customers/CustomerDetail.js

import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, Box, Grid, Button } from '@mui/material';
import SaleCard from './SaleCard';
import RatingStars from './RatingStars';

// Format number with commas
const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// Group sales by invoice ID
const groupSalesByInvoice = (sales, productNames) => {
  return sales.reduce((acc, sale) => {
    if (!acc[sale.invoiceId]) {
      acc[sale.invoiceId] = {
        invoiceId: sale.invoiceId,
        products: [],
        totalQuantity: 0,
        totalAmount: 0,
        paymentMethod: sale.paymentMethod || '',
        paymentStatus: sale.paymentStatus || '',
        cashAmount: sale.paymentMethod === 'split_payment' ? (sale.cashAmount || 0) : 0,
        mobileBankingAmount: sale.paymentMethod === 'split_payment' ? (sale.mobileBankingAmount || 0) : 0,
      };
    }
    
    const currentSale = acc[sale.invoiceId];
    
    currentSale.products.push({
      product: productNames[sale.product] || 'Unknown Product',
      quantity: sale.quantity || 0,
      unitPrice: sale.unitPrice || 0,
      totalPrice: sale.totalPrice || 0,
    });
    
    currentSale.totalQuantity += sale.quantity || 0;
    currentSale.totalAmount += sale.totalPrice || 0;
    
    return acc;
  }, {});
};

const CustomerDetail = () => {
  const { name } = useParams();
  const [customerSales, setCustomerSales] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productNames, setProductNames] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const salesPerPage = 6; // Number of sales per page

  useEffect(() => {
    const fetchCustomerSales = async () => {
      try {
        setLoading(true);

        const response = await axiosInstance.get('/sales/customer-sales', {
          params: { customerName: name }
        });
        const { customer, sales } = response.data;

        const productIds = [...new Set(sales.map(sale => sale.product))];
        const productRequests = productIds.map(id => axiosInstance.get(`/products/${id}`));

        const productResponses = await Promise.all(productRequests);
        const names = productResponses.reduce((acc, response) => {
          acc[response.data._id] = response.data.name;
          return acc;
        }, {});

        const groupedSales = groupSalesByInvoice(sales, names);
        setCustomer(customer);
        setCustomerSales(Object.values(groupedSales));
        setProductNames(names);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError('Error fetching customer sales');
        console.error('Error fetching customer sales:', error);
      }
    };

    fetchCustomerSales();
  }, [name]);

  // Get current sales for the page
  const indexOfLastSale = currentPage * salesPerPage;
  const indexOfFirstSale = indexOfLastSale - salesPerPage;
  const currentSales = customerSales.slice(indexOfFirstSale, indexOfLastSale);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" sx={{ backgroundColor: '#f5f5f5' }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" align="center" sx={{ marginTop: 4 }}>
        {error}
      </Typography>
    );
  }

  return (
    <Box padding={4} sx={{ backgroundColor: '#fafafa' }}>
      <Typography variant="h4" gutterBottom sx={{ marginBottom: 2}}>
      <span style={{ color: '#367588' }} > Details for:</span> {name}
      </Typography>
      {customerSales.length > 0 && customer && (
        <Box mb={4}>
          <Typography variant="h5" color="primary" gutterBottom>Customer Rating</Typography>
          <RatingStars rating={customer.rating} />
        </Box>
      )}
      
      {/* Display total number of invoices */}
      <Typography variant="h6" gutterBottom>
      <span style={{ color: '#367588' }} >Total Invoices:</span> {formatNumberWithCommas(customerSales.length)}
      </Typography>
      
      <Typography variant="h5" color="primary" gutterBottom>Sales Information</Typography>
      {currentSales.length > 0 ? (
        <Grid container spacing={4}>
          {currentSales.map(sale => (
            <Grid item xs={12} sm={6} md={4} key={sale.invoiceId}>
              <SaleCard sale={sale} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1">No sales found for this customer.</Typography>
      )}

      {/* Pagination Controls */}
      <Box display="flex" justifyContent="center" mt={4}>
        <Button
          variant="outlined"
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          sx={{ marginRight: 2 }}
        >
          Previous
        </Button>
        <Button
          variant="outlined"
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastSale >= customerSales.length}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default CustomerDetail;
