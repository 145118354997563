import React, { useState, useEffect } from 'react';
import {
    Box, CssBaseline, AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText,
    IconButton, Menu, MenuItem, Divider, Collapse, createTheme, ThemeProvider, Avatar,  Badge 
} from '@mui/material';
import {
    Home, ListAlt, Settings, Category, AccountBalance, Receipt, Straighten, LocationOn, 
    MonetizationOn, ExpandLess, ExpandMore, AttachMoney, People,
    Person, Menu as MenuIcon, SupervisorAccount, Assessment, Notifications, PointOfSale
} from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import ListSubheader from '@mui/material/ListSubheader';
import { BrowserRouter as Router, Link, useNavigate, useLocation } from 'react-router-dom';
import AdminContent from '../components/admin/AdminContent';
import { AuthContext } from '../components/auth/AuthContext';
import { axiosInstance, axiosAuthInstance } from '../Axios/AxiosInstance';
import { getNotifications } from '../components/hooks/notificationService';
import { formatTimeAgo } from '../utility/timeUtils';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import logo from '../assets/images/Inventory1.svg';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';



const drawerWidth = 270;

const theme = createTheme({
    palette: {
        primary: {
            main: '#367588',
        },
        secondary: {
            main: '#eceff1',
        },
    },
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 40,
                    color: '#367588',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontWeight: 500,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#367588',
                },
            },
        },
    },
});

const AdminPage = () => {
    const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
    const [token, setToken] = useState(null);
    const [username, setUsername] = useState(null);
    const [userId, setUserId] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [isOnline, setIsOnline] = useState(null);
    const [openSection, setOpenSection] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const { role, logout } = React.useContext(AuthContext);

    useEffect(() => {
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
            setToken(storedToken);
            axiosAuthInstance.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;

            axiosAuthInstance.get('/auth/user')
                .then(response => {
                    setUserId(response.data._id);
                    setUsername(response.data.username);
                    setProfilePicture(response.data.profilePicture);
                    setIsOnline(response.data.isOnline);
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        } else {
            navigate('/auth/signin');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const data = await getNotifications(userId);
                setUnreadMessages(data.notifications || []);
                const unreadCount = data.notifications?.filter(n => !n.read).length || 0;
                setUnreadCount(unreadCount);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };
    
        fetchNotifications();
        const interval = setInterval(fetchNotifications, 60000);
        return () => clearInterval(interval);
    }, [userId]); // Add userId as a dependency
    
    

    const handleNotificationClick = (event) => {
        event.stopPropagation();
        setNotificationAnchorEl(event.currentTarget);
        setSettingsAnchorEl(null); // Close settings menu if open
    };

    const handleNotificationItemClick = async (notificationId) => {
        try {
            await axiosInstance.put(`/notifications/${notificationId}/read`);
            setUnreadMessages((prevMessages) =>
                prevMessages.map((notification) =>
                    notification._id === notificationId
                        ? { ...notification, read: true }
                        : notification
                )
            );
            setUnreadCount((prevCount) => Math.max(prevCount - 1, 0));
            navigate(`/admin/notifications/detail/${notificationId}`);
            handleNotificationClose();
        } catch (error) {
            console.error('Error marking notification as read:', error);
        }
    };
    
    

    const handleNotificationClose = () => {
        setNotificationAnchorEl(null);
    };

    const handleSettingsClick = (event) => {
        event.stopPropagation();
        setSettingsAnchorEl(event.currentTarget);
        setNotificationAnchorEl(null); // Close notification menu if open
    };

    const handleSettingsClose = () => {
        setSettingsAnchorEl(null);
    };

    const handleViewMoreClick = () => {
        setSettingsAnchorEl(null);
        setNotificationAnchorEl(null);
        navigate(`/admin/notifications?userId=${userId}`);
    }
    

    const handleLogout = async () => {
        try {
            await logout(); // Call the logout function from AuthContext
            navigate('/auth/signin');
        } catch (error) {
            console.error('Logout error:', error);
            // Optionally handle logout errors here
        }
    };

    const isActiveRoute = (route) => {
        return location.pathname === route;
    };

    const handleSectionClick = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    const handleOtherItemClick = () => {
        setOpenSection(null);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="toggle drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2 }}
                        >
                            {drawerOpen ? <MenuIcon /> : <MenuIcon />}
                        </IconButton>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            <img src={logo} alt="AddisLogix Logo" style={{ width: 30, height: 30, marginRight: 8 }} />
                            <Typography variant="h6" noWrap component="div">
                                AddisLogix
                            </Typography>
                        </Box>
                        
                        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                            {profilePicture ? (
                                <img
                                    src={`data:image/jpeg;base64,${profilePicture}`}
                                    alt="Profile"
                                    style={{ width: 32, height: 32, borderRadius: '50%', marginRight: 5 }}
                                />
                            ) : (
                                <Avatar sx={{ backgroundColor: '#90cbd5', width: 32, height: 32, marginRight: 1 }}>
                                    {username ? username.charAt(0).toUpperCase() : '?'}
                                </Avatar>
                            )}
                            <Typography variant="body1" noWrap component="div">
                                {username}
                            </Typography>
                        </Box>
                        <IconButton
                            color="inherit"
                            onClick={handleNotificationClick}
                            aria-controls="notification-menu"
                            aria-haspopup="true"
                        >
                            <Badge badgeContent={unreadCount} color="error" sx={{
        '& .MuiBadge-standard': {
          width: '10px',
          height: '16px',
          minWidth: '18px',
          fontSize: '9px',
          borderRadius: '50%',
          marginTop: 0.5,
          top:  '-5px',  // Move up when drawer is closed
          left: '8px',  // Move left when drawer is closed
        },
      }}>
                                <Notifications />
                            </Badge>
                        </IconButton>
                        <Menu
    id="notification-menu"
    anchorEl={notificationAnchorEl}
    open={Boolean(notificationAnchorEl)}
    onClose={handleNotificationClose}
    PaperProps={{
        style: {
            maxHeight: 400, // Set height for menu
            width: '300px', // Set width for menu
        },
    }}
>
    {unreadMessages.length > 0 ? (
        unreadMessages.slice(0, 5).map((notification) => (
            <MenuItem
                key={notification._id}
                onClick={() => handleNotificationItemClick(notification._id)}
                sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }} // Add hover effect
            >
                <ListItemIcon>
                    {!notification.read && (
                        <CircleIcon sx={{ color: 'green', fontSize: 10 }} />
                    )}
                </ListItemIcon>
                <ListItemText
                    primary={notification.title}
                    secondary={formatTimeAgo(notification.timestamp)} // Display time ago
                />
            </MenuItem>
        ))
    ) : (
        <MenuItem onClick={handleNotificationClose}>
            No new notifications
        </MenuItem>
    )}
    <Divider />
    <MenuItem onClick={() => handleViewMoreClick()} sx={{ cursor: 'pointer' }}>
        View More
    </MenuItem>
</Menu>


                        <IconButton
                            color="inherit"
                            aria-label="open settings"
                            onClick={handleSettingsClick}
                        >
                            <Settings />
                        </IconButton>
                        <Menu
                            anchorEl={settingsAnchorEl}
                            keepMounted
                            open={Boolean(settingsAnchorEl)}
                            onClose={handleSettingsClose}
                        >
                            <MenuItem onClick={handleSettingsClose} component={Link} to="/admin/profile">Profile</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    open={drawerOpen}
                    sx={{
                        width: drawerOpen ? drawerWidth : 60,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerOpen ? drawerWidth : 60, boxSizing: 'border-box' },
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto' }}>
                    {username && ( // Display the username above the dashboard list
  <Box sx={{ 
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center', 
    padding: 2,
    position: 'relative' // To position the status indicator absolutely within this container
  }}>
  {/* Profile Picture */}
  <Box sx={{ 
      position: 'relative', // This is to position the status indicator relative to this Box
      marginBottom: 1 
    }}>
    {profilePicture ? (
      <img
        src={`data:image/jpeg;base64,${profilePicture}`}
        alt="Profile"
        style={{ 
          width: drawerOpen ? 80 : 40, // Adjusted size when drawer is open or closed
          height: drawerOpen ? 80 : 40, // Adjusted size when drawer is open or closed
          borderRadius: '50%', 
        //   border: '1px solid #367588',
        }}
      />
    ) : (
      <Avatar 
        sx={{ 
          backgroundColor: '#90cbd5', 
          width: drawerOpen ? 80 : 40, // Adjusted size when drawer is open or closed
          height: drawerOpen ? 80 : 40, // Adjusted size when drawer is open or closed
        //   border: '1px solid #367588',
        }}
      >
        {username ? username.charAt(0).toUpperCase() : '?'}
      </Avatar>
    )}

    {/* Status Indicator */}
    {drawerOpen && (
      <Box sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'background.paper',
        borderRadius: '50%',
        padding: '2px',
        border: '2px solid #fff' // Optional: to give a subtle border around the status dot
      }}>
        {isOnline ? (
          <Brightness1Icon style={{ color: '#39e600', fontSize: '14px' }} />
        ) : (
          <Brightness1Icon style={{ color: '#ccc', fontSize: '14px' }} /> // Grey for offline
        )}
      </Box>
    )}
  </Box>

  {/* Username */}
  {drawerOpen && (
    <Typography variant="h6" component="div" sx={{ textAlign: 'center', color: '#565656' }}>
      {username}
    </Typography>
  )}
</Box>


)}

                        <List>
                            {(role === 'Admin') && (
                                <>
                            <ListItem button component={Link} to="/admin/dashboard" selected={isActiveRoute('/admin/dashboard')} onClick={handleOtherItemClick} sx={{ 
        color: isActiveRoute('/admin/dashboard') ? '#2c5e6d' : 'inherit', 
      }}>
                                <ListItemIcon>
                                    <Home />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                            </ListItem>
                            <ListItem button onClick={handleViewMoreClick} selected={isActiveRoute('/admin/notifications')} sx={{ 
        color: isActiveRoute('/admin/notifications') ? '#2c5e6d' : 'inherit', 
      }}>
    <ListItemIcon>
        <Notifications />
    </ListItemIcon>
    <Badge
      badgeContent={unreadCount}
      color="error"
      sx={{
        '& .MuiBadge-standard': {
          width: '10px',
          height: '16px',
          minWidth: '18px',
          fontSize: '9px',
          borderRadius: '50%',
          marginTop: 0.5,
          top: drawerOpen ? '0' : '-13px',  // Move up when drawer is closed
          left: drawerOpen ? '80px' : '-32px',  // Move left when drawer is closed
        },
      }}
    >
      <ListItemText primary="Notifications" sx={{ display: drawerOpen ? 'block' : 'none' }} />
    </Badge>
</ListItem>
                            
                                        {drawerOpen && (
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Operations Management
                                            </ListSubheader>
                                        )}
                                    
                                    <ListItem button component={Link} to="/admin/shops" selected={isActiveRoute('/admin/shops')} onClick={handleOtherItemClick} sx={{ 
        color: isActiveRoute('/admin/shops') ? '#2c5e6d' : 'inherit', 
      }}>
                                        <ListItemIcon>
                                            <LocationOn />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Shops" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                    </ListItem>
                                    <ListItem button component={Link} to="/admin/units" selected={isActiveRoute('/admin/units')} onClick={handleOtherItemClick} sx={{ 
        color: isActiveRoute('/admin/units') ? '#2c5e6d' : 'inherit', 
      }}>
                                        <ListItemIcon>
                                            <Straighten />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Units" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                    </ListItem>
                                    <ListItem button component={Link} to="/admin/categories" selected={isActiveRoute('/admin/categories')} onClick={handleOtherItemClick} sx={{ 
        color: isActiveRoute('/admin/categories') ? '#2c5e6d' : 'inherit', 
      }}>
                                        <ListItemIcon>
                                            <Category />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Categories" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                    </ListItem>
                                    <ListItem button onClick={() => handleSectionClick('users')} >
                                        <ListItemIcon>
                                        <SupervisorAccount />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Users" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                        {drawerOpen && (openSection === 'users' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                                    </ListItem>
                                    <Collapse in={openSection === 'users'} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button component={Link} to="/admin/users" selected={isActiveRoute('/admin/users')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/users') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Users List" />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                    <ListItem button component={Link} to="/admin/suppliers" selected={isActiveRoute('/admin/suppliers')} onClick={handleOtherItemClick} sx={{ 
        color: isActiveRoute('/admin/suppliers') ? '#2c5e6d' : 'inherit', 
      }}>
                                        <ListItemIcon>
                                            <People />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Suppliers" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                    </ListItem>
                                    <ListItem button component={Link} to="/admin/customers" selected={isActiveRoute('/admin/customers')} onClick={handleOtherItemClick} sx={{ 
        color: isActiveRoute('/admin/customers') ? '#2c5e6d' : 'inherit', 
      }}>
                                        <ListItemIcon>
                                            <Person />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Customers" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                    </ListItem>
                                    {drawerOpen && (
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Inventory & Sales Management
                                            </ListSubheader>
                                        )}
                                    <ListItem button onClick={() => handleSectionClick('products')}>
                                        <ListItemIcon>
                                            <ListAlt />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Products" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                        {drawerOpen && (openSection === 'products' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                                    </ListItem>
                                    <Collapse in={openSection === 'products'} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button component={Link} to="/admin/products" selected={isActiveRoute('/admin/products')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/products') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Products List" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/admin/products/summary" selected={isActiveRoute('/admin/products/summary')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/products/summary') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Products Summary" />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                    <ListItem button onClick={() => handleSectionClick('purchases')}>
                                        <ListItemIcon>
                                            <Receipt />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Purchase" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                        {drawerOpen && (openSection === 'purchases' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                                    </ListItem>
                                    <Collapse in={openSection === 'purchases'} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button component={Link} to="/admin/purchases" selected={isActiveRoute('/admin/purchases')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/purchases') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Purchases List" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/admin/purchases/summary" selected={isActiveRoute('/admin/purchases/summary')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/purchases/summary') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Purchases Summary" />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                    <ListItem button onClick={() => handleSectionClick('distributions')}>
                                <ListItemIcon>
                                    <AccountBalance />
                                </ListItemIcon>
                                <ListItemText primary="Distributions" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                {drawerOpen && (openSection === 'distributions' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                            </ListItem>
                            <Collapse in={openSection === 'distributions'} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button component={Link} to="/admin/distribution" selected={isActiveRoute('/admin/distribution')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/distribution') ? '#2c5e6d' : 'inherit'}}>
                                        <ListItemText primary="Add Distributions" />
                                    </ListItem>
                                    <ListItem button component={Link} to="/admin/distribution/summary" selected={isActiveRoute('/admin/distribution/summary')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/distribution/summary') ? '#2c5e6d' : 'inherit'}}>
                                        <ListItemText primary="Summary" />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button onClick={() => handleSectionClick('sales')}>
                                <ListItemIcon>
                                <AttachMoney />
                                </ListItemIcon>
                                <ListItemText primary="Manage sales" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                {drawerOpen && (openSection === 'sales' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                            </ListItem>
                            <Collapse in={openSection === 'sales'} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button component={Link} to="/admin/sales" selected={isActiveRoute('/admin/sales')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/sales') ? '#2c5e6d' : 'inherit'}}>
                                        <ListItemText primary="Sale List " />
                                    </ListItem>
                                    <ListItem button component={Link} to="/admin/sales/summary" selected={isActiveRoute('/admin/sales/summary')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/sales/summary') ? '#2c5e6d' : 'inherit'}}>
                                        <ListItemText primary="Sale Report" />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <Divider />
                            <ListItem button onClick={() => handleSectionClick('quotes')}>
                                <ListItemIcon>
                                <InsertDriveFile />
                                </ListItemIcon>
                                <ListItemText primary="Manage Quotes" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                {drawerOpen && (openSection === 'quotes' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                            </ListItem>
                            <Collapse in={openSection === 'quotes'} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button component={Link} to="/admin/quote" selected={isActiveRoute('/admin/quote')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/quote') ? '#2c5e6d' : 'inherit'}}>
                                        <ListItemText primary="Quote List " />
                                    </ListItem>
                                    
                                </List>
                            </Collapse>
                            {drawerOpen && (
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Finance & Reporting
                                            </ListSubheader>
                                        )}
                            <ListItem button onClick={() => handleSectionClick('financials')}>
                                        <ListItemIcon>
                                        <MonetizationOn />
                                        </ListItemIcon>
                                        <ListItemText primary="Financials" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                        {drawerOpen && (openSection === 'financials' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                            </ListItem>
                            <Collapse in={openSection === 'financials'} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button component={Link} to="/admin/accounts" selected={isActiveRoute('/admin/accounts')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/accounts') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Accounts" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/admin/expenses" selected={isActiveRoute('/admin/expenses')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/expenses') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Expenses" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/admin/profits" selected={isActiveRoute('/admin/profits')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/profits') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Profits" />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                    <ListItem button onClick={() => handleSectionClick('Report')}>
                                                <ListItemIcon>
                                                <Assessment />
                                                </ListItemIcon>
                                                <ListItemText primary="Report" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                                {drawerOpen && (openSection === 'Report' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                                    </ListItem>
                            {/* <ListItem button component={Link} to="/admin/expenses" selected={isActiveRoute('/admin/expenses')} onClick={handleOtherItemClick}>
                                <ListItemIcon>
                                    <AccountBalanceWallet />
                                </ListItemIcon>
                                <ListItemText primary="Manage Expenses" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                            </ListItem>
                            <ListItem button component={Link} to="/admin/income" selected={isActiveRoute('/admin/income')} onClick={handleOtherItemClick}>
                                <ListItemIcon>
                                    <MonetizationOn />
                                </ListItemIcon>
                                <ListItemText primary="Manage Income" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                            </ListItem> */}
                                </>
                            )}
                            {role === 'Store-Manager' && (
                                <>
                                 <ListItem button onClick={handleViewMoreClick} selected={isActiveRoute('/admin/notifications')} sx={{ 
        color: isActiveRoute('/admin/notifications') ? '#2c5e6d' : 'inherit', 
      }}>
                                <ListItemIcon>
                                <Notifications />
                                </ListItemIcon>
                                <ListItemText primary="Notifications" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                            </ListItem>
                                    <ListItem button onClick={() => handleSectionClick('products')}>
                                        <ListItemIcon>
                                            <ListAlt />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Products" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                        {drawerOpen && (openSection === 'products' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                                    </ListItem>
                                    <Collapse in={openSection === 'products'} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button component={Link} to="/admin/products" selected={isActiveRoute('/admin/products')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/products') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Product List" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/admin/products/summary" selected={isActiveRoute('/admin/products/summary')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/products/summary') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Product Summary" />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                    <ListItem button onClick={() => handleSectionClick('purchases')}>
                                        <ListItemIcon>
                                            <Receipt />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Purchase" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                        {drawerOpen && (openSection === 'purchases' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                                    </ListItem>
                                    <Collapse in={openSection === 'purchases'} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button component={Link} to="/admin/purchases" selected={isActiveRoute('/admin/purchases')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/purchases') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Purchase List" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/admin/purchases/summary" selected={isActiveRoute('/admin/purchases/summary')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/purchases/summary') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Purchase Summary" />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                </>
                            )}
                            {role === 'Staff' && (
                                <>
                                 <ListItem button component={Link} to="/admin/pos" selected={isActiveRoute('/admin/pos')} onClick={handleOtherItemClick} sx={{ 
                                        color: isActiveRoute('/admin/pos') ? '#2c5e6d' : 'inherit', 
                                    }}>
                                    <ListItemIcon>
                                        <PointOfSale />
                                    </ListItemIcon>
                                    <ListItemText primary="POS" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                </ListItem>
                                 <ListItem button onClick={handleViewMoreClick} selected={isActiveRoute('/admin/notifications')} sx={{ 
                                        color: isActiveRoute('/admin/notifications') ? '#2c5e6d' : 'inherit', 
                                    }}>
                                <ListItemIcon>
                                <Notifications />
                                </ListItemIcon>
                                <ListItemText primary="Notifications" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                 </ListItem>
                                 {drawerOpen && (
                                            <ListSubheader component="div" id="nested-list-subheader">
                                                Operations Management
                                            </ListSubheader>
                                        )}
                                    <ListItem button onClick={() => handleSectionClick('mySales')}>
                                        <ListItemIcon>
                                            <ListAlt />
                                        </ListItemIcon>
                                        
                                        <ListItemText primary="Manage Sale" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                        {drawerOpen && (openSection === 'mySales' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                                    </ListItem>
                                    <Collapse in={openSection === 'mySales'} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button component={Link} to="/admin/sales/all-sales" selected={isActiveRoute('/admin/sales/all-sales')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/sales/all-sales') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="All Sales" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/admin/sales/today-sale" selected={isActiveRoute('/admin/sales/today-sale')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/sales/today-sale') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Today's Sales" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/admin/sales/voided" selected={isActiveRoute('/admin/sales/voided')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/sales/voided') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Voided Sales" />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                    <ListItem button onClick={() => handleSectionClick('returns')}>
                                        <ListItemIcon>
                                            <Receipt />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Return" sx={{ display: drawerOpen ? 'block' : 'none' }} />
                                        {drawerOpen && (openSection === 'returns' ? <ExpandLess sx={{ color: '#757575' }} /> : <ExpandMore sx={{ color: '#757575' }} />)}
                                    </ListItem>
                                    <Collapse in={openSection === 'returns'} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button component={Link} to="/admin/make-return" selected={isActiveRoute('/admin/make-return')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/make-return') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Make Return" />
                                            </ListItem>
                                            <ListItem button component={Link} to="/admin/returns" selected={isActiveRoute('/admin/returns')} sx={{ pl: drawerOpen ? 4 : 1, color: isActiveRoute('/admin/returns') ? '#2c5e6d' : 'inherit'}}>
                                                <ListItemText primary="Return List" />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                </>
                            )}
                        </List>
                    </Box>
                </Drawer>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                >
                    <Toolbar />
                    <AdminContent />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default AdminPage;
