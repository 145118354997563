import React from 'react';
import { Box, Typography } from '@mui/material';

const RatingStars = ({ rating }) => {
    const starColor = {
      Bronze: '#cd7f32',
      Silver: '#c0c0c0',
      Gold: '#ffd700',
    };
  
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="body2" sx={{ marginRight: 1 }}>
          Rating:
        </Typography>
        <Typography variant="h6" sx={{ color: starColor[rating] }}>
          ★
        </Typography>
      </Box>
    );
  };

export default RatingStars;
