import React from 'react';
import { Container, Typography, Paper, Link, Divider, Box } from '@mui/material';
import { styled } from '@mui/system';
import logo from '../assets/images/Inventory1.svg';

// Styled components for better control
const Section = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4),
}));

const TermsOfService = () => {
  return (
    <Container maxWidth="md" sx={{ padding: '40px' }}>
      <Paper elevation={6} sx={{ padding: '40px', borderRadius: '12px', backgroundColor: '#ffffff' }}>
        {/* Header with Logo and Title */}
        <HeaderContainer>
          <Typography variant="h4" component="h1" sx={{ color: '#2b556a' }}>
            Terms of Service
          </Typography>
          <img src={logo} alt="Company Logo" style={{ maxWidth: '150px', height: 'auto' }} />
        </HeaderContainer>

        <Typography variant="body1" sx={{ marginBottom: '32px', textAlign: 'center', color: '#666' }}>
          These Terms of Service govern your use of our website and services. By using our website, you agree to these terms.
        </Typography>

        {/* Sections */}
        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            By accessing and using our website, you agree to be bound by these terms. If you do not agree, please do not use our website.
          </Typography>
        </Section>

        <Divider sx={{ margin: '24px 0' }} />

        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            2. Changes to Terms
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            We may modify these terms at any time. It is your responsibility to review these terms regularly.
          </Typography>
        </Section>

        <Divider sx={{ margin: '24px 0' }} />

        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            3. User Responsibilities
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
          </Typography>
        </Section>

        <Divider sx={{ margin: '24px 0' }} />

        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            4. Prohibited Activities
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            You may not use our website for any unlawful or prohibited activities. Violations may result in termination of your access.
          </Typography>
        </Section>

        <Divider sx={{ margin: '24px 0' }} />

        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            5. Limitation of Liability
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            Our liability is limited to the maximum extent permitted by law. We are not liable for any indirect, incidental, or consequential damages.
          </Typography>
        </Section>

        <Divider sx={{ margin: '24px 0' }} />

        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            6. Contact Us
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            If you have any questions about these Terms of Service, please contact us at <Link href="mailto:info@addislogix.com" color="primary">info@addislogix.com</Link>.
          </Typography>
        </Section>
      </Paper>
    </Container>
  );
};

export default TermsOfService;
