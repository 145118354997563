import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';

const Expenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [newExpense, setNewExpense] = useState({
    description: '',
    amount: '',
    category: '',
    notes: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [errors, setErrors] = useState({
    description: '',
    amount: '',
    category: '',
  });

  const categoryDescriptions = {
    COGS: 'Costs of Goods Sold: Direct costs attributable to the production of the goods sold by a company. For example, raw materials, manufacturing labor, and packaging costs.',
    Operating: 'Operating Expense: Ongoing expenses for day-to-day operations. For example, rent, utilities, office supplies, and equipment maintenance.',
    Compliance: 'Compliance & Regulatory: Costs related to adhering to laws and regulations. For example, audit fees, legal compliance, and environmental certifications.',
    Administrative: 'Administrative & Operational: Expenses related to the general operation of the business. For example, salaries of administrative staff, insurance, and office administration costs.',
    Marketing: 'Marketing & Distribution: Costs related to advertising and promoting the business. For example, social media advertising, brochures, marketing events, and distribution costs.',
    Financial: 'Financial Expense: Interest payments, taxes, and other financial obligations. For example, loan interest, bank fees, and income taxes.',
    Other: 'Other: Miscellaneous expenses that don’t fall under the main categories. For example, donations, subscriptions, or unexpected one-time costs.',
  };
  

  const fetchExpenses = async () => {
    try {
      const response = await axiosInstance.get('/expenses');
      setExpenses(response.data);
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };

  const validateForm = () => {
    const newErrors = { description: '', amount: '', category: '' };
    let isValid = true;

    if (!newExpense.description) {
      newErrors.description = 'Description is required';
      isValid = false;
    }
    if (!newExpense.amount || isNaN(newExpense.amount) || newExpense.amount <= 0) {
      newErrors.amount = 'Valid amount is required';
      isValid = false;
    }
    if (!newExpense.category) {
      newErrors.category = 'Category is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleAddExpense = async () => {
    if (!validateForm()) return;

    try {
      if (editMode) {
        await axiosInstance.put(`/expenses/${editId}`, newExpense);
      } else {
        await axiosInstance.post('/expenses', newExpense);
      }
      fetchExpenses();
      resetForm();
    } catch (error) {
      console.error('Error adding/updating expense:', error);
    }
  };

  const handleDeleteExpense = async (id) => {
    try {
      await axiosInstance.delete(`/expenses/${id}`);
      fetchExpenses();
      handleCloseDialog();
    } catch (error) {
      console.error('Error deleting expense:', error);
    }
  };

  const handleEditExpense = (expense) => {
    setNewExpense(expense);
    setEditMode(true);
    setEditId(expense._id);
  };

  const resetForm = () => {
    setNewExpense({
      description: '',
      amount: '',
      category: '',
      notes: '',
    });
    setErrors({
      description: '',
      amount: '',
      category: '',
    });
    setEditMode(false);
    setEditId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewExpense({ ...newExpense, [name]: value });
  };

  const handleOpenDialog = (id) => {
    setDeleteId(id);
    setIsDialogOpen(true);
  };

  const handleOpenDialogForCategory = (category) => {
    setSelectedCategory(category);
    setDialogOpen(true);
  };
  const handleCloseDialogForCategory = (category) => {
    setSelectedCategory('');
    setDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setDeleteId(null);
  };

  useEffect(() => {
    fetchExpenses();
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" color="primary" sx={{ mb: 3 }}>
        Expense Management
      </Typography>

      {/* Add/Edit Expense Form */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" color="primary">{editMode ? 'Edit Expense' : ''}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Description"
              name="description"
              fullWidth
              value={newExpense.description}
              onChange={handleInputChange}
              error={!!errors.description}
              helperText={errors.description}
              placeholder="e.g., Office supplies or Raw materials"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Amount"
              name="amount"
              fullWidth
              type="number"
              value={newExpense.amount}
              onChange={handleInputChange}
              error={!!errors.amount}
              helperText={errors.amount}
              placeholder="e.g., 5000 (in currency)"
            />
          </Grid>
          <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Select
              label="Category"
              name="category"
              fullWidth
              value={newExpense.category}
              onChange={handleInputChange}
              error={!!errors.category}
              displayEmpty
            >
              <MenuItem value="">
                <p>Select Category</p>
              </MenuItem>
              <MenuItem value="COGS">Cost of Goods Sold</MenuItem>
              <MenuItem value="Operating">Operating Expense</MenuItem>
              <MenuItem value="Compliance">Compliance & Regulatory</MenuItem>
              <MenuItem value="Administrative">Administrative & Operational</MenuItem>
              <MenuItem value="Marketing">Marketing & Distribution</MenuItem>
              <MenuItem value="Financial">Financial Expense</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
            <Tooltip title="Click for more info">
                <IconButton onClick={() => handleOpenDialogForCategory(newExpense.category)} color="primary">
                  <Info />
                </IconButton>
              </Tooltip>
              </Box>
            {errors.category && (
              <Typography color="error" variant="body2">
                {errors.category}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Notes"
              name="notes"
              fullWidth
              value={newExpense.notes}
              onChange={handleInputChange}
              placeholder="e.g., Payment for January office supplies"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddExpense}
              sx={{ mr: 2 }}
            >
              {editMode ? 'Update Expense' : 'Add Expense'}
            </Button>
            {editMode && (
              <Button variant="outlined" color="secondary" onClick={resetForm}>
                Cancel Edit
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>

      {/* List of Expenses */}
      <Typography variant="h5" color="primary" sx={{ mb: 2, borderBottom: '2px solid #ddd', paddingBottom: '10px' }}>
        Expenses List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses.map((expense) => (
              <TableRow key={expense._id}>
                <TableCell>{expense.description}</TableCell>
                <TableCell>{expense.amount} Br.</TableCell>
                <TableCell>{expense.category}</TableCell>
                <TableCell>{expense.notes}</TableCell>
                <TableCell>{new Date(expense.date).toLocaleDateString()}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditExpense(expense)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleOpenDialog(expense._id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-danger">Confirm Expense Deletion</DialogTitle>
        <DialogContent>
          {deleteId && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Please confirm if you wish to delete the following expense:
              </Typography>
              {expenses
                .filter(expense => expense._id === deleteId)
                .map(expense => (
                  <Box key={expense._id} sx={{ mb: 2 }}>
                    <Typography variant="body1"><strong>Description:</strong> {expense.description}</Typography>
                    <Typography variant="body1"><strong>Amount:</strong> {expense.amount} Br.</Typography>
                    <Typography variant="body1"><strong>Category:</strong> {expense.category}</Typography>
                  </Box>
                ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteExpense(deleteId)}
            color="error"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialogForCategory}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{selectedCategory || 'Category Information'}</DialogTitle>
        <DialogContent>
          <Typography>
            {selectedCategory ? categoryDescriptions[selectedCategory] : 'Please select a category to view its details.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogForCategory} variant='outlined' color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Expenses;
