import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer style={{
      padding: '20px',
      borderTop: '2px solid rgba(255, 255, 255, 0.1)',
      backgroundColor: '#29323c',
      textAlign: 'center',
    }}>
      <p style={{ margin: '0 0 10px' }}>© 2024 AddisLogix. All Rights Reserved.</p>
      <Link to="/privacy-policy" style={{ color: '#91a4b3', textDecoration: 'none', margin: '0 10px' }}>Privacy Policy</Link>
      <Link to="/terms-of-service" style={{ color: '#91a4b3', textDecoration: 'none', margin: '0 10px' }}>Terms of Service</Link>
      <p>Contact: <a href="mailto:info@addislogix.com" style={{ color: '#91a4b3' }}>info@addislogix.com</a></p>
    </footer>
  );
};

export default Footer;
