import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { Box, Typography, CircularProgress, Snackbar, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TablePagination } from '@mui/material';
import { useAuth } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom';

const ReturnList = () => {
  const { shopName } = useAuth();
  const [returns, setReturns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const navigate = useNavigate();

  useEffect(() => {
    if (shopName) {
      fetchReturns();
    }
  }, [shopName, page, rowsPerPage]); // Include page and rowsPerPage in the dependency array

  const fetchReturns = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/sales/returns', {
        params: { 
          shopName, 
          page: page + 1, // Adjust page for 1-based index if necessary
          limit: rowsPerPage // Add pagination parameters
        }
      });

      const aggregatedReturns = response.data.reduce((acc, returnItem) => {
        const { invoiceId, customerName, quantity } = returnItem;
        
        if (!acc[invoiceId]) {
          acc[invoiceId] = {
            invoiceId,
            customerName: customerName || 'N/A',
            totalQuantity: 0
          };
        }
        
        acc[invoiceId].totalQuantity += quantity;
        return acc;
      }, {});

      setReturns(Object.values(aggregatedReturns));
      setError('');
    } catch (error) {
      console.error('Error fetching returns:', error);
      setError('Failed to fetch returns');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  if (loading) return <CircularProgress />;
  if (error) return <Snackbar open={true} autoHideDuration={6000}><Alert severity="error">{error}</Alert></Snackbar>;

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4"  color="primary" gutterBottom>
        Returns for Shop: {shopName}
      </Typography>
      {returns.length > 0 ? (
        <>
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Invoice No</TableCell>
                  <TableCell>Number of Products Returned</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {returns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((returnItem) => (
                  <TableRow key={returnItem.invoiceId}>
                    <TableCell>{returnItem.invoiceId}</TableCell>
                    <TableCell>{returnItem.totalQuantity}</TableCell>
                    <TableCell>{returnItem.customerName}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => navigate(`/admin/returns/detail/${returnItem.invoiceId}`)}
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={returns.length} // Set the total count of items
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Typography>No returns found</Typography>
      )}
    </Box>
  );
};

export default ReturnList;
