import React from 'react';
import Header from '../components/header/Header';
import TextContent from './partials/TextContent';
import Carousel from './partials/Carousel';
import Footer from './partials/Footer';

const Presentation = () => {
  return (
    <div style={{
      fontFamily: 'Poppins, Arial, sans-serif',
      background: 'linear-gradient(135deg, #29323c, #485563)',
      color: '#fff',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Header singleLink={{ label: 'Sign In', to: '/auth/signin' }} />

      <main style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '50px',
        gap: '40px',
        flexWrap: 'wrap',
      }}>
        <TextContent />
        <Carousel />
      </main>

      <Footer />
    </div>
  );
};

export default Presentation;
