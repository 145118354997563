import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  Button,
  TextField,
  MenuItem,
  Modal,
  Box,
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Snackbar,
  Alert,
} from '@mui/material';
import PaymentComponent from './Create_Invoice_Components/PaymentComponent';
import CustomerPicker from './Create_Invoice_Components/CustomerPicker';
import ConfirmationDialog from './Create_Invoice_Components/ConfirmationDialog';
import { useFormValidation } from '../hooks/useFormValidation';
import { useProducts } from '../hooks/useProducts';
import { useCustomers } from '../hooks/useCustomersHook';
import { useAuth } from '../auth/AuthContext';
import { getCurrentTimeInZone } from '../../utility/dateUtils';
import { 
    handleCustomerSelection, 
    handleSplitPaymentValidation, 
    prepareItems, 
    preparePayload, 
    handleApiError 
} from '../../utility/formUtilities';

const CreateInvoice = () => {
  const [formData, setFormData] = useState({
    customer: '',
    items: [{ product: '', quantity: '', unitPrice: '' }],
    totalPrice: 0,
    paymentMethod: '',
    paymentStatus: '',
    cashAmount: '',
    mobileBankingAmount: '',
    transactionId: '',
    creditPaymentType: '',
    partialCreditAmount: '',
    newCustomerName: '',
    newCustomerPhone: '',
  });

  const { shopId, userId, username } = useAuth();
  const { products, availableQuantities, setAvailableQuantities, refreshProductQuantities } = useProducts(shopId);
  const { customers, fetchCustomers } = useCustomers();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentTime, setCurrentTime] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { errors, validateForm } = useFormValidation(products);
  const [saleCompleted, setSaleCompleted] = useState(false); // New state
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshProductQuantities();
    fetchCustomers();
    updateCurrentTime();
    const interval = setInterval(updateCurrentTime, 1000);
    return () => clearInterval(interval);
  }, []);

  const updateCurrentTime = () => {
    const ethiopiaTimeZone = 'Africa/Addis_Ababa';
    setCurrentTime(getCurrentTimeInZone(ethiopiaTimeZone));
  };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const showSnackbar = (message, severity = 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...formData.items];
  
    if (field === 'product') {
      // Check if the product is already selected in another item
      const productAlreadySelected = updatedItems.some((item, i) => item.product === value && i !== index);
      if (productAlreadySelected) {
        showSnackbar('This product is already added. Please select a different product.', 'error');
        return;
      }
  
      // Check if the selected product has available quantity greater than 0
      if (availableQuantities[value] <= 0) {
        showSnackbar('This product is out of stock. Please select a product with available stock.', 'error');
        return;
      }
    }
  
    updatedItems[index][field] = value;
  
    // Handle quantity update logic
    const selectedProductId = updatedItems[index].product;
    const quantityEntered = parseFloat(updatedItems[index].quantity) || 0;
  
    if (field === 'quantity' && selectedProductId) {
      const prevQuantity = parseFloat(formData.items[index].quantity) || 0;
      const quantityDiff = quantityEntered - prevQuantity;
  
      setAvailableQuantities((prevQuantities) => ({
        ...prevQuantities,
        [selectedProductId]: prevQuantities[selectedProductId] - quantityDiff,
      }));
    }
  
    setFormData((prevData) => ({ ...prevData, items: updatedItems }));
    updateTotalPrice(updatedItems);
  };
  
  const handleAddItem = () => {
    const lastItem = formData.items[formData.items.length - 1];
    if (!lastItem.product) {
      setSnackbarMessage('Please select a product for the current item before adding a new one.');
      showSnackbar(true);
      return;
    }
    if (!lastItem.quantity || !lastItem.unitPrice) {
      setSnackbarMessage('Please fill in the quantity and unit price of the current item before adding a new one.');
      showSnackbar(true);
      return;
    }
    setFormData({ ...formData, items: [...formData.items, { product: '', quantity: '', unitPrice: '' }] });
  };

  const handleRemoveItem = (index) => {
    const itemToRemove = formData.items[index];
    const updatedItems = formData.items.filter((_, i) => i !== index);
  
    // Restore available quantity
    if (itemToRemove.product) {
      setAvailableQuantities((prevQuantities) => ({
        ...prevQuantities,
        [itemToRemove.product]: prevQuantities[itemToRemove.product] + parseFloat(itemToRemove.quantity || 0),
      }));
    }
  
    setFormData({ ...formData, items: updatedItems });
    updateTotalPrice(updatedItems);
  };
  

  const updateTotalPrice = (items) => {
    const total = items.reduce((sum, item) => {
      const itemTotal = parseFloat(item.quantity) * parseFloat(item.unitPrice || 0);
      return sum + (isNaN(itemTotal) ? 0 : itemTotal);
    }, 0);
    setFormData((prevData) => ({ ...prevData, totalPrice: total }));
  };

  const saveDraft = () => {
    const isFormValid = formData.items.every((item) => item.product && item.quantity && item.unitPrice);
    if (!isFormValid || !formData.customer) {
      setSnackbarMessage('Please fill all required fields correctly before saving the draft.');
      showSnackbar(true);
      return;
    }
    if (window.confirm('Are you sure you want to save this draft?')) {
      const draft = {
        ...formData,
        status: 'draft',
        timestamp: Date.now(),
      };
      localStorage.setItem(`draftSale-${draft.timestamp}`, JSON.stringify(draft));
      alert('Draft saved successfully!');
    }
  };
  
  const handleOpenModal = () => {
    if (validateForm(formData, customers, showSnackbar)) {
      setModalVisible(true);
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

const generateInvoiceId = () => {
    const timestamp = Date.now().toString();
    const randomNum = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
    return `INV-${timestamp}-${randomNum}`;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setModalVisible(false);

    // Validate the form before proceeding
    if (!validateForm(formData, customers, showSnackbar)) {
        setIsLoading(false);
        return;
    }

    // Generate a formatted transaction ID
    const formattedTransactionId = `TXN-${formData.transactionId}`;
    console.log('Formatted Transaction ID:', formattedTransactionId);

    let selectedCustomer = await handleCustomerSelection(formData, customers, axiosInstance, showSnackbar);

    // Stop submission if no customer is selected or created
    if (!selectedCustomer) {
        setIsLoading(false);
        return;
    }

    // Handle payment method validation
    if (!handleSplitPaymentValidation(formData, showSnackbar)) {
        setIsLoading(false);
        return;
    }

    // Generate a new invoice ID
    const invoiceId = generateInvoiceId();
    console.log('Generated Invoice ID:', invoiceId);

    // Prepare items with correct values
    const mergedItems = prepareItems(formData.items, shopId);
    console.log('Merged Items:', mergedItems);

    // Prepare payload for API request
    const payload = preparePayload(
        formData,
        selectedCustomer,
        mergedItems,
        invoiceId,
        formattedTransactionId,
        shopId,
        userId,
        username
    );
    console.log('Payload for API request:', payload);

    // Send the request to create the sale
    try {
        const response = await axiosInstance.post('/sales', payload);
        console.log('Sale Created:', response.data);

        showSnackbar('Sale created successfully!', 'success');

        // Reset form and update product quantities
        resetForm();
        await refreshProductQuantities();
    } catch (error) {
        handleApiError(error, showSnackbar);
    } finally {
        setIsLoading(false);
    }
};

const resetForm = () => {
    setFormData({
        customer: '',
        items: [{ product: '', quantity: '', unitPrice: '' }],
        totalPrice: 0,
        paymentMethod: '',
        paymentStatus: '',
        cashAmount: '',
        mobileBankingAmount: '',
        transactionId: '',
        creditPaymentType: '',
        partialCreditAmount: '',
        newCustomerName: '',
        newCustomerPhone: '',
    });
};

  const handleSearchProduct = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filtered = products.filter((product) => {
        const nameMatch = product.name && product.name.toLowerCase().includes(query.toLowerCase());
        const descriptionMatch = product.description && product.description.toLowerCase().includes(query.toLowerCase());
        return nameMatch || descriptionMatch;
      });
      setSearchResults(filtered);
    } else {
      setSearchResults([]);
    }
  };

  const handleProductSelect = (productId) => {
    const selectedProduct = products.find((product) => product._id === productId);
    const updatedItems = [...formData.items];
    updatedItems[formData.items.length - 1] = { ...updatedItems[formData.items.length - 1], product: productId };
    setFormData({ ...formData, items: updatedItems });
    setSearchQuery('');
    setSearchResults([]);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '85vh', fontFamily: 'Roboto, Arial, sans-serif' }}>
      {/* Header with Navigation */}
      <div style={{ backgroundColor: '#367588', padding: '10px', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" style={{ color: 'white' }}>My POS </Typography>
        <Typography variant="h6">Current Time: {currentTime}</Typography>
      </div>

      {/* Main Content with Sidebar and Item Entry */}
      <div style={{ display: 'flex', flexGrow: 1 }}>
        
        {/* Sidebar for Customer and Payment */}
        <div style={{ width: '30%', height: '83.3vh', backgroundColor: '#eff9f7', padding: '15px', boxShadow: '2px 0 10px rgba(0,0,0,0.1)' }}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Select Customer</Typography>
              <CustomerPicker
                customers={customers}
                formData={formData}
                handleChange={handleInputChange}
                setNewCustomerName={(value) => handleInputChange('newCustomerName', value)}
                setNewCustomerPhone={(value) => handleInputChange('newCustomerPhone', value)}
              />

              <Divider style={{ margin: '20px 0' }} />

              <PaymentComponent
                formData={formData}
                handleChange={handleInputChange}
                totalPrice={formData.totalPrice}
              />
            </CardContent>
          </Card>
        </div>

        {/* POS Entry Area */}
        <div style={{ flexGrow: 1, padding: '20px' }}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Search Product</Typography>
              <TextField
                label="Search Product"
                fullWidth
                value={searchQuery}
                onChange={handleSearchProduct}
                variant="outlined"
                InputProps={{ style: { padding: '10px' } }}
              />

                <List style={{ maxHeight: '200px', overflow: 'auto', marginTop: '10px' }}>
                {searchResults.map((product) => {
                    const isProductSelected = formData.items.some(item => item.product === product._id);
                    const isOutOfStock = availableQuantities[product._id] === 0;

                    return (
                    <ListItem
                        button
                        key={product._id}
                        onClick={() => !isProductSelected && !isOutOfStock && handleProductSelect(product._id)} // Disable click if already selected or out of stock
                        disabled={isProductSelected || isOutOfStock} // Disable the item if conditions are met
                        style={{
                        borderBottom: '1px solid #ddd',
                        backgroundColor: isProductSelected ? '#f0f0f0' : isOutOfStock ? '#edfaf7' : 'white', // Add a visual indication
                        }}
                    >
                        <ListItemText 
                        primary={product.name} 
                        secondary={`${product.description} | Min Price: ${product.minPrice || 0} | Available: ${availableQuantities[product._id] || 0}`} 
                        />
                    </ListItem>
                    );
                })}
                </List>


              {/* Items added to sale */}
              <Divider style={{ margin: '20px 0' }} />
              <Typography variant="h6" gutterBottom>Items in Sale</Typography>
              <div>
                {formData.items.map((item, index) => (
                  <div key={index} style={{ display: 'flex', gap: '10px', marginBottom: '10px', alignItems: 'center' }}>
                    <TextField
                      select
                      label="Product"
                      value={item.product}
                      onChange={(e) => handleItemChange(index, 'product', e.target.value)}
                      fullWidth
                      variant="outlined"
                    >
                      {products.map((product) => (
                        <MenuItem key={product._id} value={product._id}>
                          {product.name}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      label="Quantity"
                      type="number"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                      variant="outlined"
                      disabled={!item.product} // Disable if no product is selected
                    />
                    {errors[index] && <Typography color="error" variant="body2">{errors[index]}</Typography>}

                    <TextField
                      label="Unit Price"
                      type="number"
                      value={item.unitPrice}
                      onChange={(e) => handleItemChange(index, 'unitPrice', e.target.value)}
                      variant="outlined"
                      disabled={!item.product} // Disable if no product is selected
                      error={!!errors[`unitPrice_${index}`]}
                      helperText={errors[`unitPrice_${index}`]}
                    />

                    {formData.items.length > 1 && (
                      <Button onClick={() => handleRemoveItem(index)} color="error" variant="outlined">
                        Remove
                      </Button>
                    )}
                  </div>
                ))}
              </div>

              <Button
                onClick={handleAddItem}
                style={{ marginTop: '10px'}}
                variant="contained" 
                color="primary"
              >
                Add Another Item
              </Button>

              <Divider style={{ margin: '20px 0' }} />

              {/* Total Price Display */}
              <Typography variant="h6" gutterBottom>Total Price: {formData.totalPrice.toFixed(2)}</Typography>

              {/* Save Draft and Submit Buttons */}
              <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item>
                  <Button onClick={saveDraft} variant="outlined" color="warning">
                    Save Draft
                  </Button>
                </Grid>
                <Grid item>
                <Button onClick={handleOpenModal} variant="contained" color="primary"disabled={isLoading}>
                    {isLoading ? 'Processing...' : 'Finalize Sale'}
                </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
      <ConfirmationDialog
        open={modalVisible}
        onClose={handleCloseModal}
        formData={formData}
        customers={customers}
        products={products}
        handleSubmit={handleSubmit}
      />
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
        </Alert>
     </Snackbar>

    </div>
  );
};

export default CreateInvoice;
