import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Snackbar, Alert, Button, IconButton, Tooltip, TablePagination, TextField } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAuth } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom';

const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const AllSales = () => {
  const { username } = useAuth();
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [page, setPage] = useState(0); // For pagination
  const [rowsPerPage, setRowsPerPage] = useState(10); // For pagination
  const [startDate, setStartDate] = useState(null); // Start date state
  const [endDate, setEndDate] = useState(null); // End date state
  const [searchQuery, setSearchQuery] = useState(''); // Search query state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSales = async () => {
      if (username) {
        try {
          const response = await axiosInstance.get('/sales/non-voided-sales', {
            params: { createdBy: username }
          });

          const invoiceData = response.data.reduce((acc, sale) => {
            if (!acc[sale.invoiceId]) {
              acc[sale.invoiceId] = {
                count: 0,
                totalPrice: 0,
                paymentMethod: sale.paymentMethod,
                paymentStatus: sale.paymentStatus,
                customerName: sale.customerName,
                saleDate: sale.saleDate,
              };
            }
            acc[sale.invoiceId].count += 1;
            acc[sale.invoiceId].totalPrice += sale.totalPrice;
            return acc;
          }, {});

          setSales(Object.entries(invoiceData).map(([invoiceId, data]) => ({
            invoiceId,
            ...data
          })));
        } catch (error) {
          console.error('Error fetching sales:', error);
          setError('Failed to fetch sales data');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSales();
  }, [username]);

  // Filter sales based on the selected date range and search query
  const filteredSales = sales.filter(sale => {
    const saleDate = new Date(sale.saleDate);
    const start = startDate ? new Date(startDate.setHours(0, 0, 0, 0)) : new Date('1970-01-01'); // Default to very early date if no start date
    const end = endDate ? new Date(endDate.setHours(23, 59, 59, 999)) : new Date(); // Default to current date if no end date
    const matchesDateRange = saleDate >= start && saleDate <= end;
    const matchesSearchQuery = sale.customerName?.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesDateRange && matchesSearchQuery;
  });

  const handleCopy = (invoiceId) => {
    navigator.clipboard.writeText(invoiceId);
    setSnackbarOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Snackbar open={true} autoHideDuration={6000}><Alert severity="error">{error}</Alert></Snackbar>;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" color="primary" gutterBottom>
          All Sales
        </Typography>

        {/* Date Range Picker */}
        <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        {/* Search by Customer Name */}
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Search by Customer Name"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Invoice ID</TableCell>
                <TableCell>Number of Products</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Payment Method</TableCell>
                <TableCell>Sale Date</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSales.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: 'center', padding: 2 }}>
                    <Typography variant="h6" color="textSecondary">
                      No sales available
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredSales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(({ invoiceId, count, totalPrice, paymentMethod, saleDate, customerName }) => (
                  <TableRow key={invoiceId}>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {invoiceId}
                        <Tooltip title="Copy Invoice ID">
                          <IconButton onClick={() => handleCopy(invoiceId)} sx={{ marginLeft: 1 }}>
                            <ContentCopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell>{count}</TableCell>
                    <TableCell>{formatNumberWithCommas(totalPrice.toFixed(2))} Br.</TableCell>
                    <TableCell>{paymentMethod}</TableCell>
                    <TableCell>{new Date(saleDate).toLocaleString()}</TableCell>
                    <TableCell>{customerName || 'N/A'}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => navigate(`/admin/sales/sale-detail/${invoiceId}`)}
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[10, 15, 25]}
          component="div"
          count={filteredSales.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="success">
            Invoice ID copied to clipboard!
          </Alert>
        </Snackbar>
      </Box>
    </LocalizationProvider>
  );
};

export default AllSales;
