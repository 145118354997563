import React, { useState, useEffect, useMemo } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { Link } from 'react-router-dom';
import { Table, Modal, Spinner, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faFileExcel, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'; // Excel icon
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon} from '@mui/icons-material';
import {  TableBody,Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,TextField, Select, MenuItem, FormControl, InputLabel, Breadcrumbs, Typography, Box, Button, CircularProgress } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import ExcelJS from 'exceljs';
import JsBarcode from 'jsbarcode';
import JSZip from 'jszip';
import { saveAs } from 'file-saver'

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [deleteProductName, setDeleteProductName] = useState('');
  const [deleteProductDescription, setDeleteProductDescription] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [showCustomMessageModal, setShowCustomMessageModal] = useState(false);
  const [customMessage, setCustomMessage] = useState('');
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);

  const pageLimit = 5; // Number of page buttons to display
  
  
  const handleCloseCustomMessageModal = () => setShowCustomMessageModal(false);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = { search: searchTerm, category: categoryFilter, deleted: false };
        const [productsResponse, categoriesResponse] = await Promise.all([
          axiosInstance.get('/products', { params }),
          axiosInstance.get('/categories')
        ]);
  
        // Sorting products by name alphabetically
        const sortedProducts = productsResponse.data.sort((a, b) => a.name.localeCompare(b.name));
  
        setProducts(sortedProducts);
        setCategories(categoriesResponse.data || []); // Keeping categories as they are
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [searchTerm, categoryFilter]);
  

  const formatNumberWithCommas = (value) => {
    if (value == null || isNaN(value)) {
      return 'N/A'; // Return a placeholder or a default value if the input is invalid
  }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
  };

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setCategoryFilter(category);
  };

  const memoizedFilteredProducts = useMemo(() => {
    let filtered = products.filter(product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (categoryFilter) {
      filtered = filtered.filter(product => product.category?._id === categoryFilter);
    }

    return filtered;
  }, [products, searchTerm, categoryFilter]);
  

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const handleDeleteProduct = async () => {
    try {
      await axiosInstance.patch(`/products/${deleteProductId}`, { deleted: true });
      // Update the products list, memoized filtering will handle the rest
      setProducts(products.filter(product => product._id !== deleteProductId));
      handleCloseDeleteDialog();
    } catch (error) {
      setError('Error deleting product');
    }
  };
  

  const handleOpenDeleteDialog = (productId, productName, productDescription) => {
    setDeleteProductId(productId);
    setDeleteProductName(productName); // Store the product name
    setDeleteProductDescription(productDescription); // Store the product name
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteProductId(null);
    setOpenDeleteDialog(false);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = memoizedFilteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate pagination buttons
  const totalPages = Math.ceil(memoizedFilteredProducts.length / productsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  const generatePagination = () => {
    const startPage = Math.max(2, currentPage - Math.floor(pageLimit / 2));
    const endPage = Math.min(startPage + pageLimit - 1, totalPages - 1);
    const middlePages = pageNumbers.slice(startPage - 1, endPage);

    return (
      <>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} />
        <Pagination.Item active={currentPage === 1} onClick={() => handlePageChange(1)}>1</Pagination.Item>
        {startPage > 2 && <Pagination.Ellipsis />}
        {middlePages.map(page => (
          <Pagination.Item key={page} active={page === currentPage} onClick={() => handlePageChange(page)}>{page}</Pagination.Item>
        ))}
        {endPage < totalPages - 1 && <Pagination.Ellipsis />}
        {totalPages > 1 && (
          <Pagination.Item active={currentPage === totalPages} onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>
        )}
        <Pagination.Next disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
      </>
    );
  };

  const exportToExcel = () => {
    if (memoizedFilteredProducts.length === 0) {
        setCustomMessage('No products available for export.');
        setShowCustomMessageModal(true);
        return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Products');

    // Add Title
    worksheet.mergeCells('A1:I1');
    const titleRow = worksheet.getCell('A1');
    titleRow.value = 'Product Inventory Report';
    titleRow.font = { size: 18, bold: true };
    titleRow.alignment = { horizontal: 'center' };

    // Add Description
    worksheet.mergeCells('A2:I2');
    const descRow = worksheet.getCell('A2');
    descRow.value = 'This report provides an overview of the current product inventory.';
    descRow.font = { italic: true, size: 12 };
    descRow.alignment = { horizontal: 'center' };

    // Add Date
    worksheet.mergeCells('A3:I3');
    const dateRow = worksheet.getCell('A3');
    dateRow.value = `Date: ${new Date().toLocaleDateString()}`;
    dateRow.font = { size: 10 };
    dateRow.alignment = { horizontal: 'center' };

    // Add empty row for spacing
    worksheet.addRow([]);

    // Add Headers
    const headers = ['#', 'Name', 'Description', 'Price', 'Stock', 'Unit', 'Category', 'Type', 'Value', 'Barcode'];
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell) => {
        cell.font = { bold: true, size: 14, color: { argb: 'FFFFFFFF' } };
        cell.alignment = { horizontal: 'center' };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '367588' },
        };
    });

    // Add Data
    let sequentialNumber = 1;
    memoizedFilteredProducts.forEach((product) => {
        const row = worksheet.addRow([
            sequentialNumber++, // Sequential number
            product.name,
            product.description,
            Number.isInteger(product.price) ? product.price.toFixed(2) : product.price,
            product.stock,
            product.unit ? product.unit.name : 'N/A',
            product.category ? product.category.name : 'N/A',
            product.productType,
            (product.price * product.stock).toFixed(2),
            '', // Placeholder for barcode image
        ]);

        // Adjust row height for barcode
        worksheet.getRow(row.number).height = 60; // Adjust the height as needed

        // Generate barcode and add to the row
        if (product.barcode) {
            const imageId = workbook.addImage({
                base64: product.barcode, // Assuming 'barcode' is the Base64 string
                extension: 'png',
            });

            worksheet.addImage(imageId, {
                tl: { col: 9, row: row.number - 1 }, // Adjust the position (col index starts from 0)
                ext: { width: 150, height: 50 }, // Adjust the size as needed
            });
        }
    });

    // Add Total Value Row
    const totalValue = memoizedFilteredProducts.reduce((total, product) => total + (product.price * product.stock), 0).toFixed(2);
    const totalRow = worksheet.addRow(['Total', '', '', '', '', '', totalValue]);
    totalRow.getCell(7).font = { bold: true };
    totalRow.getCell(7).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '367588' },
    };

    // Adjust Column Widths
    worksheet.columns = [
        { width: 10 },  // Sequential number
        { width: 15 },  // Name
        { width: 65 },  // Description
        { width: 15 },  // Price
        { width: 15 },  // Stock
        { width: 10 },  // Unit
        { width: 25 },  // Category
        { width: 15 },  // Type
        { width: 20 },  // Value
        { width: 25 },  // Barcode
    ];

    // Format the date for the filename
    const formattedDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format

    // Export the file
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `ProductList_${formattedDate}.xlsx`; // Filename with date
        link.click();
    });
};

  const downloadBarcodesAsZip = async () => {

    if (memoizedFilteredProducts.length === 0) {
      setCustomMessage('No barcodes available for export.');
      setShowCustomMessageModal(true);
      return;
    }

    const zip = new JSZip();
    memoizedFilteredProducts.forEach((product) => {
      if (product.barcode) {
        const base64Data = product.barcode.split(',')[1];
        const binaryData = atob(base64Data);
        const arrayBuffer = new Uint8Array(binaryData.length);

        for (let i = 0; i < binaryData.length; i++) {
          arrayBuffer[i] = binaryData.charCodeAt(i);
        }

        zip.file(`${product.name}-barcode.png`, arrayBuffer);
      }
    });

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'barcodes.zip');
  };
  


  return (
    <div className="container mt-5">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" color="primary">Products List</Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit">Products</Typography>
          <Typography color="textPrimary">Product List</Typography>
        </Breadcrumbs>
      </Box>

      <TextField
        label="Search products by name or description"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={handleSearchFocus}
        onBlur={handleSearchBlur}
        fullWidth
        sx={{ mb: 3 }}
      />

      <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
        <InputLabel id="category-filter-label">Filter by Category</InputLabel>
        <Select
          labelId="category-filter-label"
          id="category-filter"
          value={categoryFilter}
          onChange={handleCategoryChange}
          label="Filter by Category"
        >
          <MenuItem value="">All Categories</MenuItem>
          {categories.map(category => (
            <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
      <Button
        component={Link}
        to="/admin/products/add"
        variant="contained"
        color="primary"
      >
        Add New Product
      </Button>
      <Button
      variant="outlined"
        color="success"
        startIcon={<FontAwesomeIcon icon={faFileExcel} />} // Excel icon
        onClick={exportToExcel}
      >
        Export to Excel
      </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => downloadBarcodesAsZip(memoizedFilteredProducts)}
        >
          Download Barcodes
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : error ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" color="error">{error}</Typography>
        </Box>
      ) : currentProducts.length > 0 ? (
        <>
         <TableContainer component={Box} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Stock</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Actions</TableCell>
                  {currentProducts.some(
                    (product) =>
                      (product.price > 0 && product.minPrice === 0) ||
                      (product.stock > 0 &&
                        ['liquid', 'gel', 'powder'].includes(product.productType) &&
                        !product.expirationDate) ||
                      (product.minPrice > 0 && product.minPrice < product.price)
                  ) && <TableCell>Warning</TableCell>}
                </TableRow>
              </TableHead>

              <TableBody>
                {currentProducts.map((product, index) => {
                  const showMinPriceWarning = product.price > 0 && product.minPrice === 0;
                  const showExpirationDateWarning =
                    product.stock > 0 &&
                    ['Liquid', 'Gel', 'Powder'].includes(product.productType) &&
                    !product.expirationDate;
                  const showMinPriceLessThanUnitPriceWarning =
                    product.minPrice > 0 && product.minPrice < product.price;

                  // Determine which warning to show
                  const warningMessage = showMinPriceWarning
                    ?'The minimum selling price for this product has not been set. Please update the product details to include a minimum price. Note that distribution of this product will not be possible until this requirement is met.'
                    : showExpirationDateWarning
                    ? 'The expiration date is required for Liquid, Gel, and Powder products. Please update the product details to include this information.'
                    : showMinPriceLessThanUnitPriceWarning
                    ? 'The minimum price should be at least equal to or higher than the unit price. Please update the product details to adjust the minimum price.'
                    : '';

                  return (
                    <TableRow key={product._id}>
                      <TableCell>
                        {index + 1 + (currentPage - 1) * productsPerPage}
                      </TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.description}</TableCell>
                      <TableCell>{formatNumberWithCommas(product.price.toFixed(2))}</TableCell>
                      <TableCell>{formatNumberWithCommas(product.stock)}</TableCell>
                      <TableCell>{product.unit ? product.unit.name : 'N/A'}</TableCell>
                      <TableCell>{product.category ? product.category.name : 'N/A'}</TableCell>
                      <TableCell>{formatNumberWithCommas((product.price * product.stock).toFixed(2))}</TableCell>
                      <TableCell>
                        <Button
                          component={Link}
                          to={`/admin/products/detail/${product._id}`}
                          startIcon={<VisibilityIcon />}
                        >
                          View
                        </Button>
                        <Button
                          component={Link}
                          to={`/admin/products/edit/${product._id}`}
                          startIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => handleOpenDeleteDialog(product._id, product.name, product.description)}
                          startIcon={<DeleteIcon />}
                          color="error"
                        >
                          Delete
                        </Button>
                      </TableCell>
                      {warningMessage && (
                        <TableCell>
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            style={{ color: 'red', marginLeft: 8, cursor: 'pointer' }}
                            title="Click for more info"
                            onClick={() => {
                              setWarningMessage(warningMessage);
                              setShowWarningModal(true);
                            }}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>



          <Pagination>{generatePagination()}</Pagination>
        </>
      ) : (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
          <Typography variant="h6" color="textSecondary" sx={{ mb: 2 }}>
            No products found.
          </Typography>
        </Box>
      )}

<Dialog
  open={openDeleteDialog}
  onClose={handleCloseDeleteDialog}
  aria-labelledby="delete-dialog-title"
  aria-describedby="delete-dialog-description"
>
  <DialogTitle id="delete-dialog-title">
    Confirm Delete
  </DialogTitle>
  <DialogContent>
    <Typography id="delete-dialog-description" gutterBottom>
      Please confirm if you wish to delete the following product:
    </Typography>
    <Typography variant="h6" gutterBottom>
      <strong>{deleteProductName}</strong>
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {deleteProductDescription}
    </Typography>
    <Typography variant="body2" color="error">
      This action cannot be undone!
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button variant="outlined" color="primary" onClick={handleCloseDeleteDialog}>
      Cancel
    </Button>
    <Button variant="contained" color="error" onClick={handleDeleteProduct}>
      Confirm
    </Button>
  </DialogActions>
</Dialog>

      <Dialog
        open={showCustomMessageModal}
        onClose={handleCloseCustomMessageModal}
        aria-labelledby="custom-message-dialog-title"
        aria-describedby="custom-message-dialog-description"
      >
        <DialogTitle id="custom-message-dialog-title">
          Notice
        </DialogTitle>
        <DialogContent>
          <Typography id="custom-message-dialog-description">
            {customMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleCloseCustomMessageModal}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        aria-labelledby="warning-dialog-title"
        aria-describedby="warning-dialog-description"
      >
        <DialogTitle id="warning-dialog-title">
          Warning
        </DialogTitle>
        <DialogContent>
          <Typography id="warning-dialog-description">
            {warningMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setShowWarningModal(false)}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>

    </div>  
  );
};

export default ProductList;
