import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Use for navigation
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Snackbar,
    Alert
} from '@mui/material';
import { axiosInstance } from '../../Axios/AxiosInstance';

const DraftsPage = () => {
    const [drafts, setDrafts] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        const fetchDrafts = async () => {
            try {
                const response = await axiosInstance.get('/quotes'); // Fetch all quotes
                // Filter drafts based on isDraft: true
                const draftQuotes = response.data.filter(quote => quote.isDraft);
                setDrafts(draftQuotes);
            } catch (error) {
                console.error('Error fetching drafts:', error);
                setSnackbarMessage('Failed to fetch drafts');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        };

        fetchDrafts();
    }, []);

    // Close Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleViewDetails = (draftId) => {
        navigate(`/admin/drafts/detail/${draftId}`); // Navigate to the draft detail page
    };

    const handleNavigateToQuotes = () => {
        navigate('/admin/quote'); // Navigate to the drafts page
    };

    return (
        <Box p={3} sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            <Typography variant="h4" gutterBottom color="primary">
                All Quote Drafts
            </Typography>

            <Button variant="contained" color="primary" onClick={handleNavigateToQuotes}>
                Go To All Quotes
            </Button>

            <TableContainer component={Paper} sx={{ borderRadius: 2, mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="h6">Number of Products</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">Created At</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="h6">Actions</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {drafts.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <Typography variant="body1" color="textSecondary">
                                        No drafts available
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            drafts.map((draft) => (
                                <TableRow key={draft._id}>
                                    <TableCell>{draft.products.length}</TableCell>
                                    <TableCell>{new Date(draft.createdAt).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleViewDetails(draft._id)}
                                        >
                                            View Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Snackbar for notifications */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default DraftsPage;
