// formUtilities.js

// Handles customer selection and creation logic
export const handleCustomerSelection = async (formData, customers, axiosInstance, showSnackbar) => {
    let selectedCustomer = customers.find(customer => customer._id === formData.customer);
    console.log('Selected Customer from existing list:', selectedCustomer);

    // Create a new customer if necessary
    if (!selectedCustomer && formData.customer === 'new_customer') {
        if (!formData.newCustomerName || !formData.newCustomerPhone) {
            showSnackbar('Validation Error', 'Please provide name and phone for the new customer.');
            return null;
        }

        const existingCustomer = customers.find(customer => customer.phone === formData.newCustomerPhone);
        console.log('Existing Customer with same phone:', existingCustomer);

        if (existingCustomer) {
            selectedCustomer = existingCustomer;
        } else {
            try {
                const newCustomerResponse = await axiosInstance.post(`/customers`, {
                    name: formData.newCustomerName,
                    phone: `+251${formData.newCustomerPhone.replace(/^0+/, '')}`,
                });
                selectedCustomer = newCustomerResponse.data;
                console.log('New Customer Created:', selectedCustomer);
            } catch (error) {
                showSnackbar('Error', 'Error creating new customer. Please try again later.');
                return null;
            }
        }
    }

    if (!selectedCustomer) {
        showSnackbar('Validation Error', 'Selected customer not found.');
        return null;
    }

    return selectedCustomer;
};

// Handles split payment validation logic
export const handleSplitPaymentValidation = (formData, showSnackbar) => {
    if (formData.paymentMethod === 'split_payment') {
        const cashAmount = parseFloat(formData.cashAmount) || 0;
        const mobileBankingAmount = parseFloat(formData.mobileBankingAmount) || 0;
        const totalAmount = cashAmount + mobileBankingAmount;

        console.log('Total Payment Amount:', totalAmount);
        console.log('Form Total Price:', formData.totalPrice);

        if (totalAmount !== formData.totalPrice) {
            showSnackbar(
                'Validation Error', 
                `The total payment amount (${totalAmount.toFixed(2)}) does not match the total price (${formData.totalPrice.toFixed(2)}).`
            );
            return false;
        }
    }
    return true;
};

// Prepares items for the payload
export const prepareItems = (items, shopId) => {
    return items.map(item => ({
        ...item,
        shopId,
        quantity: Number(item.quantity),
        unitPrice: Number(item.unitPrice),
        totalPrice: Number(item.quantity) * Number(item.unitPrice),
    }));
};

// Prepares the full payload for the API request
export const preparePayload = (formData, selectedCustomer, mergedItems, invoiceId, transactionId, shopId, userId, username) => {
    const paymentStatus = getPaymentStatus(formData.paymentMethod, formData.paymentStatus);

    return {
        items: mergedItems,
        paymentMethod: formData.paymentMethod,
        paymentStatus,
        customer: selectedCustomer._id,
        customerName: selectedCustomer.name,
        invoiceId,
        user: userId,
        createdBy: username,
        shopId: shopId,
        cashAmount: formData.cashAmount || null,
        mobileBankingAmount: formData.mobileBankingAmount || null,
        transactionId,
        timestamp: Date.now(),
        partialCreditAmount: formData.partialCreditAmount || null,
        creditPaymentType: formData.creditPaymentType || null,
    };
};

// Determines payment status based on the form data
const getPaymentStatus = (paymentMethod, paymentStatus) => {
    if (paymentMethod === 'credit') {
        return 'Unpaid';
    } else if (paymentMethod === 'split_payment') {
        return 'split';
    }
    return paymentStatus;
};

// Handles API error responses
export const handleApiError = (error, showSnackbar) => {
    if (error.response) {
        const errorMessage = error.response.data.message || 'An error occurred while processing your request.';
        showSnackbar(errorMessage, 'error');
    } else if (error.request) {
        showSnackbar('No response from server. Please check your network connection.', 'error');
    } else {
        showSnackbar(`Error: ${error.message}`, 'error');
    }
};
