import { useState, useCallback, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';

const useUnits = () => {
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const getToken = () => localStorage.getItem('authToken');

  const fetchUnits = useCallback(async () => {
    setLoading(true);
    try {
      const token = getToken();
      const response = await axiosInstance.get('/units', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUnits(Array.isArray(response.data) ? response.data : []);
      setError(null);
    } catch (err) {
      setError('There was an error fetching the units!');
    } finally {
      setLoading(false);
    }
  }, []);

  const createUnit = async (name) => {
    try {
      const token = getToken();
      const response = await axiosInstance.post('/units', { name }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUnits((prevUnits) => [...prevUnits, response.data]);
      setSuccess('Unit added successfully.');
      setError(null);
    } catch (err) {
      setError('Error creating unit.');
    }
  };

  const updateUnit = async (id, name) => {
    try {
      const token = getToken();
      const response = await axiosInstance.put(`/units/${id}`, { name }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUnits((prevUnits) =>
        prevUnits.map((unit) => (unit._id === id ? response.data : unit))
      );
      setSuccess('Unit updated successfully.');
      setError(null);
    } catch (err) {
      setError('Error updating unit.');
    }
  };

  const deleteUnit = async (id) => {
    try {
      const token = getToken();
      await axiosInstance.delete(`/units/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUnits((prevUnits) => prevUnits.filter((unit) => unit._id !== id));
      setSuccess('Unit deleted successfully.');
      setError(null);
    } catch (err) {
      setError('Error deleting unit.');
    }
  };

  const clearMessages = () => {
    setError(null);
    setSuccess(null);
  };

  useEffect(() => {
    fetchUnits();
  }, [fetchUnits]);

  return {
    units,
    loading,
    error,
    success,
    createUnit,
    updateUnit,
    deleteUnit,
    clearMessages, // Make sure to return clearMessages
  };
};

export default useUnits;
