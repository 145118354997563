import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { useAuth } from '../auth/AuthContext';

const AccessDenied = () => {
  const { username } = useAuth();  // Get username from auth context

  // Capitalize the username
  const capitalizedUsername = username ? username.charAt(0).toUpperCase() + username.slice(1) : '';

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#000',  // Black background
        padding: 3,
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          backgroundColor: '#fff',
          padding: 4,
          borderRadius: 3,
          boxShadow: 3,
          maxWidth: 500,
          width: '100%',
          color: '#000', // Text color
        }}
      >
        <ErrorOutline sx={{ fontSize: 70, color: '#d32f2f', mb: 3 }} />
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
          Access Denied
        </Typography>
        <Typography variant="body1" paragraph>
          {capitalizedUsername ? `Dear ${capitalizedUsername}, you do not have the necessary permissions to access this resource.` : 'You do not have the necessary permissions to access this resource.'}
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          If you believe this is an error, please contact your administrator. For further assistance, you may need to provide additional details to your administrator.
        </Typography>
        <Button
          component={Link}
          to="/auth/signin"
          variant="contained"
          color="primary"
          sx={{
            textTransform: 'none',
            backgroundColor: '#367588',
            color: '#fff',
            '&:hover': { backgroundColor: '#5f9ea0' },
            mt: 2
          }}
        >
          Back to Sign In
        </Button>
      </Box>
    </Box>
  );
};

export default AccessDenied;
