import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Typography, CircularProgress, Grid, Container } from '@mui/material';
import { AuthContext } from './AuthContext';
import Header from '../header/Header';
import logo from '../../assets/images/Inventory1.svg';

const VerifySecurityKey = () => {
  const [securityKey, setSecurityKey] = useState('');
  const [securityKeyError, setSecurityKeyError] = useState('');
  const [loading, setLoading] = useState(false);
  const { verifySecurityKey, role, username, userId, email } = useContext(AuthContext); // Ensure email is included
  const navigate = useNavigate();

  const handleSecurityKeySubmit = async (e) => {
    e.preventDefault();
    setSecurityKeyError('');
    setLoading(true);

    try {
      const isValid = await verifySecurityKey(securityKey);
      if (isValid) {
        navigate('/loading', { state: { userRole: role, email, userId } }); // Ensure role is passed correctly
      } else {
        setSecurityKeyError('Invalid security key. Please try again.');
      }
    } catch (error) {
      setSecurityKeyError('An error occurred while validating the security key.');
    } finally {
      setLoading(false);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #2b556a, #4b89a9)',
        color: '#fff',
        padding: 0,
      }}
    >
      <Header 
        links={[
          { label: 'Home', to: '/dashboard' },
          { label: 'Signin', to: '/auth/signin' },
        ]}
      />

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3,
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              boxShadow: 12,
              borderRadius: 3,
              overflow: 'hidden',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6} sx={{ p: 5 }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                  <img src={logo} alt="Logo" style={{ maxWidth: '150px', margin: '0 auto' }} />
                </Box>
                <form onSubmit={handleSecurityKeySubmit}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Security Key"
                    type="password"
                    variant="outlined"
                    value={securityKey}
                    onChange={(e) => setSecurityKey(e.target.value.toUpperCase())}
                    onPaste={handlePaste}
                    required
                    error={Boolean(securityKeyError)}
                    helperText={securityKeyError}
                    InputProps={{
                      sx: { textTransform: 'uppercase' },
                    }}
                  />
                  <Box sx={{ mt: 3 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      sx={{ backgroundColor: '#367588', color: '#fff', '&:hover': { backgroundColor: '#5f9ea0' } }}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Verify Security Key'}
                    </Button>
                  </Box>
                </form>
              </Grid>
              <Grid item xs={12} md={6} sx={{ p: 5, backgroundColor: '#2b556a', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h4" component="h2" gutterBottom>
                  Secure Access
                </Typography>
                <Typography variant="h5" component="h3" gutterBottom>
                  Dear {username},
                </Typography>
                <Typography variant="body1">
                  Verify your identity to proceed. Enter the security key sent to your email to gain access to the system.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default VerifySecurityKey;
