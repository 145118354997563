
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Inventory1.svg'; // Update the path if needed

const Header = ({ singleLink, links }) => {
  return (
    <header style={{
      position: 'sticky',  // Makes the header sticky
      top: 0,              // Sticks to the top of the page
      zIndex: 1000,   
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px 40px',
      borderBottom: '2px solid rgba(255, 255, 255, 0.2)',
      background: 'linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8))', // Gradient for darker edges on left and right
    }}>


      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/">
          <img 
            src={logo} 
            alt="AddisLogix Logo"
            style={{ height: '50px', marginRight: '20px' }} 
          />
        </Link>
        <h1 style={{
          fontSize: '2em',
          fontWeight: '500',
          margin: 0,
          color: '#f0f4f8',
        }}>
          AddisLogix
        </h1>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        {singleLink ? (
          <Link to={singleLink.to} style={{ textDecoration: 'none' }}>
            <button
              style={{
                padding: '7px 20px',
                fontSize: '1.1em',
                color: '#fff',
                backgroundColor: '#367588',
                border: 'none',
                borderRadius: '30px',
                cursor: 'pointer',
                boxShadow: '0 6px 20px rgba(0, 0, 0, 0.3)',
                transition: 'background-color 0.3s ease, transform 0.3s ease',
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#2b556a';
                e.currentTarget.style.transform = 'translateY(-3px)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = '#367588';
                e.currentTarget.style.transform = 'translateY(0)';
              }}
            >
              {singleLink.label}
            </button>
          </Link>
        ) : (
          links && links.map((link, index) => (
            <Link key={index} to={link.to} style={{ textDecoration: 'none' }}>
              <button
                style={{
                  padding: '7px 20px',
                  fontSize: '1.1em',
                  color: '#fff',
                  backgroundColor: '#367588',
                  border: 'none',
                  borderRadius: '30px',
                  cursor: 'pointer',
                  boxShadow: '0 6px 20px rgba(0, 0, 0, 0.3)',
                  transition: 'background-color 0.3s ease, transform 0.3s ease',
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#2b556a';
                  e.currentTarget.style.transform = 'translateY(-3px)';
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = '#367588';
                  e.currentTarget.style.transform = 'translateY(0)';
                }}
              >
                {link.label}
              </button>
            </Link>
          ))
        )}
      </div>
    </header>
  );
};

export default Header;
