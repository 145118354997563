import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';

const refreshAuthToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) return null;

  try {
    const response = await axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/refresh`, { refreshToken });
    const { token: newToken } = response.data;
    
    // Store the new tokens in localStorage
    localStorage.setItem('authToken', newToken);
    return newToken;
  } catch (error) {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    toast.error('Session expired, please log in again.');
    setTimeout(() => {
      if (window.location.pathname !== '/auth/signin') {
        window.location.href = '/auth/signin';
      }
    }, 2000);
    return null;
  }
};

const setAuthHeader = async (config) => {
  let token = localStorage.getItem('authToken');
  if (token) {
    let decodedToken;
    try {
      decodedToken = jwtDecode(token);
    } catch (error) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      toast.error('Invalid token, please log in again.');
      setTimeout(() => {
        if (window.location.pathname !== '/auth/signin') {
          window.location.href = '/auth/signin';
        }
      }, 2000);
      return config;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    
    if (decodedToken.exp - 60 <= currentTime) {
      token = await refreshAuthToken();
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      const newDecodedToken = jwtDecode(token);
      config.headers['ShopId'] = newDecodedToken.shopId; // Include shopId in headers if needed
    }
  }
  return config;
};

// Create axios instances
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
});

// Request interceptor to set the auth header
axiosInstance.interceptors.request.use(
  async (config) => await setAuthHeader(config),
  (error) => Promise.reject(error)
);

axiosAuthInstance.interceptors.request.use(
  async (config) => await setAuthHeader(config),
  (error) => Promise.reject(error)
);

// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const newToken = await refreshAuthToken();
      if (newToken) {
        error.config.headers['Authorization'] = `Bearer ${newToken}`;
        return axiosInstance.request(error.config);
      } else {
        toast.error('Session expired. Please log in again.');
        setTimeout(() => {
          if (window.location.pathname !== '/auth/signin') {
            window.location.href = '/auth/signin';
          }
        }, 2000);
      }
    }
    return Promise.reject(error);
  }
);

export { axiosInstance, axiosAuthInstance };
