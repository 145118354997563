import React, { useState, useEffect, useRef } from 'react';
import dashboardPreview1 from '../../assets/images/dashboard1.png';
import dashboardPreview2 from '../../assets/images/dashboard2.png';
import dashboardPreview3 from '../../assets/images/dashboard3.png';

const Carousel = () => {
  const images = [dashboardPreview1, dashboardPreview2, dashboardPreview3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const imageContainerRef = useRef(null); // Ref for image container
  let autoSlideInterval = useRef(null); // Ref for the interval

  // Function to start auto-sliding
  const startAutoSlide = () => {
    autoSlideInterval.current = setInterval(() => {
      handleNextImage();
    }, 10000); // 5 seconds interval
  };

  // Function to stop auto-sliding
  const stopAutoSlide = () => {
    clearInterval(autoSlideInterval.current);
  };

  useEffect(() => {
    // Start auto-slide when component mounts
    startAutoSlide();
    
    // Cleanup on unmount
    return () => stopAutoSlide();
  }, []);

  const handleNextImage = () => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setIsFading(false);
    }, 500); // Sync with the fade animation duration
  };

  const handlePrevImage = () => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setIsFading(false);
    }, 500); // Sync with the fade animation duration
  };

  const handleDotClick = (index) => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentImageIndex(index);
      setIsFading(false);
    }, 500);
  };

  // Add hover event listeners to stop and resume auto-sliding
  useEffect(() => {
    const imageContainer = imageContainerRef.current;

    const handleMouseEnter = () => {
      stopAutoSlide();
    };

    const handleMouseLeave = () => {
      startAutoSlide();
    };

    // Add event listeners for hover
    if (imageContainer) {
      imageContainer.addEventListener('mouseenter', handleMouseEnter);
      imageContainer.addEventListener('mouseleave', handleMouseLeave);
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (imageContainer) {
        imageContainer.removeEventListener('mouseenter', handleMouseEnter);
        imageContainer.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  return (
    <section style={{ flex: '1', textAlign: 'center', padding: '20px' }}>
      <div
  ref={imageContainerRef} // Reference the image container for hover events
  style={{
      width: '900px',
      height: '500px',
      position: 'relative',
      padding: '20px',
      backgroundColor: '#ffffff',
      borderRadius: '15px',
      boxShadow: '0 15px 50px rgba(0, 0, 0, 0.3)',
      overflow: 'hidden',
      borderLeft: '5px solid #3b9ca8',  // Add border to half of the box (left side)
  }}
>
  <img 
    src={images[currentImageIndex]} 
    alt="Dashboard Preview" 
    className={isFading ? 'fade' : ''} 
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '12px',
      opacity: isFading ? 0 : 1,
      transition: 'opacity 0.5s ease-in-out',
    }}
  />

  {/* Previous Button */}
  <button onClick={handlePrevImage} style={{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '50px',
    height: '100%',
    backgroundColor: 'transparent',
    color: 'transparent',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
    zIndex: 1,
  }}
  className="carousel-button">
    &#60;
  </button>

  {/* Next Button */}
  <button onClick={handleNextImage} style={{
    position: 'absolute',
    top: 0,
    right: 0,
    width: '50px',
    height: '100%',
    backgroundColor: 'transparent',
    color: 'transparent',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s, color 0.3s',
    zIndex: 1,
  }}
  className="carousel-button">
    &#62;
  </button>

  {/* Hover effect (style applied via CSS) */}
  <style>{`
    .carousel-button:hover {
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
    }
    .fade {
      opacity: 0;
    }
  `}</style>
</div>


      {/* Dots for navigation */}
      <div style={{ marginTop: '15px' }}>
        {images.map((_, index) => (
          <span 
            key={index}
            onClick={() => handleDotClick(index)}
            style={{
              height: '12px',
              width: '12px',
              margin: '0 5px',
              display: 'inline-block',
              borderRadius: '50%',
              backgroundColor: currentImageIndex === index ? '#3b9ca8' : '#dcdcdc',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default Carousel;
