import React, { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';
import { 
    TextField, 
    Button, 
    MenuItem, 
    Typography, 
    Container, 
    Box, 
    Alert, 
    Breadcrumbs, 
    CircularProgress, 
    Avatar, 
    Tooltip, 
    IconButton, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper 
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import InfoIcon from '@mui/icons-material/Info';

const AddProduct = ({ onImportSuccess }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [unitId, setUnitId] = useState('');
    const [units, setUnits] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [categories, setCategories] = useState([]);
    const [productType, setProductType] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [file, setFile] = useState(null);
    const [importFile, setImportFile] = useState(null);
    const [importing, setImporting] = useState(false);
    const [reorderLevel, setReorderLevel] = useState('');
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.get('/categories');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        const fetchUnits = async () => {
            try {
                const response = await axiosInstance.get('/units');
                setUnits(response.data);
            } catch (error) {
                console.error('Error fetching units:', error);
            }
        };

        fetchCategories();
        fetchUnits();
    }, []);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const handleImportFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setImportFile(selectedFile);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess('');
    
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('unit', unitId);
        formData.append('category', categoryId);
        formData.append('reorderLevel', parseFloat(reorderLevel)); // Include reorderLevel
        formData.append('productType', productType); // Include productType
    
        if (file) {
            formData.append('image', file);
        }
    
        try {
            await axiosInstance.post('/products', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            setSuccess('Product added successfully');
            setName('');
            setDescription('');
            setUnitId('');
            setCategoryId('');
            setReorderLevel(''); // Clear reorder level
            setProductType(''); // Clear productType
            setFile(null);
            
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message;
                const existingProduct = error.response.data.existingProduct;
    
                if (existingProduct) {
                    setError(`${errorMessage}. The conflicting product is "${existingProduct.name}" with description: "${existingProduct.description}".`);
                } else {
                    setError(errorMessage || 'There was an error adding the product.');
                }
            } else {
                setError('There was an error adding the product: ' + (error.message || 'Please try again later.'));
            }
        }
    };
    
    
    

    const handleFileUpload = async () => {
        if (!importFile) {
            setError('No file selected for import.');
            return;
        }
    
        setImporting(true);
        const formData = new FormData();
        formData.append('file', importFile);
    
        try {
            const response = await axiosInstance.post('/products/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
    
            // Check for duplicates in the response
            if (response.data.duplicates) {
                // Ensure that duplicates is an array of objects and extract name and description
                const duplicateInfo = response.data.duplicates
                    .map(product => `Name: ${product.name}, Description: ${product.description}`)
                    .join('\n');
    
                setSuccess('Products imported successfully with warnings.');
                setError(`Warning: The following products were not imported due to duplicates:\n${duplicateInfo}`);
            } else {
                setSuccess('Products imported successfully.');
                setError('');
            }
    
            if (onImportSuccess) onImportSuccess();
        } catch (error) {
            setError('Error importing data: ' + (error.response?.data?.message || error.message));
        } finally {
            setImporting(false);
            setImportFile(null);
        }
    };
    

    const handleInfoDialogOpen = () => setInfoDialogOpen(true);
    const handleInfoDialogClose = () => setInfoDialogOpen(false);

    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                    <Typography variant="h4" component="h2" color="primary" gutterBottom>
                        Add Product
                    </Typography>
                    <Breadcrumbs aria-label="breadcrumb">
                        <RouterLink to="/admin/products" style={{ textDecoration: 'none', color: 'inherit' }}>
                            Product List
                        </RouterLink>
                        <Typography color="textPrimary">Add Product</Typography>
                    </Breadcrumbs>
                </Box>
                {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
                {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        label="Product Name"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Description"
                        variant="outlined"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        label="Reorder Level"
                        variant="outlined"
                        type="number"
                        value={reorderLevel}
                        onChange={(e) => setReorderLevel(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        select
                        label="Product Type"
                        variant="outlined"
                        value={productType}
                        onChange={(e) => setProductType(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    >
                        <MenuItem value="" disabled>Select product type</MenuItem>
                        <MenuItem value="Solid">Solid</MenuItem>
                        <MenuItem value="Liquid">Liquid</MenuItem>
                        <MenuItem value="Gas">Gas</MenuItem>
                        <MenuItem value="Gel">Gel</MenuItem>
                        <MenuItem value="Powder">Powder</MenuItem>
                    </TextField>

                    <TextField
                        select
                        fullWidth
                        label="Unit"
                        variant="outlined"
                        value={unitId}
                        onChange={(e) => setUnitId(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    >
                        <MenuItem value="" disabled>Select unit</MenuItem>
                        {units.map((unit) => (
                            <MenuItem key={unit._id} value={unit._id}>
                                {unit.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        label="Category"
                        variant="outlined"
                        value={categoryId}
                        onChange={(e) => setCategoryId(e.target.value)}
                        required
                        sx={{ mb: 2 }}
                    >
                        <MenuItem value="" disabled>Select category</MenuItem>
                        {categories.map((category) => (
                            <MenuItem key={category._id} value={category._id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <label htmlFor="file-upload">
                            <input
                                id="file-upload"
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <Button
                                variant="outlined"
                                component="span"
                                startIcon={<PhotoCamera />}
                            >
                                Upload Image
                            </Button>
                        </label>
                        {file && (
                            <Avatar
                                src={URL.createObjectURL(file)}
                                sx={{ width: 80, height: 80, ml: 2 }}
                            />
                        )}
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 3,
                            bgcolor: '#367588',
                            '&:hover': {
                                bgcolor: '#25525D',
                            }
                        }}
                        disabled={!name.trim() || !description.trim() || !reorderLevel.trim() || !productType.trim() || !unitId.trim() || !categoryId.trim()}
                    >
                        Add Product
                    </Button>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleImportFileChange}
                        style={{ display: 'block', marginBottom: '16px' }}
                        id="import-file"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFileUpload}
                        disabled={importing || !importFile}
                        sx={{ position: 'relative' }}
                    >
                        {importing ? (
                            <>
                                <CircularProgress
                                    size={24}
                                    sx={{ position: 'absolute', left: '50%', top: '50%', marginLeft: '-12px', marginTop: '-12px' }}
                                />
                                Please wait, importing...
                            </>
                        ) : (
                            'Import Products'
                        )}
                    </Button>
                </Box>

                <Tooltip title="Click for import instructions">
                    <IconButton
                        color="primary"
                        onClick={handleInfoDialogOpen}
                        sx={{ mt: 2 }}
                    >
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
                <Dialog
                    open={infoDialogOpen}
                    onClose={handleInfoDialogClose}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>Import Instructions</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">Pre-import Checklist</Typography>
                        <Typography variant="body1" paragraph>
                            1. Ensure that <span style={{ color: '#367588', fontWeight: 'bold' }}>units</span> and <span style={{ color: '#367588', fontWeight: 'bold' }}>categories</span> are added to the system before importing products.
                            Products should reference existing units and categories.
                        </Typography>
                        <Typography variant="h6">Import File Requirements</Typography>
                        <Typography variant="body1" paragraph>
                            The import file must include the following columns only:
                        </Typography>
                        <ul>
                            <li>Name</li>
                            <li>Description</li>
                            <li>Unit</li>
                            <li>Category</li>
                            <li>Reorder Level</li>
                            <li>Product Type</li>
                        </ul>
                        <Typography variant="body1" paragraph>
                            The <span style={{ color: '#367588', fontWeight: 'bold' }}>Product Type</span> should be one of the following:
                        </Typography>

                        <ul>
                            <li>Solid</li>
                            <li>Liquid</li>
                            <li>Gas</li>
                            <li>Gel</li>
                            <li>Powder</li>
                        </ul>
                        <Typography variant="h6">Prototype Table</Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Unit</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Reorder Level</TableCell>
                                        <TableCell>Product Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Example Product</TableCell>
                                        <TableCell>This is a description</TableCell>
                                        <TableCell>Unit1</TableCell>
                                        <TableCell>Category1</TableCell>
                                        <TableCell>10</TableCell>
                                        <TableCell>Solid</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleInfoDialogClose} variant='outlined' color="error">Close</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Container>
    );
};

export default AddProduct;
