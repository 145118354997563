import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Box,
    Typography,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Snackbar,
    Alert,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the Delete icon
import { axiosInstance } from '../../Axios/AxiosInstance';

const DRAFT_KEY = 'quote_draft'; // Key for saving the draft locally

const CreateQuote = () => {
    const location = useLocation();
    const { selectedProducts } = location.state || { selectedProducts: [] };
    const initialQuantities = selectedProducts.reduce((acc, product) => {
        acc[product.productId] = 0; // Set initial quantity to 0
        return acc;
    }, {});

    const [products, setProducts] = useState(selectedProducts); // State for products
    const [quantities, setQuantities] = useState(initialQuantities);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    // State for confirmation dialog
    const [dialogOpen, setDialogOpen] = useState(false);
    const [productToRemove, setProductToRemove] = useState(null);

    const [loading, setLoading] = useState(false);

    // Load draft from localStorage or server on mount
    useEffect(() => {
        const savedDraft = JSON.parse(localStorage.getItem(DRAFT_KEY));
        if (savedDraft) {
            setProducts(savedDraft.products);
            setQuantities(savedDraft.quantities);
        } else {
            fetchDraftFromServer();
        }
    }, []);

    // Save draft to localStorage whenever products or quantities change
    useEffect(() => {
        const draftData = { products, quantities };
        localStorage.setItem(DRAFT_KEY, JSON.stringify(draftData));
    }, [products, quantities]);

    // Function to fetch draft from the server
    const fetchDraftFromServer = async () => {
        try {
            const response = await axiosInstance.get('/quotes/draft');
            if (response.data) {
                setProducts(response.data.products);
                setQuantities(response.data.quantities);
            }
        } catch (error) {
            console.error('Failed to fetch draft from server:', error);
        }
    };

    const saveDraftToServer = async () => {
        // Prepare draft data with quantities including nulls for unspecified quantities
        const draftData = {
         // Include user ID
            products: products.map(product => ({
                productId: product.productId,
            })),
            quantities: Object.fromEntries(
                products.map(product => [
                    product.productId,
                    quantities[product.productId] > 0 ? quantities[product.productId] : null, // Use null for unspecified quantities
                ])
            ),
        };
    
        setLoading(true); // Start loading
    
        try {
            await axiosInstance.post('/quotes/draft/save', draftData);
            setSnackbarMessage('Draft saved successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
    
            // Optionally reset fields or state here if desired
            // setProducts([]);
            // setQuantities(initialQuantities);
        } catch (error) {
            console.error('Error saving draft to server:', error);
            
            // Display a more specific error message if available
            const errorMessage = error.response?.data?.message || 'Failed to save draft to the server.';
            
            setSnackbarMessage(errorMessage);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false); // End loading
        }
    };
    

    const handleQuantityChange = (productId, value) => {
        // Remove non-numeric characters
        let newValue = value.replace(/[^0-9]/g, '');

        // If the first character is '0' and a valid digit is entered, replace the '0'
        if (newValue.length > 1 && newValue.startsWith('0')) {
            newValue = newValue.slice(1);
        }

        // Update the quantities state
        setQuantities(prev => ({
            ...prev,
            [productId]: newValue === '' ? 0 : Number(newValue) // Ensure the state has a numeric value
        }));
    };

    const handleOpenDialog = (productId) => {
        setProductToRemove(productId);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setProductToRemove(null);
    };

    const handleConfirmRemove = () => {
        if (productToRemove) {
            // Remove the product from the products list
            const updatedProducts = products.filter(product => product.productId !== productToRemove);
            setProducts(updatedProducts);

            // Remove the quantity from the quantities state
            const { [productToRemove]: removed, ...updatedQuantities } = quantities;
            setQuantities(updatedQuantities);
        }
        handleCloseDialog();
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleSubmitQuote = async () => {
        // Check if any quantity is less than 1
        const anyQuantityInvalid = Object.values(quantities).some(q => q < 1);
        if (anyQuantityInvalid) {
            setSnackbarMessage('All quantities must be filled and greater than or equal to 1.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }
    
        // Prepare quote data for batch submission
        const quoteData = {
            products: products.map(product => ({
                productId: product.productId,
                quantity: quantities[product.productId]
            }))
        };
    
        console.log('Submitting quote data:', quoteData); // Log the data for debugging
    
        try {
            await axiosInstance.post('/quotes/batch', quoteData); // Send the data to the API
            setSnackbarMessage('Quotes sent successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setQuantities(initialQuantities); // Reset quantities to initial values
            setProducts(selectedProducts); // Reset products to initial values
            localStorage.removeItem(DRAFT_KEY); // Clear draft from localStorage
        } catch (error) {
            console.error('Error sending quotes:', error);
            setSnackbarMessage('Failed to send quotes.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };
    

    const filteredProducts = useMemo(() => {
        return products.filter(product =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [products, searchTerm]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Calculate total products with quantity > 0
    const totalProducts = Object.values(quantities).filter(q => q > 0).length;

    return (
        <Box p={3} sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            <Typography variant="h4" gutterBottom color="primary">
                Create Quote
            </Typography>

            <TextField
                label="Search Products"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ marginBottom: 2 }}
            />

            {filteredProducts.length > 0 ? (
                <>
                    <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell color="primary"><Typography variant="h6">Product Name</Typography></TableCell>
                                    <TableCell color="primary"><Typography variant="h6">Description</Typography></TableCell>
                                    <TableCell color="primary"><Typography variant="h6">Quantity</Typography></TableCell>
                                    <TableCell color="primary"><Typography variant="h6">Actions</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
                                    <TableRow key={product.productId}>
                                        <TableCell>
                                            <Typography variant="body1" color="text.secondary">{product.name}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" color="text.secondary">{product.description}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                value={quantities[product.productId]}
                                                onChange={(e) => handleQuantityChange(product.productId, e.target.value)}
                                                inputProps={{ min: 0 }}
                                                variant="outlined"
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleOpenDialog(product.productId)} color="error">
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredProducts.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                    <Typography variant="body1" color="text.secondary">
                        <span style={{ color: '#367588' }} >Total Products with Quantity</span> &gt; 0: {totalProducts}
                    </Typography>
                </>
            ) : (
                <Typography variant="body1">No products found.</Typography>
            )}

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitQuote}
                disabled={totalProducts === 0}
                sx={{ marginTop: 3 }}
            >
                Submit Quote
            </Button>

            <Button
                variant="contained"
                color="warning"
                onClick={saveDraftToServer}
                disabled={totalProducts === 0 || loading} // Disable button while loading
                sx={{ marginTop: 3, marginLeft: 2 }}
            >
                {loading ? 'Saving...' : 'Save Draft'}
            </Button>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* Confirmation Dialog */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', color: 'black' }}>
                    Confirm Removal
                </DialogTitle>
                <DialogContent sx={{ padding: 3 }}>
                    {productToRemove && (
                        <DialogContentText sx={{ textAlign: 'center', fontSize: '1.1rem' }}>
                            You are about to remove the following product from your quote:
                            <br />
                            <strong>{products.find(product => product.productId === productToRemove)?.name}</strong>
                            <br />
                            <em>{products.find(product => product.productId === productToRemove)?.description}</em>
                            <br />
                            <Typography variant="body2" color="text.secondary" sx={{ marginTop: 2 }}>
                                Are you sure you want to proceed with this action?
                            </Typography>
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
                    <Button onClick={handleCloseDialog} variant="outlined" color="primary" sx={{ marginRight: 1 }}>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmRemove} variant="contained" color="error">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CreateQuote;
