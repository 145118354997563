import { useState, useEffect } from 'react';
import { axiosInstance } from '../../Axios/AxiosInstance';

const useCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteCustomerId, setDeleteCustomerId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newCustomerName, setNewCustomerName] = useState('');
  const [newCustomerPhone, setNewCustomerPhone] = useState('');
  const [submissionError, setSubmissionError] = useState(null);
  const [submissionSuccess, setSubmissionSuccess] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [editName, setEditName] = useState('');
  const [editPhone, setEditPhone] = useState('');

  const [loading, setLoading] = useState(false);  // Loading state
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (submissionSuccess) {
      const timer = setTimeout(() => setSubmissionSuccess(null), 3000);
      return () => clearTimeout(timer);
    }
    if (submissionError) {
      const timer = setTimeout(() => setSubmissionError(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [submissionSuccess, submissionError]);

  const fetchCustomers = async () => {
    setLoading(true);  // Start loading
    setFetchError(null);
    try {
      const response = await axiosInstance.get('/customers');
      const fetchedCustomers = Array.isArray(response.data) ? response.data : [];
      setCustomers(fetchedCustomers);
      setFilteredCustomers(fetchedCustomers);
    } catch (error) {
      console.error('Error fetching customers:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    filterCustomers(searchTerm);
    setSubmissionError(null);
    setSubmissionSuccess(null);
  };

  const filterCustomers = (searchTerm) => {
    const filtered = customers.filter(customer =>
      customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.phone.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCustomers(filtered);
  };

  const handleDeleteCustomer = async (customerId) => {
    try {
      await axiosInstance.delete(`/customers/${customerId}`);
      setCustomers(customers.filter(customer => customer._id !== customerId));
      setFilteredCustomers(filteredCustomers.filter(customer => customer._id !== customerId));
      handleCloseDeleteDialog();
    } catch (error) {
      console.error('Error deleting customer:', error);
    }
  };

  const handleOpenDeleteDialog = (customerId) => {
    setDeleteCustomerId(customerId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteCustomerId(null);
    setOpenDeleteDialog(false);
  };

  const formatPhoneNumber = (phone) => {
    phone = phone.replace(/\D/g, '');
    if (phone.length === 12 && phone.startsWith('251') && (phone[3] === '9' || phone[3] === '7')) {
      return `+251${phone.slice(3)}`;
    }
    if (phone.length === 10 && (phone.startsWith('09') || phone.startsWith('07'))) {
      return `+251${phone.slice(1)}`;
    }
    if (phone.length === 9 && (phone.startsWith('9') || phone.startsWith('7'))) {
      return `+251${phone}`;
    }
    return null;
  };

  const handleAddCustomer = async () => {
    if (!newCustomerName.trim()) {
      setSubmissionError('Customer name is required.');
      return;
    }

    const formattedPhone = formatPhoneNumber(newCustomerPhone);
    if (!formattedPhone) {
      setSubmissionError('Invalid phone number format.');
      return;
    }

    const existingCustomer = customers.find(customer => customer.phone === formattedPhone);
    if (existingCustomer) {
      setSubmissionError(`The phone number you entered is already associated with ${existingCustomer.name}. Please use a different phone number.`);
      return;
    }

    try {
      await axiosInstance.post('/customers', {
        name: newCustomerName,
        phone: formattedPhone
      });
      setNewCustomerName('');
      setNewCustomerPhone('');
      setSubmissionSuccess('Customer added successfully');
      fetchCustomers();
    } catch (error) {
      setSubmissionError('Error adding customer');
      console.error('Error adding customer:', error);
    }
  };

  const handleEditClick = (customer) => {
    setEditMode(customer._id);
    setEditName(customer.name);
    setEditPhone(customer.phone);
  };

  const handleSaveEdit = async (customerId) => {
    const formattedPhone = formatPhoneNumber(editPhone);
    if (!formattedPhone) {
      setSubmissionError('Invalid phone number format.');
      return;
    }

    try {
      await axiosInstance.put(`/customers/${customerId}`, {
        name: editName,
        phone: formattedPhone
      });

      setCustomers(customers.map(customer =>
        customer._id === customerId ? { ...customer, name: editName, phone: formattedPhone } : customer
      ));
      setFilteredCustomers(filteredCustomers.map(customer =>
        customer._id === customerId ? { ...customer, name: editName, phone: formattedPhone } : customer
      ));
      setEditMode(null);
      setSubmissionSuccess('Customer updated successfully');
    } catch (error) {
      setSubmissionError('Error updating customer');
      console.error('Error updating customer:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditMode(null);
    setEditName('');
    setEditPhone('');
  };

  return {
    customers,
    filteredCustomers,
    searchTerm,
    deleteCustomerId,
    openDeleteDialog,
    newCustomerName,
    newCustomerPhone,
    submissionError,
    submissionSuccess,
    editMode,
    editName,
    editPhone,
    loading,
    fetchError,
    fetchCustomers,
    handleSearchChange,
    handleDeleteCustomer,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleAddCustomer,
    handleEditClick,
    handleSaveEdit,
    handleCancelEdit,
    setSearchTerm,
    setNewCustomerName,
    setNewCustomerPhone,
    setSubmissionError,
    setSubmissionSuccess,
    setEditMode,
    setEditName,
    setEditPhone
  };
};

export default useCustomers;
