import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../Axios/AxiosInstance';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Alert
} from '@mui/material';
import { Container } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';

const DistributionSummary = () => {
  const [distributions, setDistributions] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState(''); // New state for search input
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [datePickerType, setDatePickerType] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchDistributions = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get('/distributions/distribution-batch', {
          params: {
            search, // Include search parameter in the request
            startDate: startDate ? startDate.toISOString() : null,
            endDate: endDate ? endDate.toISOString() : null,
            page,
            limit,
          },
        });

        if (response.data && response.data.data) {
          setDistributions(response.data.data);
          setTotal(response.data.total || 0);
        } else {
          setDistributions([]);
          setTotal(0);
        }
      } catch (error) {
        setError('Failed to fetch distributions. Please try again later.');
        setDistributions([]);
        setTotal(0);
      } finally {
        setLoading(false);
      }
    };

    fetchDistributions();
  }, [search, startDate, endDate, page, limit]); // Add search to the dependencies

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDatePickerOpen = (type) => {
    setDatePickerType(type);
    setDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setDatePickerOpen(false);
  };

  const handleDateChange = (date) => {
    if (datePickerType === 'startDate') {
      setStartDate(date);
    } else if (datePickerType === 'endDate') {
      setEndDate(date);
    }
    handleDatePickerClose();
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value); // Update search state when input changes
  };

  const handleDetailClick = (batchId) => {
    navigate(`/admin/distribution/details/${batchId}`);
  };

  return (
    <Container>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" color="primary" gutterBottom>
          Distribution Summary
        </Typography>

        <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              label="Search by Shop Name"
              variant="outlined"
              value={search}
              onChange={handleSearchChange}
            />
            <Button
              variant="outlined"
              onClick={() => handleDatePickerOpen('startDate')}
            >
              Start Date: {startDate ? format(startDate, 'MM/dd/yyyy') : 'Select date'}
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleDatePickerOpen('endDate')}
            >
              End Date: {endDate ? format(endDate, 'MM/dd/yyyy') : 'Select date'}
            </Button>
          </Box>
        </Box>

        {loading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : error ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
          <Typography variant="h6" color="error">{error}</Typography>
        </Box>
      ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Shop</TableCell>
                  <TableCell>Products Distributed</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {distributions.length > 0 ? (
                  distributions.map((distribution, index) => (
                    <TableRow key={index}>
                      <TableCell>{distribution.shopDetails?.name}</TableCell>
                      <TableCell>{distribution.totalProductsDistributed}</TableCell>
                      <TableCell>{new Date(distribution.date).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => handleDetailClick(distribution._id)}
                        >
                          View Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            {distributions.length > 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Pagination
                  count={Math.ceil(total / limit)}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            )}
          </TableContainer>
        )}
      </Box>

      <Dialog open={datePickerOpen} onClose={handleDatePickerClose}>
        <DialogTitle>Select Date</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={datePickerType === 'startDate' ? 'Start Date' : 'End Date'}
              value={datePickerType === 'startDate' ? startDate : endDate}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDatePickerClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default DistributionSummary;
