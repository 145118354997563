import React from 'react';
import { Container, Typography, Paper, Link, Divider, Box } from '@mui/material';
import { styled } from '@mui/system';
import logo from '../assets/images/Inventory1.svg';

// Styled components for better control
const Section = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4),
}));

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ padding: '40px' }}>
      <Paper elevation={6} sx={{ padding: '40px', borderRadius: '12px', backgroundColor: '#ffffff' }}>
        {/* Header with Logo and Title */}
        <HeaderContainer>
          <Typography variant="h4" component="h1" sx={{ color: '#2b556a' }}>
            Privacy Policy
          </Typography>
          <img src={logo} alt="Company Logo" style={{ maxWidth: '150px', height: 'auto' }} />
        </HeaderContainer>
        
        <Typography variant="body1" sx={{ marginBottom: '32px', textAlign: 'center', color: '#666' }}>
          This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.
        </Typography>

        {/* Sections */}
        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            We may collect personal information, such as your name, email address, and other details you provide to us.
          </Typography>
        </Section>

        <Divider sx={{ margin: '24px 0' }} />

        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            We use the information we collect to provide and improve our services, communicate with you, and ensure the security of our website.
          </Typography>
        </Section>

        <Divider sx={{ margin: '24px 0' }} />

        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            3. Sharing Your Information
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            We do not sell or rent your personal information to third parties. We may share information with trusted partners to help us operate our website.
          </Typography>
        </Section>

        <Divider sx={{ margin: '24px 0' }} />

        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            4. Security
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            We implement appropriate security measures to protect your personal information from unauthorized access.
          </Typography>
        </Section>

        <Divider sx={{ margin: '24px 0' }} />

        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            5. Changes to This Policy
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            We may update this Privacy Policy from time to time. We encourage you to review this page periodically.
          </Typography>
        </Section>

        <Divider sx={{ margin: '24px 0' }} />

        <Section>
          <Typography variant="h6" component="h2" sx={{ marginBottom: '8px', color: '#2b556a', fontWeight: 'bold' }}>
            6. Contact Us
          </Typography>
          <Typography variant="body1" sx={{ color: '#444' }}>
            If you have any questions about this Privacy Policy, please contact us at <Link href="mailto:info@addislogix.com" color="primary">info@addislogix.com</Link>.
          </Typography>
        </Section>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
