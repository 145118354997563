import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff, Check } from '@mui/icons-material';
import Header from '../header/Header';
import { AuthContext } from './AuthContext';
import logo from '../../assets/images/Inventory1.svg';
import '../../assets/styles/Signin.css'; // Import your CSS file

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setEmailError('');
    setPasswordError('');
    setLoading(true);

    let hasError = false;

    if (!email) {
      setEmailError('Email is required.');
      hasError = true;
    }

    if (!password) {
      setPasswordError('Password is required.');
      hasError = true;
    }

    if (hasError) {
      setLoading(false);
      return;
    }

    try {
      const { role: userRole, message } = await login(email, password);

      if (message) {
        setError(message);
        setSnackbarOpen(true);
        setLoading(false);
        return;
      }

      if (userRole === 'Admin') {
        navigate('/loading', { state: { userRole, email } });
      } else if (userRole === 'Store-Manager' || userRole === 'Staff') {
        navigate('/auth/verify-security-key');
      } else {
        navigate('/auth/access-denied', { state: { username: email.split('@')[0] } });
      }
    } catch (error) {
      if (error.message.includes('User not found')) {
        setError('User not found. Please check your email and try again.');
      } else if (error.message.includes('Invalid credentials')) {
        setError('Invalid credentials. Please check your email and password.');
      } else {
        setError('An error occurred. Please try again.');
      }

      setPassword('');
      setError(error.message);
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Handle Enter key press
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #29323c, #485563)',
        color: '#fff',
        padding: 0,
      }}
    >
      <Header singleLink={{ label: 'Home', to: '/' }} />

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 3,
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              boxShadow: 12,
              borderRadius: 3,
              overflow: 'hidden',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6} sx={{ p: 5 }}>
                {error && (
                  <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                      {error}
                    </Alert>
                  </Snackbar>
                )}
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                  <div className="hole"> {/* Wrap logo in hole div */}
                    <img src={logo} alt="Logo" className="logo" />
                  </div>
                </Box>
                <Typography variant="h5" component="h3" gutterBottom sx={{ color: '#367588' }}>
                  Sign In
                </Typography>
                <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Email"
                    type="email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    error={Boolean(emailError)}
                    helperText={emailError}
                    autoComplete="email"
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    error={Boolean(passwordError)}
                    helperText={passwordError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onMouseDown={togglePasswordVisibility} onMouseUp={togglePasswordVisibility}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="current-password"
                  />
                  <Box sx={{ mt: 2, textAlign: 'right' }}>
                    <Link
                      to="/auth/forgot-password"
                      style={{
                        textDecoration: 'none',
                        color: loading ? '#A0A0A0' : '#367588', // Change color to grey when loading
                        pointerEvents: loading ? 'none' : 'auto', // Disable interaction when loading
                      }}
                    >
                      Forgot Password?
                    </Link>
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      sx={{ backgroundColor: '#367588', color: '#fff', '&:hover': { backgroundColor: '#5f9ea0' } }}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Sign In'}
                    </Button>
                  </Box>
                </form>
              </Grid>
              <Grid item xs={12} md={6} sx={{ p: 5, backgroundColor: '#2b556a', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h4" component="h2" gutterBottom>
                  Welcome Back!
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Manage your inventory efficiently with AddisLogix. Keep track of stock levels, update item details, and monitor sales and purchases with ease.
                </Typography>
                <List
                  sx={{
                    listStyleType: 'disc',
                    paddingLeft: 3,
                    '& .MuiListItem-root': {
                      color: 'white',
                    },
                  }}
                >
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <Check sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary="Track stock levels in real-time" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <Check sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary="Update item details effortlessly" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <Check sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary="Monitor sales and purchases" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <Check sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary="Generate detailed reports" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon sx={{ minWidth: '40px' }}>
                      <Check sx={{ color: 'white' }} />
                    </ListItemIcon>
                    <ListItemText primary="Receive low-stock alerts" />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box
  component="footer"
  sx={{
    width: '100%',
    py: 2,
    textAlign: 'center',
  }}
>
  <Box
    sx={{
      width: '40%',          // Reduce the width
      borderTop: '2px solid #333', // Matte black line
      margin: '0 auto',      // Center the line
    }}
  />
  <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
    © 2024 AddisLogix. All rights reserved.
  </Typography>
</Box>


    </Box>
  );
};

export default Signin;
